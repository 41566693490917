// import { ScheduleComponent, Day, Week, WorkWeek, Month, Agenda, Inject } from '@syncfusion/ej2-react-schedule';
import React, { useEffect, useState, useRef, useContext } from 'react';
import { DatePickerComponent, Inject } from '@syncfusion/ej2-react-calendars';
import { ColumnDirective, ColumnsDirective, CommandColumn, GridComponent, Page, Sort, Filter, ExcelExport, Edit, Toolbar } from '@syncfusion/ej2-react-grids';
import "./CapacityManagement.css";
import moment from 'moment';
import agent from '../../api/agent';
import { IoMdRefresh } from 'react-icons/io'
import { BiSolidPencil } from 'react-icons/bi'
import TokenProvider, { logOutUser } from '../TokenProvider/TokenProvider';
import { useTranslation } from 'react-i18next';
import AppContext from '../Theme/AppContext';
import { showToast, startLoader, stopLoader } from '../Theme/Utils';
import { useNavigate } from 'react-router-dom';
import Loading from '../Loading/Loading';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import {AiOutlineQuestionCircle} from "react-icons/ai"
import { Col, Row } from 'react-bootstrap';
import { Animation } from '@syncfusion/ej2-base';

const CapacityManagement = () => {
    const { t, i18n } = useTranslation();
    const moduleName = "Capacity Management"
    const { theme, userInfo } = useContext(AppContext);

    const { token, setToken } = TokenProvider();
    const cbToken = token.token;
    const username = token.username;

    const [capacityList, setCapacityList] = useState([]);
    const [loadingTableList, setLoadingTableList] = useState(false);
    const [selectedAvailableDate, setSelectedAvailableDate] = useState(null);
    const [lastRefreshedTrips, setLastRefreshedTrips] = useState(null);

    const [customerDDList, setCustomerDDList] = useState([]);
    const [loadingCustomerList, setLoadingCustomerList] = useState(true);
    const [customerDDText, setCustomerDDText] = useState(null);
    const [customerDDValue, setCustomerDDValue] = useState(null);
    const customerDDRef = useRef(null);
    const gridRef = useRef(null);
    const [placeKey, setPlaceKey] = useState(0);

    const navigate = useNavigate();


    const handlAuthorization = (error) => {
        console.log(error);
        if (error.code === "ERR_NETWORK") {
            showToast(t("No response from server."), 'error');
        }
        else if (error?.response?.status === 401) {
            showToast(t('Your session has expired. Please login again.'), 'error');
            logOutUser();
        }
        else if (error?.response?.status === 403) {
            //showToast(t("You do not have access to ")+ moduleName+ t(", please contact your account manager to gain access."), 'error');
            navigate('/access-denied', { state: { moduleName } });
        }
    }

    useEffect(() => {
        getCustomerList().then(data => {
            if(data){
                setLoadingTableList(true);
                getAvailableCapacity(selectedAvailableDate, data);
            }
        })
    }, []);

    useEffect(()=>{
      if(i18n.language){
          setPlaceKey(prev=>prev+1);
      }
  },[i18n.language])

    const getCustomerList = async () => {
        try {
            setLoadingCustomerList(true);
            let res = await agent.getCustomerListCap();
            if (!(res.data.length > 0)) {
                navigate('/access-denied', { state: { moduleName } });
                return;
            }
            //   res.data.push("key1-val1");
            //   res.data.push("key2-val2");
            let list = res.data.map(item => {
                const text = item.split('-')[0];
                return { text, value: item };
            });
            list.sort((a, b) => a.text.localeCompare(b.text));
            setCustomerDDList(list);
            setCustomerDDText(list[0].text)
            setCustomerDDValue(list[0].value)
            setLoadingCustomerList(false);
            return list[0].value;
        }
        catch (err) {
            // setLoadingCustomerList(false);
            handlAuthorization(err);
        }
    }

    const handlChangeCustomerDD = (e) => {
        let val = e.target.value;
        setCustomerDDText(val)
        setCustomerDDValue(customerDDList.find(item => item.text === val).value)
    }

    const getAvailableCapacity = (availDate, customer) => {
        let params = {
            customer,
        }
        if (availDate) {
            params.equipAvailableDate = moment(availDate).format("YYYY-MM-DD HH:mm:ss");
        }
        agent.getAllAvailableCapacityList(params).then((response) => {
            setCapacityList((response.data));

            for (let i = 0; i < response.data.length; i++) {
                response.data[i].dateAvailable = new Date(response.data[i].dateAvailable);
                response.data[i].emailSentToCarrier = response.data[i].emailSentToCarrier ? new Date(response.data[i].emailSentToCarrier) : null;
            }
            setLoadingTableList(false);
            setLastRefreshedTrips(new Date().toLocaleString("en-US"));
        }).catch((error) => {
            setLoadingTableList(false);
            handlAuthorization(error);
            setLastRefreshedTrips(new Date().toLocaleString("en-US"));
        });
    }

    const handleClickSubmit = () => {
        setLoadingTableList(true);
        getAvailableCapacity(selectedAvailableDate, customerDDValue)
    }

    const handleClickRefresh = () => {
        setLoadingTableList(true);
        getAvailableCapacity(selectedAvailableDate, customerDDValue)
        setLastRefreshedTrips(null);
    }

    const handleActionBegin = (args) => {
        if (args.requestType === 'save' && args.action === 'edit') {
            const editedRecord = args.data;
            if (editedRecord.equipmentUsed > editedRecord.quantity) {
                showToast(t("Equipment Used should be less than Quantity."),"error")
                args.cancel = true;
                return;
            }
        }
    };

    const handleToolbarClick = (args) => {
      if (args.item.id === 'capacity-management-grid_excelexport') {
        startLoader()
        // Trigger Excel export using the grid ref
        if (gridRef.current) {
          const exportProperties = {
            fileName: `capacity_management_export_${new Date().toISOString().slice(0, 10)}.xlsx`,
          };
          gridRef.current.excelExport(exportProperties);
        }
        stopLoader();
      }
    };
  
    const handleActionComplete = (args) => {
        const customer = customerDDValue;

        if (args.requestType === 'save' && args.action === 'edit') {
            const editedRecord = args.data;
            const originalRecord = args.rowData;

            if (editedRecord.equipmentUsed !== originalRecord.equipmentUsed ||
                editedRecord.usedComments !== originalRecord.usedComments) {
                startLoader()
                agent.PostCapacityUsed({
                    // ...editedRecord,
                    capacityId: editedRecord.capacityID,
                    qtyUsed: editedRecord.equipmentUsed,
                    usedComments: editedRecord.usedComments,
                }, { customer }).then((response) => {
                    if (response.data) {
                        getAvailableCapacity(selectedAvailableDate, customerDDValue);
                    }
                }).catch((error) => {
                    handlAuthorization(error);
                })
                .finally(()=>{
                  stopLoader();
                })
            }
        }
    };

    const handleDocumentDownload = (props) => {
        agent
          .GetCapacityBoardDoc()
          .then((response) => {
            console.log(response.data);
            const linkSource = `data:application/pdf;base64,${response.data}`;
            const downloadLink = document.createElement('a');
            const fileName = t(props) + '.pdf';
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
          })
          .catch((x) => alert(t('Unable to download file')));
      };

    const loaderRef = useRef(null);
    const contentRef = useRef(null);
  
    useEffect(() => {
      if (loadingCustomerList) {
        let animation = new Animation();
        animation.animate(loaderRef.current, { name: 'FadeOut', duration: 800 });
      } else {
        let animation = new Animation();
        animation.animate(contentRef.current, { name: 'FadeIn', duration: 800 });
      }
    }, [loadingCustomerList]);

    return (
      <div>
      {loadingCustomerList ? (
        <div ref={loaderRef} style={{ height: "100vh" }} className="my-custom-loader-container">
          <Loading />
        </div>
      ) : (
      <div ref={contentRef} className="container-flex" style={{ padding: '1.5rem 0rem 4rem' }}>
        <div className="chart-container mt-4" style={{ paddingLeft: '1.5rem' }}>
          <h1 className="mt-4 my-custom-text main-header-text flex-center">{t('Capacity Management')}</h1>
         <Row id="dashboard-form">
          <Col md={11} sm={11} lg={11}>
            <div className="flex-center" style={{ maxWidth: '100%', alignItems: 'end', flexWrap: 'wrap', gap: '1rem' }}>
              {customerDDList && customerDDList.length > 1 && (
                <div className=" my-custom-text">
                  <span className="form-field-label">{t('Customer Name')}</span>
                  <div className="">
                    <div className="daterangepicker-control-section">
                      <DropDownListComponent
                        required={true}
                        onChange={handlChangeCustomerDD}
                        ref={customerDDRef}
                        value={customerDDText}
                        placeholder={t('Customer Name')}
                        floatLabelType="Never"
                        cssClass="e-medium"
                        className="e-field"
                        dataSource={customerDDList}
                        id="dropdown"
                        data-msg-containerid="errorForDropDown"
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className=" my-custom-text">
                <span className="form-field-label">{t('Equipment Available Date')}</span>
                <div className="">
                  <div className="daterangepicker-control-section">
                    <DatePickerComponent
                      onChange={(e) => {
                        if (e?.value) {
                          setSelectedAvailableDate(e?.value);
                        } else {
                          setSelectedAvailableDate(null);
                        }
                      }}
                      value={selectedAvailableDate !== null ? selectedAvailableDate : ''}
                      name="date"
                      id="date"
                      className="form-control"
                      showClearButton="yes"
                      cssClass="e-medium"
                      placeholder={t('Enter Equipment Available Date')}
                      key={"EquipmentDate"+placeKey}
                      maskPlaceholder={'yes'}
                      format="MM/dd/yyyy"
                      enableMask={true}
                      floatLabelType="Never"
                    />
                  </div>
                </div>
              </div>
              <button onClick={handleClickSubmit} disabled={loadingTableList} className="my-custom-btn" type="submit">
                {t('Filter')}
              </button>
              <div onClick={handleClickRefresh} style={{ width: 'max-content' }} className="btn btn-outline-secondary active flex-center" type="button">
                <IoMdRefresh style={{ color: 'white', marginRight: '5px' }} />
                {t('Refresh')}
              </div>
              <div style={{ height: '100%' }}>
                <div style={{ height: '100%' }} className="my-custom-text flex-center">
                  {t('Last Refreshed') + ': '}
                  {loadingTableList ? t('Refreshing') + '...' : lastRefreshedTrips}
                </div>
              </div>
            </div>
          </Col>
          <Col md={1} sm={1} lg={1}>
            <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', height: '100%' }}>
              <AiOutlineQuestionCircle
                onClick={() => {
                  handleDocumentDownload('CapacityBoard');
                }}
                style={{ height: '30px', marginTop: '1rem', marginRight:"4rem", cursor: 'pointer' }}
                size={30}
                color={theme === 'dark' ? 'white' : 'grey'}
              />
            </div>
          </Col>
          </Row>
          <div className="mt-4" style={{ padding: '0rem 4rem' }}>
            {!loadingTableList ? (
              <GridComponent
                loadingIndicator={{ indicatorType: 'Shimmer' }}
                className="syncf-grid-comp my-custom-toolbar-table"
                id="capacity-management-grid"
                dataSource={capacityList}
                ref={gridRef}
                allowPaging={true}
                allowSorting={true}
                allowFiltering={(capacityList && capacityList.length > 0)}
                allowExcelExport={(capacityList && capacityList.length > 0)}
                // toolbar={[{ template: exportButtonTemplate }]}
                pageSettings={{ pageSize: 10 }}
                textWrapSettings={{ wrapMode: 'Header' }}
                sortSettings={{
                  columns: [{ field: 'dateAvailable', direction: 'Descending' }],
                }}
                actionComplete={handleActionComplete}
                actionBegin={handleActionBegin}
                toolbarClick={handleToolbarClick}
                filterSettings={{
                  type: 'Menu',
                }}
                editSettings={{ allowEditing: true, allowAdding: false, allowDeleting: false }}
                toolbar={['Edit', 'Update', 'Cancel', 'ExcelExport']}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    headerTextCss="dashboard-header-text"
                    field="capacityID"
                    headerTextAlign="Center"
                    headerText="id"
                    width="0"
                    textAlign="Center"
                    visible={false}
                    isPrimaryKey={true}
                  />
                  <ColumnDirective
                    field="carrierName"
                    headerText="Carrier Name"
                    headerTemplate={(props) => (
                      <div style={{ textAlign: 'center' }}>
                        <div>{t('Carrier')}</div>
                        <div>{t('Name')}</div>
                      </div>
                    )}
                    allowEditing={false}
                    template={(data) => <div style={{ whiteSpace: 'pre-line' }}>{data.carrierName}</div>}
                    headerTextAlign="Center"
                    disableHtmlEncode={false}
                    width="160"
                    textAlign="Center"
                  />
                  <ColumnDirective
                    disableHtmlEncode={false}
                    field="equipmentName"
                    headerText={t('Equipment')}
                    headerTextAlign="Center"
                    width="140"
                    textAlign="Center"
                    allowEditing={false}
                  />
                  <ColumnDirective
                    field="quantity"
                    disableHtmlEncode={false}
                    headerText={t('Qty')}
                    headerTextAlign="Center"
                    width="70"
                    textAlign="Center"
                    allowEditing={false}
                  />
                  <ColumnDirective
                    field="from"
                    headerText={t('From')}
                    width="120"
                    textAlign="Center"
                    headerTextAlign="center"
                    template={(data) => <div style={{ whiteSpace: 'pre-line' }}>{data.from}</div>}
                    allowEditing={false}
                  />
                  <ColumnDirective
                    field="to"
                    headerText={t('To')}
                    width="120"
                    textAlign="Center"
                    template={(data) => <div style={{ whiteSpace: 'pre-line' }}>{data.to}</div>}
                    allowEditing={false}
                  />
                  <ColumnDirective
                    field="dateAvailable"
                    headerText={t('Available Date')}
                    headerTextAlign="center"
                    headerTemplate={(props) => (
                      <div style={{ textAlign: 'center' }}>
                        <div>{t('Available')}</div>
                        <div>{t('Date')}</div>
                      </div>
                    )}
                    allowEditing={false}
                    //  disableHtmlEncode={false}
                    width="110"
                    textAlign="Center"
                    format={'MM/dd/yyyy'}
                  />
                  <ColumnDirective
                    field="carrierComments"
                    headerText={t('Carrier Commments')}
                    headerTemplate={() => (
                      <div style={{ textAlign: 'center' }}>
                        <div>{t('Carrier')}</div>
                        <div>{t('Commments')}</div>
                      </div>
                    )}
                    template={(data) => <div style={{ whiteSpace: 'pre-line' }}>{data.carrierComments}</div>}
                    allowEditing={false}
                    width="130"
                    textAlign="Left"
                  />
                  <ColumnDirective
                    field="equipmentUsed"
                    headerText="Qty Used"
                    headerTemplate={() => (
                      <div style={{ display: 'flex', alignItems: 'end' }}>
                        <div style={{ textAlign: 'left' }}>
                          <div>{t('Qty')}</div>
                          <div>{t('Used')}</div>
                        </div>
                        <BiSolidPencil style={{ marginLeft: '2px' }} color="#6c757d" />
                      </div>
                    )}
                    width="100"
                    textAlign="Center"
                    validationRules={{ required: true, min: 0 }}
                    editType="numericedit"
                  />
                  <ColumnDirective
                    field="usedComments"
                    headerText={t('Commments')}
                    headerTemplate={() => (
                      <div style={{ textAlign: 'center' }}>
                        <div>
                          {t('Commments')}
                          <BiSolidPencil style={{ marginLeft: '2px' }} color="#6c757d" />
                        </div>
                      </div>
                    )}
                    width="140"
                    textAlign="Left"
                    editType="stringedit" // Set editType to enable editing
                    template={(data) => <div style={{ whiteSpace: 'pre-line' }}>{data.usedComments}</div>}
                    validationRules={{
                      maxLength: 400,
                    }}
                  />
                  <ColumnDirective
                    field="emailSentToCarrier"
                    headerText={t('Confirmation Sent')}
                    headerTemplate={() => (
                      <div style={{ textAlign: 'center' }}>
                        <div>{t('Confirmation')}</div>
                        <div>{t('Sent')}</div>
                      </div>
                    )}
                    width="130"
                    textAlign="Center"
                    allowEditing={false}
                    format={'MM/dd/yyyy HH:mm'}
                  />
                </ColumnsDirective>
                <Inject services={[Page, CommandColumn, Sort, Filter, ExcelExport, Edit, Toolbar]} />
              </GridComponent>
            ) : (
              <div className="my-custom-loader-container">
                <Loading />
              </div>
            )}
          </div>
        </div>
      </div>
      )}
      </div>
    );
};

export default CapacityManagement;