import axios from 'axios';
import { getJWTToken, getUserInfo } from '../components/Auth/ProtectedRoute';
import { showToast } from '../components/Theme/Utils';
import { getLocale } from '../components/Theme/ThemeUtils';

const TRACKTRACE_API = process.env.REACT_APP_TRACKTRACE_API;
const AUTH_API = process.env.REACT_APP_AUTH_API;
const RATING_API = process.env.REACT_APP_RATING_API;
const CAPACITY_API = process.env.REACT_APP_CAPACITY_API;
const DIMEN_API = process.env.REACT_APP_DIMEN_API;
const DOCUMENT_API = process.env.REACT_APP_DOCUMENT_API;
const REPORT_API = process.env.REACT_APP_REPORT_API;
const LOGGING_API = process.env.REACT_APP_LOGGING_API;

const axAPIError = () => {
  return axios.create({
    // baseURL: newURL,
    headers: {
      Authorization: `Bearer ${getJWTToken()}`,
      'Content-Type': 'application/json',
    },
  });
};

const axAPI = () => {
  const api = axios.create({
    // baseURL: newURL,
    headers: {
      Authorization: `Bearer ${getJWTToken()}`,
      'Content-Type': 'application/json',
    },
  });

  // Add a request interceptor
  api.interceptors.request.use(
    (config) => {
      config.metadata = { startTime: new Date() };
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  api.interceptors.response.use(
    (response) => {
      // Calculate request duration
      const duration = new Date() - response.config.metadata.startTime;

      // Show toast notification if duration is greater than 30 seconds
      if (duration > 20000) {
        // 100 20000
        const locale = getLocale();
        const EnMessage = 'Error has occurred while trying to access your data. The systems administrator has been notified. Please try again in few mins';
        const FrMessage =
          "Une erreur s'est produite lors de la tentative d'accès à vos données. L'administrateur système a été notifié. Veuillez réessayer dans quelques minutes.";
        const error = 'The Customer Portal API call took more than 20 seconds';
        // showToast((locale === "en" ? EnMessage : FrMessage), 'error');
        sendErrorEmail({
          token: getJWTToken(),
          errorTypes: 17,
          UserName: getUserInfo().username,
          TenantName: ' ',
          APIServiceName: ' ',
          ControllerName: ' ',
          MethodName: 'Error in Customer Portal Frontend',
          Parameters: `[[Environment : ${process.env.REACT_APP_ENV}]]`,
          ErrorMessage: `Error: ${error && error.toString()}`,
          InnerException: ' ',
          CallStack: `[[Environment : ${process.env.REACT_APP_ENV}]]: ${error && error.toString()}`,
          ReferenceNumber: ' ',
          ReferenceType: ' ',
          sendEmail: true,
        })
          .then((response) => {})
          .catch((error) => {
            console.error('Error while reporting the error:', error);
          });
      }
      return response;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return api;
};

// Authentication
const fetchToken = async (userName, password) => {
  const otherParams = { app: Number(process.env.REACT_APP_APIAPPVERSION), environment: Number(process.env.REACT_APP_APIENV) };
  // return await axios.post(`https://authentication-api.cedricmillar.com/api/v1/Account/Authenticate`, {userName , password });
  return await axios.post(`${AUTH_API}/api/v2/Account/Authenticate`, { userName, password, ...otherParams });
};

const getRefreshToken = async (params) => {
  const otherParams = { app: Number(process.env.REACT_APP_APIAPPVERSION), environment: Number(process.env.REACT_APP_APIENV) };
  // return await axios.post(`https://authentication-api.cedricmillar.com/api/v1/Account/Authenticate`, {userName , password });
  return await axios.post(`${AUTH_API}/api/v2/Account/Refresh?token=${params.token}`, { ...params, ...otherParams });
};

const getRefreshMetebaseToken = async (params) => {
  const otherParams = { app: Number(process.env.REACT_APP_APIAPPVERSION), environment: Number(process.env.REACT_APP_APIENV) };
  // return await axios.post(`https://authentication-api.cedricmillar.com/api/v1/Account/Authenticate`, {userName , password });
  return await axAPI().post(`${AUTH_API}/api/v2/Account/MetabaseRefresh?token=${params.token}`, { ...params, ...otherParams });
};

const PostThemeCarrierPortal = async (token, userID, theme, lang) => {
  // return axios.post(
  //   `${API_URL}/PostThemeCarrierPortal?token=${token}&userID=${userID}&theme=${theme}&lang=${lang}`,
  //   "",
  //   auth
  // );
};

const sendErrorEmail = async (bodyData) => {
  // console.log("bodyData ", bodyData)
  return await axAPIError().post(`${LOGGING_API}/api/v1/Email/EmailToTechnology`, { ...bodyData });
  // return await axAPI().post(`${LOGGING_API}/api/v1/Error/ErrorLogging`, bodyData);
};

const getExportedExcelData = async (gridName, params) => {
  if (gridName === 'Trips') {
    return await axAPI().get(`${TRACKTRACE_API}/api/v1/ExcelReport`, { params });
  }
};
/////////
/// report
const getCustomerTripsList = async (params) => {
  return await axAPI().get(`${TRACKTRACE_API}/api/v1/CustomerPortalTrips/GetAllCustomerTrips`, { params });
};

const getCustomerTripStopImagePDF = async (params) => {
  return await axAPI().get(`${TRACKTRACE_API}/api/v1/CustomerPortalTrips/GetAllTripStopimage`, { params });
};
// const removeCustomerTripStopImagePDF = async (params) => {
// 	return await axAPI().delete(`${TRACKTRACE_API}/api/v1/CustomerPortalTrips/RemoveTripStopimage`, {params});
// };
// const getCustomerTripStopsList = async (params) => {
// 	return await axAPI().get(`${TRACKTRACE_API}/api/v1/CustomerPortalTrips/GetAllTripStopimage`, {params});
// };
const getCustomerBackhaulList = async (params) => {
  return await axAPI().get(`${TRACKTRACE_API}/api/v1/CustomerPortalTrips/GetAllCustomerBackhauls`, { params });
};

const getAllTripsStopsList = async (params) => {
  return await axAPI().get(`${TRACKTRACE_API}/api/v1/CustomerPortalTrips/GetAllCustomerTripStops`, { params });
};

const getAllBackhaulStopsList = async (params) => {
  return await axAPI().get(`${TRACKTRACE_API}/api/v1/CustomerPortalTrips/GetAllCustomerBackhaulStops`, { params });
};

const getCustomerListTrack = async (params) => {
  return await axAPI().get(`${TRACKTRACE_API}/api/v1/CustomerPortalTrips/GetCustomers`, { params });
};
////
/// capacity
const getAllAvailableCapacityList = async (params) => {
  return await axAPI().get(`${CAPACITY_API}/api/v1/CapacityManagement/GetAllAvailableCapacity`, { params });
};
const PostCapacityUsed = async (params, otherParams) => {
  const queryParams = new URLSearchParams({ customer: otherParams.customer });
  return await axAPI().post(`${CAPACITY_API}/api/v1/CapacityManagement/PostCapacityUsed?${queryParams.toString()}`, params);
};
const getCustomerListCap = async (params) => {
  return await axAPI().get(`${CAPACITY_API}/api/v1/CapacityManagement/GetCustomers`, { params });
};
////
/// Rating
const getCustomerListRateCall = async (params) => {
  return await axAPI().get(`${RATING_API}/api/Permissions/GetCustomers`, { params });
};

const getOracleItem = async (params) => {
  return await axAPI().get(`${RATING_API}/api/v1/TMS/GetOracleItem`, { params });
};

const getAllHillmanRateCallRequestsList = async (params) => {
  return await axAPI().get(`${RATING_API}/api/v1/Report/GetAllRateCallRequests`, { params });
};

const getAllLowestRateCallList = async (params, otherParams) => {
  const queryParams = new URLSearchParams({ customer: otherParams.customer });
  return await axAPI().post(`${RATING_API}/api/v1/RateCall/GetRateCall?${queryParams.toString()}`, params);
};

const getAllRateCallDefaultValues = async (params) => {
  return await axAPI().get(`${RATING_API}/api/v1/Report/GetAllRateCallDefaults`, { params });
};

const updateRateCallDefaultValues = async (params, otherParams) => {
  const queryParams = new URLSearchParams({ customer: otherParams.customer });
  return await axAPI().post(`${RATING_API}/api/v1/Report/UpdateRateCallDefaults?${queryParams.toString()}`, params);
};

////
/// Dimension
const getStateByCountryCodeList = async (params) => {
  return await axAPI().get(`${DIMEN_API}/api/v1/State/GetStateCodeForCountry`, { params });
};

const getAreaByStateCodeList = async (params) => {
  return await axAPI().get(`${DIMEN_API}/api/v1/Area/GetAreaByStateCode`, { params });
};
////

const GetTripManagementDoc = async () => {
  return await axAPI().get(`${DOCUMENT_API}/api/v1/CustomerPortal/GetTripManagementDoc3`);
};

const GetCapacityBoardDoc = async () => {
  return await axAPI().get(`${DOCUMENT_API}/api/v1/CustomerPortal/GetCapacityBoardDoc2`);
};

// metabase

const getMatabaseDashBoard = async () => {
  return await axAPI().get(`${REPORT_API}/api/MetabaseDashboard/GetEmbeddingURL`);
};

const getMatabaseDashBoardID = async (params) => {
  return await axAPI().get(`${REPORT_API}/api/MetabaseDashboard/GetMetabaseDashboardId`, { params });
};

const GetCustomerMetabase = async (params) => {
  return await axAPI().get(`${REPORT_API}/api/MetabaseDashboard/GetCustomers`, { params });
};

const fetchTokenMetabase = async (token, userName, password) => {
  const otherParams = { app: Number(process.env.REACT_APP_APIAPPVERSION), environment: Number(process.env.REACT_APP_APIENV) };
  // return await axios.post(`https://authentication-api.cedricmillar.com/api/v1/Account/Authenticate`, {userName , password });
  return await axios.post(
    `${AUTH_API}/api/v2/Account/MetabaseAuthenticate`,
    { userName, password, ...otherParams },
    { headers: { Authorization: `Bearer ${token}` } }
  );
};

/// Document
const getDocuments = async (customer, type, searchText) => {
  const response = await axAPI().get(`${DOCUMENT_API}/api/v2/Documents/GetDocuments`, {
    params: {
      customer,
      type: type,
      docRef: searchText,
    },
  });
  return response;
};

const getCustomerDocuments = async (customer) => {
  const response = await axAPI().get(`${DOCUMENT_API}/api/v2/Documents/GetDocumentCustomer`, {
    params: {
      customer,
    },
  });
  return response;
};

const getColumnFormat = async (grid) => {
  const response = await axAPI().get(`${DOCUMENT_API}/api/v2/Documents/GetColumnFormat`, {
    params: {
      gridName: grid,
    },
  });
  return response;
};

const getDocument = async (customer, type, docRef) => {
  const response = await axAPI().get(`${DOCUMENT_API}/api/v2/Documents/GetDocument`, {
    params: {
      customer: customer,
      type: type,
      docRef: docRef,
    },
  });
  return response;
};
// https://localhost:7179/GetDocument

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getDocument,
  getColumnFormat,
  getCustomerDocuments,
  getExportedExcelData,
  getStateByCountryCodeList,
  getAreaByStateCodeList,
  PostCapacityUsed,
  getCustomerTripStopImagePDF,
  getAllRateCallDefaultValues,
  getAllBackhaulStopsList,
  getAllTripsStopsList,
  getCustomerListTrack,
  getCustomerListCap,
  getOracleItem,
  getCustomerListRateCall,
  updateRateCallDefaultValues,
  getAllHillmanRateCallRequestsList,
  getAllLowestRateCallList,
  getRefreshMetebaseToken,
  // removeCustomerTripStopImagePDF,
  // getCustomerTripStopsList,
  getAllAvailableCapacityList,
  getCustomerTripsList,
  getCustomerBackhaulList,
  fetchToken,
  getMatabaseDashBoard,
  getRefreshToken,
  PostThemeCarrierPortal,
  sendErrorEmail,
  GetTripManagementDoc,
  GetCapacityBoardDoc,
  GetCustomerMetabase,
  fetchTokenMetabase,
  getMatabaseDashBoardID,
  getDocuments,
};
