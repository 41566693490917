// import { ScheduleComponent, Day, Week, WorkWeek, Month, Agenda, Inject } from '@syncfusion/ej2-react-schedule';
import React, { useEffect, useState, useRef, useContext } from 'react';
import "./Trips.css";
import agent from '../../api/agent';
import { formatDate, showToast } from '../Theme/Utils';
import { logOutUser } from '../TokenProvider/TokenProvider';
import { useTranslation } from 'react-i18next';
import AppContext from '../Theme/AppContext';
import TripBackhaulStopTable from './TripBackhaulStopTable';
import { useNavigate } from 'react-router-dom';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import Loading from '../Loading/Loading';
import { Animation } from '@syncfusion/ej2-base';

const BackhaulPage = (props) => {
  const { t } = useTranslation();
  const { theme, userInfo } = useContext(AppContext);
  const navigate = useNavigate();
  const moduleName = "Backhauls"

  const [backhaulTableData, setBackhaulTableData] = useState([]);
  const [loadingTableList, setLoadingTableList] = useState(false);
  const [sortOrder, setSortOrder] = useState('Ascending'); // or 'Descending' based on your default
  const [sortColumn, setSortColumn] = useState('TripNumber'); // Default sorting column
  const [pageSettings, setPageSettings] = useState({
    currentPage: 1,
    pageSize: 10,
    pageCount: 5,
    totalRecordsCount: 0
  });

  const [customerDDList, setCustomerDDList] = useState([]);
  const [loadingCustomerList, setLoadingCustomerList] = useState(true);
  const [customerDDText, setCustomerDDText] = useState(null);
  const [customerDDValue, setCustomerDDValue] = useState(null);
  const customerDDRef = useRef(null);

  useEffect(() => {
    getCustomerList().then(data=>{
      if(data){
        getAllTableData(data);
      }
    })
  }, []);
  

  const handlAuthorization = (error) => {
    console.log(error);
    if (error.code === "ERR_NETWORK") {
      showToast(t("No response from server."), 'error');
    }
    else if (error?.response?.status === 401) {
      showToast(t('Your session has expired. Please login again.'), 'error');
      logOutUser();
    }
    else if (error?.response?.status === 403) {
      //showToast(t("You do not have access to ")+ moduleName+ t(", please contact your account manager to gain access."), 'error');
      navigate('/access-denied', { state : { moduleName } });
    }
  }

  const getCustomerList = async () => {
    try{
      setLoadingCustomerList(true);
      let res = await agent.getCustomerListTrack();
      if(!(res.data.length > 0)){
        navigate('/access-denied', { state : { moduleName } });
        return;
      }
      let list = res.data.map(item => {
        const text = item.split('-')[0];
        return { text, value : item };
      });
      list = list.sort((a, b) => a.text.localeCompare(b.text));
      setCustomerDDList(list);
      setCustomerDDText(list[0].text)
      setCustomerDDValue(list[0].value)
      setLoadingCustomerList(false);
      return list[0].value;
    }
    catch(err){
      // setLoadingCustomerList(false);
      handlAuthorization(err);
    }
  }

  const handlChangeCustomerDD = (e) =>{
    let val = e.target.value;
    setCustomerDDText(val)
    setCustomerDDValue(customerDDList.find(item => item.text === val).value)
  }

  const getAllTableData = (customer) => {
    const convertData = (dataA) => {
      return dataA.map(data => ({
              ...data,
              trailerNumber: (data.trailerNumber ? data.trailerNumber : ""),
              orderStatus_fr: (data.orderStatus ? t(data.orderStatus, { lng: 'fr' }) : ""),
              origin_en: `${data.originName} ${data.originAddress}`,
              originDate_en: `S: ${formatDate(data.originSch, 'MM/DD/YYYY HH:mm')} A: ${formatDate(data.originActual, 'MM/DD/YYYY HH:mm')} C: ${formatDate(data.originActual, 'MM/DD/YYYY HH:mm')}`,
              destinationDate_en: `S: ${formatDate(data.destSch, 'MM/DD/YYYY HH:mm')} A: ${formatDate(data.destActual, 'MM/DD/YYYY HH:mm')} C: ${formatDate(data.destComplete, 'MM/DD/YYYY HH:mm')}`,
          }));
    }
    setLoadingTableList(true);
    agent.getCustomerBackhaulList({ customer }).then((response) => {
      setBackhaulTableData(convertData(response.data.items));
      setPageSettings({
        ...pageSettings,
        currentPage: response.data.currentPage,
        totalPages: response.data.totalPages,
      })
      setLoadingTableList(false);
    }).catch((error) => {
      setLoadingTableList(false);
      handlAuthorization(error);
    });
  }

  const loaderRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    if (loadingCustomerList) {
      let animation = new Animation();
      animation.animate(loaderRef.current, { name: 'FadeOut', duration: 800 });
    } else {
      let animation = new Animation();
      animation.animate(contentRef.current, { name: 'FadeIn', duration: 800 });
    }
  }, [loadingCustomerList]);

  useEffect(()=>{
    setLoadingTableList(true);
    setTimeout(() => {
    setLoadingTableList(false);
    }, 50);
  },[theme])

  return (
    <div>
      {/* <h1 onClick={()=>{ throw new Error('Simulated error from ChildComponentWithError'); }} className="mt-4 my-custom-text main-header-text flex-center">
      Email Error Test
            </h1> */}
      {loadingCustomerList ? (
        <div ref={loaderRef} style={{ height: "100vh" }} className="my-custom-loader-container">
          <Loading />
        </div>
      ) : (
    <div ref={contentRef} className="container-flex" style={{ padding: "1.5rem 0rem 4rem 1.5rem" }}>
      <h1 className="mt-4 my-custom-text main-header-text flex-center">{t('Backhauls')}</h1>
      <div>
        {customerDDList && customerDDList.length > 1 &&
        <div className='flex-center' id="dashboard-form" style={{ maxWidth: "100%", alignItems: 'end', flexWrap: 'wrap', gap: '1rem' }}>
              <div className="my-custom-text">
                <span className="form-field-label">
                  {t('Customer Name')}
                </span>
                <div className="">
                  <div className='daterangepicker-control-section'>
                    <DropDownListComponent
                      required={true}
                      onChange={handlChangeCustomerDD}
                      ref={customerDDRef}
                      value={customerDDText}
                      placeholder={t('Customer Name')}
                      floatLabelType="Never"
                      cssClass="e-medium"
                      className='e-field'
                      dataSource={customerDDList}
                      id="dropdown"
                      data-msg-containerid="errorForDropDown"
                    />
                  </div>
                </div>
              </div>
              <div>
                <button onClick={() => {
                  if (customerDDValue) {
                    getAllTableData(customerDDValue)
                  }
                }} disabled={loadingTableList} className="my-custom-btn" type="submit" >{t('Search')}</button>
              </div>
        </div>}
      </div>
      <div className='chart-container mt-4' style={{}}>
        <TripBackhaulStopTable
          activeTab="Backhaul"
          loadingTableList={loadingTableList}
          backhaulTableData={backhaulTableData}
          customer={customerDDValue}
        />
      </div>
    </div>
      )}
      </div>
  );
};

export default BackhaulPage;
