import "./Home.css";
import React from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import TokenProvider from "../TokenProvider/TokenProvider";
import { useState, useEffect, useRef } from "react";
import AppContext from "../Theme/AppContext";
import { RxHamburgerMenu } from 'react-icons/rx';
import { BiFile } from "react-icons/bi"; // import icons
import { Card, Container } from "react-bootstrap";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";

const Home = () => {
  const { token, setToken } = TokenProvider();
  const { t, i18n } = useTranslation();
  const { theme, toggleTheme } = useContext(AppContext);

  useEffect(() => {
    if (token && token.locale) {
      i18n.changeLanguage(token.locale);
    }
  }, [token]);
  

  // const FaqComponent = () => {
  //   const faqList = [
  //     {
  //       issue: "I entered a load in the Capacity Board, and it does not show up on the Trip Management page.",
  //       answer: "The customer needs to accept the load and update their systems. Only then a trip will be generated."
  //     },
  //     {
  //       issue: "I updated the ETA status for a trip. Nothing happens.",
  //       answer: "A notification with the update is sent to the customer and will not be reflected here."
  //     },
  //     {
  //       issue: "I received a cancellation email. Why can't I see the trip?",
  //       answer: "Cancelled trips are not shown. The trip was cancelled by the customer or replaced by another trip created in our system."
  //     },
  //     {
  //       issue: "The same loading slip is on multiple trips.",
  //       answer: "Please contact us at helpdesk@cedricmillar.com with the trip numbers."
  //     },
  //     {
  //       issue: "The weights are not correct for my trip.",
  //       answer: "Please contact us at helpdesk@cedricmillar.com with the trip numbers and correct weights."
  //     },
  //     {
  //       issue: "I need to cancel my trip.",
  //       answer: "Please contact the customer directly to request cancellation. Once updated in their systems, the trip will be cancelled in TMS."
  //     },
  //     {
  //       issue: "I am not sure what trip to invoice as I have received cancellations from active trips.",
  //       answer: "Please check Customer Portal for loading slip number and match it to trip number for invoicing"
  //     }
  //   ];
  //   return (
  //     <div className="faq-container">
  //       <ul className="faq-list">
  //         {faqList.map((ele,idx)=>(
  //           <li key={idx}>
  //           <strong>{t('Issue')}:</strong> {t(ele.issue)}
  //           <br />
  //           <strong>{t('Answer')}:</strong> {t(ele.answer)}
  //         </li>
  //         ))}
  //       </ul>
  //     </div>
  //   );
  // };

  return (
    <ErrorBoundary>
    <Container fluid style={{ padding: "1.5rem", paddingBottom: "4rem" }}>
      <h1 className="display-2 mt-4 my-custom-text">
        {t("Welcome to Customer Portal")}
      </h1>

      <h5 className="mt-4 my-custom-text">
        {t("Maximize the power of your supply chain with Cedric Millar’s CUSTOMER PORTAL!")}
      </h5>
      <h5 className="mt-4 my-custom-text">
        {t("Our Customer Portal will help you manage your capacity, track the status of your trips, look up trip rates in accordance to your final destination, download reports and much more – all helping your business create flow, uncover hidden savings, and enhance efficiencies in your supply chain operation.")}
      </h5>
      <h5 className="mt-4 my-custom-text">
      {t("Click")}<RxHamburgerMenu style={{margin:'0.3rem', paddingBottom:"0.2rem"}} />{t("in the top left corner to navigate the menu items.")}
      </h5>

      {/* <div className="display-6 mt-4 my-custom-text">
        {t("Help Guides")}
      </div>
      <Card
        style={{
          marginTop: "2rem",
          width: "max-content",
          color: "black",
          background:"#06DF94",
        }}
      >
        <Card.Body>
          <div style={{ marginBottom: ".5rem" }}> 
            <a className="download-file-link" href="https://cedricmillar.com/Documents/CarrierPortal/CP_CapacityBoard.pdf" target="_blank" rel="noreferrer">
              {t("Download File Capacity Board")} <BiFile /> 
            </a>
          </div>

          <div style={{ marginBottom: ".5rem" }}> 
            <a className="download-file-link" href="https://www.cedricmillar.com/Documents/CarrierPortal/CP_TripManagement.pdf" target="_blank" rel="noreferrer">
              {t("Download File Trip Management")} <BiFile /> 
            </a>
          </div>
        </Card.Body>
      </Card> */}

      {/* <div className="display-6 mt-4 my-custom-text">
        {" "}
        {t("FAQ's")}{" "}
      </div>
      <Card
        style={{
          marginTop: "2rem",
          width: "max-content",
          marginBottom: "1rem",
          color:"black",
          background: "#06DF94",
          overflow: "auto"
        }}
      >
        <Card.Body>
          <FaqComponent />
        </Card.Body>
      </Card> */}

      <div className="display-6 mt-4 my-custom-text">
        {" "}
        {t("Need help")}{" "}
      </div>
      <Card
        style={{
          marginTop: "2rem",
          width: "18rem",
          marginBottom: "1rem",
          color:"black",
          background: "#06DF94",
          overflow: "auto"
        }}
      >
        <Card.Body>
          {t("Contact Us")} <br /> <br />
          <small>
            {t("Email")}:{" "}
            <a
              style={{ textDecoration: "none", color: "black" }}
              href="mailto:helpdesk@cedricmillar.com"
              target="_blank"
              rel="noreferrer"
            >
              {t("helpdesk@cedricmillar.com")}
            </a>
          </small>
        </Card.Body>
      </Card>
    </Container>
    </ErrorBoundary>
  );
};
export default Home;