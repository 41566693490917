import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const NoPermissionPage = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation();

  return (
    <div>
      <Container className="mt-5 flex-center my-custom-text">
        <Row>
          <Col style={{textAlign:'center'}}>
            <h1>{t("Access Denied")}</h1>
            <div>{t("You do not have access to")} {location?.state?.moduleName ? t(location?.state?.moduleName) : t("this")}, {t("please contact your account manager to gain access.")}</div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NoPermissionPage;
