import React, { useState, useRef, useContext, useEffect } from 'react';
import { Inject } from '@syncfusion/ej2-react-calendars';
import { ColumnDirective, ColumnsDirective, CommandColumn, GridComponent, Page, Sort, Filter, Toolbar, ExcelExport, Edit } from '@syncfusion/ej2-react-grids';
import moment from 'moment';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import './Trips.css';
import agent from '../../api/agent';
import { BiSolidFileExport } from 'react-icons/bi';
import TokenProvider, { logOutUser } from '../TokenProvider/TokenProvider';
import { useTranslation } from 'react-i18next';
import AppContext from '../Theme/AppContext';
import PDFViewer from './PDFViewer';
import { formatDate, showToast } from '../Theme/Utils';
import Loading from '../Loading/Loading';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import { TiAttachment } from 'react-icons/ti';
import { useNavigate } from 'react-router-dom';
import { GrRotateRight, GrRotateLeft } from 'react-icons/gr';
import { BiDownload } from 'react-icons/bi';
import { IoIosArrowDown, IoMdReturnLeft } from 'react-icons/io';
import { IoMdClose } from 'react-icons/io';
import { Animation } from '@syncfusion/ej2-base';
import { Exception } from 'sass';

function StopDialogue(props) {
  const { t, i18n } = useTranslation();
  const {
    // loadingTripStop, tripStopList, tripStopGridRef, setIsGridDataLoaded, setSelectedTripNum, getPDFData
    modalFrom,
    args,
  } = props;
  const [tripStopList, setTripStopList] = useState([]);
  const [loadingTripStop, setLoadingTripStop] = useState(false);
  const { theme, userInfo, localeState } = useContext(AppContext);
  const tripStopGridRef = React.createRef();

  const [isPDFView, setIsPDFView] = useState(false);
  const [loadingTripStopImage, setLoadingTripStopImage] = useState(false);
  const [pdfContentJSON, setPdfContentJSON] = useState(null);
  const [rotationAngle, setRotationAngle] = useState(0);
  const [pdfKey, setKey] = useState(0);
  const [selectedTripNum, setSelectedTripNum] = useState(null);
  const [selectedShipToCode, setSelectedShipToCode] = useState(null);
  const [selectedStop, setSelectedStop] = useState(null);

  //BLOB docs
  const [blobPDF, setBlobPDF] = useState();

  const pdfviewRef = useRef(null);
  const gridViewRef = useRef(null);

  useEffect(() => {
    if (pdfviewRef.current && gridViewRef.current) {
      if (isPDFView) {
        let animation = new Animation();
        animation.animate(gridViewRef.current, { name: 'FadeOut', duration: 800 }); // ZoomIn FadeIn
        animation.animate(pdfviewRef.current, { name: 'ZoomIn', duration: 800 }); // ZoomOut FadeOut
        document.querySelector('.e-edit-dialog .e-dlg-header-content').style.display = 'none';
      } else {
        let animation = new Animation();
        animation.animate(gridViewRef.current, { name: 'ZoomIn', duration: 800 }); // ZoomOut FadeOut
        animation.animate(pdfviewRef.current, { name: 'FadeOut', duration: 800 }); // ZoomOut FadeOut
        document.querySelector('.e-edit-dialog .e-dlg-header-content').style.display = 'block';
      }
    }
  }, [isPDFView]);

  useEffect(() => {
    // console.log("this is called", args, "props", props);
    openTripStopListDialogue(args, modalFrom);
    // const interval = setInterval(() => { showToast("Hello!", "error");}, 7000);
    // return () => clearInterval(interval);
  }, []);

  const getPDFData = ({ shipToCode, seq, externalSequence }) => {
    // console.log("shipToCode, seq", shipToCode, seq);
    setIsPDFView(true);
    setLoadingTripStopImage(true);
    setSelectedShipToCode(shipToCode);
    setSelectedStop(seq);
    const params = {
      tripNumber: selectedTripNum,
      shipToCode,
      customer: props.customer,
    };

    if (params?.customer === 'RedChris-Newmont') {
      //we get the document name
      const temp = ['BOL'];
      agent.getDocuments(params.customer, JSON.stringify(temp), externalSequence).then((response) => {
        if (!response.data) {
          throw Exception;
        } else {
          //now we call the docment link api
          agent.getDocument(params.customer, 'BOL', response.data[0].fileName).then((responseGetDoc) => {
            console.log(responseGetDoc.data);
            setBlobPDF(responseGetDoc.data);
            setLoadingTripStopImage(false);
          });
        }
      });
    } else {
      agent
        .getCustomerTripStopImagePDF(params)
        .then((response) => {
          setPdfContentJSON(response.data);
          setLoadingTripStopImage(false);
        })
        .catch((error) => {
          // props.handlAuthorization(error);
          showToast(t('Unable to Retrieve Image.'), 'error');
          setLoadingTripStopImage(false);
        });
    }
  };

  const openTripStopListDialogue = (data, modalFrom) => {
    try {
      const convertData = (dataA) => {
        return dataA.map((data) => ({
          ...data,
          osrd_en: `D: ${data.damaged} S: ${data.short} O: ${data.overage} R: ${data.refusal}`,
          status_fr: data.status ? t(data.status, { lng: 'fr' }) : '',
          stopName_en: `${data.stopName}, ${data.city}, ${data.province}, Miles: ${data.distance}`,
          origin_dates_en: `S: ${formatDate(data.schOriginDateTZ, 'MM-DD-YYYY HH:mm')}, A: ${formatDate(data.actualOriginDateTZ, 'MM-DD-YYYY HH:mm')}`,
          schDate_en: `${formatDate(data.schArrivalBeginDate, 'MM-DD-YYYY HH:mm')}${data?.eta ? ` ${data.eta}` : ''}`,
          actualDate_en: `A: ${formatDate(data.actualArrivalTZ, 'MM-DD-YYYY HH:mm')} C: ${formatDate(data.actualCompleteTZ, 'MM-DD-YYYY HH:mm')}`,
        }));
      };
      if (data && data.tripNumber) {
        setLoadingTripStop(true);
        setSelectedTripNum(data.tripNumber);
        const params = { customer: props.customer };
        if (modalFrom === 'Trips') {
          agent
            .getAllTripsStopsList({ ...params, tripNumber: data.tripNumber })
            .then((response) => {
              let mappedData = convertData(response.data);
              setTripStopList(mappedData);
              setLoadingTripStop(false);
            })
            .catch((error) => {
              setTripStopList([]);
              setLoadingTripStop(false);
              // props.handlAuthorization(error);
            });
        } else {
          agent
            .getAllBackhaulStopsList({ ...params, tripNumber: data.tripNumber })
            .then((response) => {
              let mappedData = convertData(response.data);
              setTripStopList(mappedData);
              setLoadingTripStop(false);
            })
            .catch((error) => {
              setTripStopList([]);
              setLoadingTripStop(false);
              // props.handlAuthorization(error);
            });
        }
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const closeTripStopImageModal = () => {
    setIsPDFView(false);
    setPdfContentJSON(null);
    setSelectedShipToCode(null);
    setSelectedStop(null);
    setRotationAngle(0);
    setKey(0);
  };

  const handleRotate = (direction) => {
    let newRotationAngle;
    if (direction === 'right') {
      newRotationAngle = (rotationAngle + 90) % 360;
    } else {
      newRotationAngle = (rotationAngle - 90 + 360) % 360;
    }
    setRotationAngle(newRotationAngle);
    setKey((prevKey) => prevKey + 1);
    // setLoadingTripStopImage(true)
  };

  const downloadPdf = () => {
    if (pdfContentJSON?.imageContent) {
      const byteCharacters = atob(pdfContentJSON.imageContent);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `TripStop-${selectedTripNum}-${selectedShipToCode}.pdf`;
      a.click();
      window.URL.revokeObjectURL(url);
    }
  };

  return (
    <div>
      <ErrorBoundary compName="TRIP STOP Model">
        <div style={{ height: '70vh', width: '70vw' }}>
          {loadingTripStop && (
            <div className="my-custom-loader-container">
              <Loading />
            </div>
          )}
          <div style={{ display: !isPDFView ? 'block' : 'none' }}>
            {!loadingTripStop && (
              <div style={{ marginBottom: '2rem' }}>
                <div>
                  <ErrorBoundary compName="TRIP STOP Model: GridComponent">
                    {tripStopList && (
                      <GridComponent
                        ref={tripStopGridRef}
                        dataBound={() => {
                          if (tripStopGridRef && tripStopGridRef.current) {
                            if (tripStopGridRef.current.dataSource.length > 0) {
                              tripStopGridRef.current.selectRows([0]);
                              // setTimeout(() => {
                              //     setIsGridDataLoaded(true);
                              // }, 100);
                            }
                            // tripStopGridRef.current?.refreshHeader();
                            // tripStopGridRef.current?.autoFitColumns();
                          }
                        }}
                        id="tripback-stop-table"
                        locale={localeState === 'fr' ? 'fr-FR' : 'en-US'}
                        className="syncf-grid-comp mb-2"
                        dataSource={tripStopList ? tripStopList : []}
                        allowPaging={true}
                        allowSorting={true}
                        allowFiltering={tripStopList && tripStopList.length > 0}
                        recordClick={(args) => {
                          if (args.column.field === 'imageFound' && args.rowData.imageFound) {
                            getPDFData(args.rowData);
                          }
                        }}
                        actionFailure={(x) => console.log(' error in the $: ', x)}
                        pageSettings={{ pageSize: 7 }}
                        textWrapSettings={{ wrapMode: 'Header' }}
                        editSettings={{ allowEditing: false, allowDeleting: false }}
                        // editSettings={{ allowEditing: true, allowAdding: false ,allowDeleting: false, mode: "Dialog", template: gridTemplate }}
                        sortSettings={{
                          columns: [{ field: 'seq', direction: 'Ascending' }],
                        }}
                        filterSettings={{
                          type: 'Menu',
                        }}
                      >
                        <ColumnsDirective>
                          <ColumnDirective field="tripShipTo" headerText="id" visible={false} />
                          <ColumnDirective
                            field="imageFound"
                            headerText={t('Doc')}
                            headerTextAlign="Center"
                            disableHtmlEncode={false}
                            headerTemplate={(args) => (
                              <div style={{ paddingLeft: '11px' }} className="whtspc-preln">
                                {' '}
                                {args.headerText}
                              </div>
                            )}
                            width="60"
                            textAlign="Center"
                            template={(args) => {
                              return (
                                <div>
                                  {args.imageFound ? (
                                    <div
                                      // onClick={() => {
                                      //     getPDFData(args)
                                      // }}
                                      className="trip-num-col trip-stop-table"
                                    >
                                      <TiAttachment className="action-btn-table" />
                                    </div>
                                  ) : (
                                    <div></div>
                                  )}
                                </div>
                              );
                            }}
                          />
                          <ColumnDirective
                            field="osrd_en"
                            headerText={t('OS and D')}
                            headerTemplate={(args) => <div className="whtspc-preln">{args.headerText}</div>}
                            headerTextAlign="Center"
                            disableHtmlEncode={false}
                            allowFiltering={false}
                            allowSorting={false}
                            template={(data) => <div style={{ whiteSpace: 'pre-line' }}>{data.osrd_en}</div>}
                            width="80"
                            textAlign="Left"
                          />
                          <ColumnDirective
                            disableHtmlEncode={false}
                            field="seq"
                            headerText={t('Stop #')}
                            headerTemplate={(args) => <div className="whtspc-preln">{args.headerText}</div>}
                            headerTextAlign="Center"
                            width="80"
                            textAlign="Left"
                          />
                          <ColumnDirective
                            field="externalSequence"
                            disableHtmlEncode={false}
                            headerText={t('Seq')}
                            headerTextAlign="Center"
                            width="80"
                            textAlign="Center"
                          />
                          <ColumnDirective
                            field="shipToCode"
                            headerText={t('Ship To')}
                            headerTemplate={(args) => <div className="whtspc-preln">{args.headerText}</div>}
                            disableHtmlEncode={false}
                            headerTextAlign="Center"
                            width="80"
                            textAlign="Center"
                          />
                          <ColumnDirective
                            headerText={t('Stop')}
                            field="stopName_en"
                            allowFiltering={false}
                            allowSorting={false}
                            headerTextAlign="Center"
                            template={(data) => {
                              const fullName = `${data.stopName}`;
                              const miles = t('Miles') + `: ${data.distance}`;
                              const location = `${data.city}, ${data.province}`;
                              return (
                                <div style={{ whiteSpace: 'pre-line' }}>
                                  <div>{fullName}</div>
                                  <div>{location}</div>
                                  <div>{miles}</div>
                                </div>
                              );
                            }}
                            width="140"
                            disableHtmlEncode={false}
                            textAlign="Left"
                          />
                          <ColumnDirective
                            headerText={t('Origin Dates')}
                            headerTemplate={(args) => <div className="whtspc-preln">{args.headerText}</div>}
                            field="origin_dates_en"
                            headerTextAlign="Center"
                            template={(data) => {
                              return (
                                <div className="whtspc-preln">
                                  <div>{`S: ${formatDate(data.schOriginDateTZ, 'MM-DD-YYYY HH:mm')}`}</div>
                                  <div>{`A: ${formatDate(data.actualOriginDateTZ, 'MM-DD-YYYY HH:mm')}`}</div>
                                </div>
                              );
                            }}
                            width="140"
                            disableHtmlEncode={false}
                            textAlign="Left"
                            // filter={{ type: 'date' }}
                          />
                          <ColumnDirective
                            headerText={t('Sch Date')}
                            headerTemplate={(args) => <div className="whtspc-preln">{args.headerText}</div>}
                            field="schDate_en"
                            template={(data) => {
                              return (
                                <div className="whtspc-preln">
                                  <div>{formatDate(data.schArrivalBeginDate, 'MM-DD-YYYY HH:mm')}</div>
                                  <div style={{ color: 'red' }}>{data?.eta}</div>
                                </div>
                              );
                            }}
                            disableHtmlEncode={false}
                            headerTextAlign="Center"
                            width="120"
                            textAlign="Left"
                          />
                          <ColumnDirective
                            headerText={t('Actual Date')}
                            headerTemplate={(args) => <div className="whtspc-preln">{args.headerText}</div>}
                            field="actualDate_en"
                            allowFiltering={false}
                            allowSorting={false}
                            headerTextAlign="Center"
                            template={(data) => {
                              return (
                                <div>
                                  <div>{`A: ${formatDate(data.actualArrivalTZ, 'MM-DD-YYYY HH:mm')}`}</div>
                                  <div>{`C: ${formatDate(data.actualCompleteTZ, 'MM-DD-YYYY HH:mm')}`}</div>
                                </div>
                              );
                            }}
                            width="140"
                            disableHtmlEncode={false}
                            textAlign="Left"
                          />
                          <ColumnDirective
                            field={localeState === 'fr' ? 'status_fr' : 'status'}
                            headerText={t('Status')}
                            disableHtmlEncode={false}
                            headerTextAlign="Center"
                            width="90"
                            textAlign="Left"
                          />
                        </ColumnsDirective>
                        <Inject services={[Page, CommandColumn, Sort, Filter, Toolbar]} />
                      </GridComponent>
                    )}
                  </ErrorBoundary>
                </div>
              </div>
            )}
          </div>
          <div style={{ display: isPDFView ? 'block' : 'none' }} ref={gridViewRef}>
            <div className="custom-headerbar" ref={pdfviewRef} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
              <div className="my-custom-text e-bold" style={{ fontSize: 16, fontWeight: 500, color: theme === 'dark' ? 'white' : 'grey' }}>
                {isPDFView ? t('Stop #') + ' ' + selectedStop + ' ' + t('Images') : t('Stops Details') + ': ' + selectedTripNum}
              </div>
              <div>
                <GrRotateLeft title={t('Rotate Left')} onClick={() => handleRotate('left')} style={{ cursor: 'pointer' }} size={25} color="grey" />
                <GrRotateRight
                  title={t('Rotate Right')}
                  onClick={() => handleRotate('right')}
                  style={{ marginLeft: '1rem', cursor: 'pointer' }}
                  size={25}
                  color="grey"
                />
                <BiDownload title={t('Download PDF')} onClick={downloadPdf} style={{ marginLeft: '1rem', cursor: 'pointer' }} size={25} color="grey" />
                <IoIosArrowDown
                  title={t('Close PDF')}
                  size={25}
                  color="grey"
                  style={{ marginLeft: '1rem', cursor: 'pointer' }}
                  onClick={() => closeTripStopImageModal()}
                />
              </div>
            </div>
            <div>
              <div style={{ height: '73vh', width: '50vw', overflow: 'auto', margin: 'auto' }}>
                <>
                  {loadingTripStopImage ? (
                    <div className="my-custom-loader-container">
                      <Loading />
                    </div>
                  ) : (
                    <>
                      {blobPDF ? (
                        <PDFViewer
                          // pdfContent={pdfContentJSON.imageContent}
                          pdfContentBlob={blobPDF}
                          loadingTripStopImage={loadingTripStopImage}
                          setLoadingTripStopImage={setLoadingTripStopImage}
                          rotationAngle={rotationAngle}
                          pdfKey={pdfKey}
                        />
                      ) : isPDFView && pdfContentJSON && pdfContentJSON.imageContent ? (
                        <PDFViewer
                          pdfContent={pdfContentJSON.imageContent}
                          loadingTripStopImage={loadingTripStopImage}
                          setLoadingTripStopImage={setLoadingTripStopImage}
                          rotationAngle={rotationAngle}
                          pdfKey={pdfKey}
                        />
                      ) : (
                        <>
                          <h3 className="flex-center my-custom-text">
                            {t('No Image found')}
                          </h3>
                        </>
                      )}
                    </>
                  )}
                </>
              </div>
            </div>
          </div>
        </div>
      </ErrorBoundary>
    </div>
  );
}

export default StopDialogue;
