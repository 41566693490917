const ButtonLoading = (props) => {
	return (
		<div>
			<div className='spinner-border' role='status' style={{ color: "white", height: "1.2rem", width: "1.2rem", ...props.style }}>
				<span className='sr-only' style={{ color: "white" }}></span>
			</div>
		</div>
	);
};

export default ButtonLoading;
