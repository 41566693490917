// import { ScheduleComponent, Day, Week, WorkWeek, Month, Agenda, Inject } from '@syncfusion/ej2-react-schedule';
import React, { useEffect, useState, useRef, useContext, useCallback } from 'react';
import { DatePickerComponent, Inject, MaskedDateTime } from '@syncfusion/ej2-react-calendars';
import { ColumnDirective, ColumnsDirective, CommandColumn, GridComponent, Page, Sort, Filter, ExcelExport } from '@syncfusion/ej2-react-grids';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { TextBoxComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import agent from '../../api/agent';
import moment from 'moment';
import TokenProvider, { logOutUser } from '../TokenProvider/TokenProvider';
import { Card, Container, Row, Col } from 'react-bootstrap';
import { Tab, Nav } from 'react-bootstrap';
import { TabComponent, TabItemDirective, TabItemsDirective } from '@syncfusion/ej2-react-navigations';
import { useTranslation } from 'react-i18next';
import AppContext from '../Theme/AppContext';
import { showToast, startLoader, stopLoader } from '../Theme/Utils';
import { useNavigate } from 'react-router-dom';
import Loading from '../Loading/Loading';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { useFormik } from 'formik';
import { Animation } from '@syncfusion/ej2-base';

function RateCallDefaults() {
    const { t, i18n } = useTranslation();
    const { theme, userInfo } = useContext(AppContext);
    const moduleName = 'RateCall Controls';

    const { token, setToken } = TokenProvider();
    const cbToken = token.token;
    const username = token.username;
    const navigate = useNavigate();

    const [customerDDList, setCustomerDDList] = useState([]);
    const [loadingCustomerList, setLoadingCustomerList] = useState(true);
    const [customerDDText, setCustomerDDText] = useState(null);
    const [customerDDValue, setCustomerDDValue] = useState(null);
    const customerDDRef = useRef(null);
    const [placeKey, setPlaceKey] = useState(0);

    const initFormVal = {
        CourierAverageWeight: "",
        FreightAdder: "",
        FreightAdded: "",
        AverageWeightPallet: "",
        CubeToWeightRatio: "",
    }

    const [rateCallFormValue, setRateCallFormValue] = useState(initFormVal);
    const [isChangedForm, setIsChangedForm] = useState(false);

    const {
        values,
        setValues,
        // handleChange,
        handleBlur,
        handleSubmit,
        errors,
        touched,
        isValid,
        dirty,
    } = useFormik({
        initialValues: {
            CourierAverageWeight: '',
            FreightAdder: '',
            FreightAdded: '',
            AverageWeightPallet: '',
            CubeToWeightRatio: '',
        },
        validate: (values) => {
            const errors = {};
            const validateField = (fieldName, value) => {
                if (value <= 0 || value > 1000 || isNaN(value)) {
                    errors[fieldName] = `*Value must be a positive number.`;
                }
            };
            validateField('CourierAverageWeight', values.CourierAverageWeight);
            validateField('FreightAdder', values.FreightAdder);
            validateField('FreightAdded', values.FreightAdded);
            validateField('AverageWeightPallet', values.AverageWeightPallet);
            validateField('CubeToWeightRatio', values.CubeToWeightRatio);
            return errors;
        },
        onSubmit: (values) => {
            // console.log("rateCallFormValue", values);
            let customer = customerDDValue;
            let apiVal = {...values}
            startLoader()
            agent.updateRateCallDefaultValues(apiVal, { customer })
            .then((response) => {
                setDefaultRateCall(customer);
            })
            .catch((error) => {
                handlAuthorization(error);
            })
            .finally(()=>{
                stopLoader();
            })
        },
    });

    const [loadingTableList, setLoadingTableList] = useState(false);


    useEffect(() => {
        getCustomerList().then(data => {
            if(data){
                setDefaultRateCall(data);
            }
        })
    }, []);

    useEffect(()=>{
        if(i18n.language){
            setPlaceKey(prev=>prev+1);
        }
    },[i18n.language])

    const loaderRef = useRef(null);
    const contentRef = useRef(null);
  
    useEffect(() => {
      if (loadingCustomerList) {
        let animation = new Animation();
        animation.animate(loaderRef.current, { name: 'FadeOut', duration: 800 });
      } else {
        let animation = new Animation();
        animation.animate(contentRef.current, { name: 'FadeIn', duration: 800 });
      }
    }, [loadingCustomerList]);

    const getCustomerList = async () => {
        try {
            setLoadingCustomerList(true);
            let res = await agent.getCustomerListRateCall();
            if (!(res.data.length > 0)) {
                navigate('/access-denied', { state: { moduleName } });
                return;
            }
            let list = res.data.map(item => {
                const text = item.split('-')[0];
                return { text, value: item };
            });
            // Filter the data to include only the element that contains "hillman" in its value
            let hillmanElement = list.find(item => item.value.toLowerCase().includes('hillman'));
            if (!hillmanElement) {
                navigate('/access-denied', { state: { moduleName } });
                return;
            }
            list = [hillmanElement];
            /////
            list.sort((a, b) => a.text.localeCompare(b.text));
            setCustomerDDList(list);
            setCustomerDDText(list[0].text)
            setCustomerDDValue(list[0].value)
            setLoadingCustomerList(false);
            return list[0].value;
        }
        catch (err) {
            // setLoadingCustomerList(false);
            handlAuthorization(err);
        }
    }

    const handlChangeCustomerDD = (e) => {
        let val = e.target.value;
        if(isChangedForm && customerDDText !== val){
            let msg = t("You have unsaved changes. Are you sure you want to switch customer?");
            if (window.confirm(msg)) {
                setCustomerDDText(val);
                let custVal = customerDDList.find(item => item.text === val).value;
                setCustomerDDValue(custVal);
                setDefaultRateCall(custVal);
                setIsChangedForm(false)
            }
            else{
                // e.preventDefault();
                let val = customerDDText;
                setCustomerDDText(val);
                let custVal = customerDDList.find(item => item.text === val).value;
                setCustomerDDValue(custVal);
            }
        }
        else{
            setCustomerDDText(val)
            setCustomerDDValue(customerDDList.find(item => item.text === val).value)
        }
    }

    const handlAuthorization = (error) => {
        console.log(error);
        if (error.code === "ERR_NETWORK") {
            showToast(t("No response from server."), 'error');
        }
        else if (error?.response?.status === 401) {
            showToast(t('Your session has expired. Please login again.'), 'error');
            logOutUser();
        }
        else if (error?.response?.status === 403) {
            //showToast(t("You do not have access to ")+ moduleName+ t(", please contact your account manager to gain access."), 'error');
            navigate('/access-denied', { state: { moduleName } });
        }
    }

    const handleApiResponse = (responseData) => {
        const updatedValues = initFormVal;
        responseData.forEach(item => {
            switch (item.parameterName) {
                case 'ParcelAvgWeightPerBox':
                    updatedValues.CourierAverageWeight = parseFloat(item.parameterValue);
                    break;
                case 'ParcelMarkup':
                    updatedValues.FreightAdder = parseFloat(item.parameterValue);
                    break;
                case 'LTLMarkup':
                    updatedValues.FreightAdded = parseFloat(item.parameterValue);
                    break;
                case 'WeightPerPallet':
                    updatedValues.AverageWeightPallet = parseFloat(item.parameterValue);
                    break;
                case 'WeightPerCube':
                    updatedValues.CubeToWeightRatio = parseFloat(item.parameterValue);
                    break;
                default:
                    break;
            }
        });
        return updatedValues;
    };

    const setDefaultRateCall = (customer) => {
        setLoadingTableList(true);
        agent.getAllRateCallDefaultValues({ customer }).then((response) => {
            if (response.data && response.data.length > 0) {
                let APIRes = handleApiResponse(response.data);
                setRateCallFormValue(APIRes);
                setValues(APIRes)
                setIsChangedForm(false);
            }
            else {
                setValues(initFormVal);
                setRateCallFormValue(initFormVal);
            }
            setLoadingTableList(false);
        }).catch((error) => {
            handlAuthorization(error);
            setLoadingTableList(false);
        });
    }

    const compareValues = (formikValues, rateCallFormValue) => {
        for (const key in formikValues) {
            if (Number(formikValues[key]) !== Number(rateCallFormValue[key])) {
                setIsChangedForm(true);
                return;
            }
        }
        setIsChangedForm(false);
    };
    

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
        compareValues({ ...values, [name]: value }, rateCallFormValue);
    };
    
    return (
        <div>
        {loadingCustomerList ? (
          <div ref={loaderRef} style={{ height: "100vh" }} className="my-custom-loader-container">
            <Loading />
          </div>
        ) : (
        <div ref={contentRef} className="container-flex" style={{ padding: "1.5rem 0rem 4rem" }}>
            <div className='chart-container mt-4 my-custom-text' style={{ paddingLeft: "1.5rem" }}>
                <div style={{ padding: '0px 1rem 1rem 0px' }}>
                    <h1 className="mt-2 my-custom-text main-header-text flex-center">{t('RateCall Controls')}</h1>
                    <div>
                        {customerDDList && customerDDList.length > 1 &&
                            <div className='flex-center' style={{ maxWidth: "100%", alignItems: 'end', flexWrap: 'wrap', gap: '1rem' }}>
                                <div className="my-custom-text">
                                    <span className="form-field-label">
                                        {t('Customer Name')}
                                    </span>
                                    <div className="">
                                        <div className='daterangepicker-control-section'>
                                            <DropDownListComponent
                                                required={true}
                                                onChange={handlChangeCustomerDD}
                                                ref={customerDDRef}
                                                value={customerDDText}
                                                placeholder={t('Customer Name')}
                                                floatLabelType="Never"
                                                cssClass="e-medium"
                                                className='e-field'
                                                dataSource={customerDDList}
                                                id="dropdown"
                                                data-msg-containerid="errorForDropDown"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <button onClick={() => {
                                        if (customerDDValue) { setDefaultRateCall(customerDDValue) }
                                    }}
                                        disabled={loadingTableList}
                                        className="my-custom-btn" type="submit" >{t('Search')}</button>
                                </div>
                            </div>}
                    </div>
                    <div className='mt-4'>
                        {(!loadingTableList) ? (
                            <Container style={{ width: "100%" }}>
                                {/* weight  */}
                                <Row className='rate-call-def-row'>
                                    <Col xs={0} lg={2}></Col>
                                    <Col xs={3} lg={3} className='pd-0'>
                                        <div className="ratecall-def-label-item" style={{ paddingRight: '1rem' }}>
                                            {t("Courier Average Weight per Box:")}
                                        </div>
                                    </Col>
                                    <Col xs={5} lg={5} className='pd-0'>
                                        <div className='ratecall-def-item'>
                                            <div className="rate-call-field my-custom-text" style={{ display: "flex", justifyContent: "space-between" }}>
                                                <div className="rate-call-form-field-wrapper flex-center">
                                                    <TextBoxComponent
                                                        type="text"
                                                        className="form-control form-control-sm"
                                                        width={"200px"}
                                                        placeholder={t('Enter Value')}
                                                        value={values.CourierAverageWeight}
                                                        key={"CourierAverageWeight"+placeKey}
                                                        name='CourierAverageWeight'
                                                        id='CourierAverageWeight'
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                    {touched.CourierAverageWeight && errors.CourierAverageWeight && (
                                                        <div className="e-error">{t(errors.CourierAverageWeight)}</div>
                                                    )}
                                                </div>
                                                <div style={{ paddingLeft: "1rem", width: "250px", display: 'flex', alignItems: 'center' }}>
                                                    {t("Lbs.")}
                                                </div>
                                            </div>
                                        </div>

                                    </Col>
                                </Row>

                                {/* Freight  */}
                                <Row className='rate-call-def-row'>
                                    <Col xs={0} lg={2}></Col>
                                    <Col xs={3} lg={3} className='pd-0'>
                                        <div className="ratecall-def-label-item" style={{ paddingRight: '1rem' }}>
                                            {t("Freight adder for courier:")}
                                        </div>
                                    </Col>
                                    <Col xs={5} lg={5} className='pd-0'>
                                        <div className='ratecall-def-item'>
                                            <div className="rate-call-field my-custom-text" style={{ display: "flex", justifyContent: "space-between" }}>
                                                <div className="rate-call-form-field-wrapper flex-center">
                                                    <TextBoxComponent
                                                        type="text"
                                                        width={"200px"}
                                                        className="form-control form-control-sm"
                                                        placeholder={t('Enter Value')}
                                                        value={values.FreightAdder}
                                                        name='FreightAdder'
                                                        id='FreightAdder'
                                                        key={"FreightAdder"+placeKey}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                    {touched.FreightAdder && errors.FreightAdder && (
                                                        <div className="e-error">{t(errors.FreightAdder)}</div>
                                                    )}
                                                </div>
                                                <div style={{ paddingLeft: "1rem", width: "250px", display: 'flex', alignItems: 'center' }}>
                                                    {t("%(on the total value)")}
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                {/* LTL  */}
                                <Row className='rate-call-def-row'>
                                    <Col xs={0} lg={2}></Col>
                                    <Col xs={3} lg={3} className='pd-0'>
                                        <div className="ratecall-def-label-item" style={{ paddingRight: '1rem' }}>
                                            {t("Freight added for LTL:")}
                                        </div>
                                    </Col>
                                    <Col xs={5} lg={5} className='pd-0'>
                                        <div className='ratecall-def-item'>
                                            <div className="rate-call-field my-custom-text" style={{ display: "flex", justifyContent: "space-between" }}>
                                                <div className="rate-call-form-field-wrapper flex-center">
                                                    <TextBoxComponent
                                                        type="text"
                                                        width={"200px"}
                                                        className="form-control form-control-sm"
                                                        placeholder={t('Enter Value')}
                                                        value={values.FreightAdded}
                                                        name='FreightAdded'
                                                        id='FreightAdded'
                                                        key={"FreightAdded"+placeKey}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                    {touched.FreightAdded && errors.FreightAdded && (
                                                        <div className="e-error">{t(errors.FreightAdded)}</div>
                                                    )}
                                                </div>
                                                <div style={{ paddingLeft: "1rem", width: "250px", display: 'flex', alignItems: 'center' }}>
                                                    {t("%(on the total value)")}
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                {/* Pallet  */}
                                <Row className='rate-call-def-row'>
                                    <Col xs={0} lg={2}></Col>
                                    <Col xs={3} lg={3} className='pd-0'>
                                        <div className="ratecall-def-label-item" style={{ paddingRight: '1rem' }}>
                                            {t("Average weight per pallet:")}
                                        </div>
                                    </Col>
                                    <Col xs={5} lg={5} className='pd-0'>
                                        <div className='ratecall-def-item'>
                                            <div className="rate-call-field my-custom-text" style={{ display: "flex", justifyContent: "space-between" }}>
                                                <div className="rate-call-form-field-wrapper flex-center">
                                                    <TextBoxComponent
                                                        type="text"
                                                        width={"200px"}
                                                        className="form-control form-control-sm"
                                                        placeholder={t('Enter Value')}
                                                        value={values.AverageWeightPallet}
                                                        name='AverageWeightPallet'
                                                        id='AverageWeightPallet'
                                                        key={"AverageWeightPallet"+placeKey}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                    {touched.AverageWeightPallet && errors.AverageWeightPallet && (
                                                        <div className="e-error">{t(errors.AverageWeightPallet)}</div>
                                                    )}
                                                </div>
                                                <div style={{ paddingLeft: "1rem", width: "250px", display: 'flex', alignItems: 'center' }}>
                                                    {t("Lbs")}
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                {/* Cube  */}
                                <Row className='rate-call-def-row'>
                                    <Col xs={0} lg={2}></Col>
                                    <Col xs={3} lg={3} className='pd-0'>
                                        <div className="ratecall-def-label-item" style={{ paddingRight: '1rem' }}>
                                            {t("Cube to weight ratio:")}
                                        </div>
                                    </Col>
                                    <Col xs={5} lg={5} className='pd-0'>
                                        <div className='ratecall-def-item'>
                                            <div className="rate-call-field my-custom-text" style={{ display: "flex", justifyContent: "space-between" }}>
                                                <div className="rate-call-form-field-wrapper flex-center">
                                                    <TextBoxComponent
                                                        width={"200px"}
                                                        type="text"
                                                        className="form-control form-control-sm"
                                                        placeholder={t('Enter Value')}
                                                        value={values.CubeToWeightRatio}
                                                        name='CubeToWeightRatio'
                                                        id='CubeToWeightRatio'
                                                        key={"CubeToWeightRatio"+placeKey}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                    {touched.CubeToWeightRatio && errors.CubeToWeightRatio && (
                                                        <div className="e-error">{t(errors.CubeToWeightRatio)}</div>
                                                    )}
                                                </div>
                                                <div style={{ paddingLeft: "1rem", width: "250px", display: 'flex', alignItems: 'center' }}>
                                                    {t("Lbs per Cubic Ft")}
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className='rate-call-def-row'>
                                    <Col xs={12} lg={2}>
                                    </Col>
                                    <Col xs={12} lg={3} className='pd-0'>
                                    </Col>
                                    <Col xs={12} lg={3} className='pd-0'>
                                        <div className='ratecall-def-item flex-center'>
                                            <div className="rate-call-field my-custom-text">
                                                <div style={{ display: "flex", flexDirection: "column" }} className="">
                                                    <button
                                                        onClick={handleSubmit}
                                                        disabled={!isValid}
                                                        className="my-custom-btn" style={{ width: "max-content" }} type="submit" >{t('Update')}</button>
                                                    {isChangedForm && (<div className="e-error mt-1">{t("*You have unsaved changes.")}</div>)}
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={12} lg={3} className='pd-0'>
                                    </Col>
                                </Row>

                            </Container>
                        ) : (
                            <div className='my-custom-loader-container'><Loading /></div>
                        )}
                    </div>
                </div>
            </div>
        </div>
          )}
        </div>
    );
}

export default RateCallDefaults;