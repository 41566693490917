import React, { useEffect, useState, useRef, useContext } from 'react';
import "./DashBoard.css";
import { formatDate, showToast } from '../Theme/Utils';
import { logOutUser } from '../TokenProvider/TokenProvider';
import { useTranslation } from 'react-i18next';
import AppContext from '../Theme/AppContext';
import { useNavigate } from 'react-router-dom';
import Loading from '../Loading/Loading';
import { Animation } from '@syncfusion/ej2-base';
import agent from '../../api/agent';
import { getMetabaseToken, getMetabaseUserInfo, isValidMetabaseRefreshToken, isValidMetabaseToken, setMetabaseToken, setMetabaseUserInfo } from '../Auth/ProtectedRoute';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { useLocation } from 'react-router-dom';

const extractParamFromRoute = (route) => {
  const startIndex = "/dashboard-".length;
  return route.slice(startIndex);
};

const DashBoard = (props) => {
  const { t } = useTranslation();
  const { theme, userInfo, metabaseCurrentToken } = useContext(AppContext);
  const navigate = useNavigate();
  const moduleName = "Dashboard"

  const location = useLocation();
  const currentRoute = location.pathname;

  const [iframeUrl, setIframeUrl] = useState(null);
  const [metabaseDashBoardID, setMetabaseDashBoardID] = useState(null);
  const [loadingTableList, setLoadingTableList] = useState(false);
  const loaderRef = useRef(null);
  const contentRef = useRef(null);
  const [iframeDimensions, setIframeDimensions] = useState({
    width: 1000,
    height: 10,
  });

  const [customerDDList, setCustomerDDList] = useState([]);
  const [loadingCustomerList, setLoadingCustomerList] = useState(true);
  const [customerDDText, setCustomerDDText] = useState(null);
  const [customerDDValue, setCustomerDDValue] = useState(null);
  const customerDDRef = useRef(null);

  const [dashboardDDList, setDashboardDDList] = useState([]);
  const [dashboardDDText, setDashboardDDText] = useState(null);
  const [dashboardDDValue, setDashboardDDValue] = useState(null);
  const dashboardDDRef = useRef(null);

  const getCustomerList = async () => {
    try {
      setLoadingCustomerList(true);
      let res = await agent.GetCustomerMetabase();
      let customers = res.data.customers;
      let dashboards = res.data.dashboards;
      if (!(customers.length > 0)) {
        navigate('/access-denied', { state: { moduleName } });
        return;
      }
      if (!(dashboards.length > 0)) {
        showToast(t("The dashboard is not available."), 'error'); // navigate('/access-denied', { state: { moduleName } });
        return;
      }
      let list = customers.map(item => {
        const text = item.split('-')[0];
        return { text, value: item };
      });
      let listDash = dashboards.map(item => {
        let text = item;
        // if (item.endsWith("External")) {
        //   text = item.replace(/External$/, ''); // Remove "External" from the end
        // } else if (item.endsWith("Internal")) {
        //   text = "Internal " + item.replace(/Internal$/, ''); // Add "Internal" to the beginning
        // }
        let finalText = [];
        if(text.toLowerCase().includes("internal")){
          finalText.push("Internal") 
        }
        if(text.toLowerCase().includes("ops")){
          finalText.push("Ops") 
        }
        else if(text.toLowerCase().includes("finance")){
          finalText.push("Finance") 
        }
        text = finalText.join(' ');
        return { text: text, value: item };
      });
      /////
      list.sort((a, b) => a.text.localeCompare(b.text));
      listDash.sort((a, b) => a.text.localeCompare(b.text));
      setCustomerDDList(list);
      setCustomerDDText(list[0].text)
      setCustomerDDValue(list[0].value)

      setDashboardDDList(listDash);
      setDashboardDDText(listDash[0].text);
      setDashboardDDValue(listDash[0].value);
      setLoadingCustomerList(false);
      return { customer : list[0].value , dashboard: listDash[0].value};
    }
    catch (err) {
      handlAuthorization(err);
    }
  }

  // useEffect(() => {
  //   getCustomerList().then(data => {
  //     if (data) {
  //       getIframeData(data)
  //     }
  //   })
  // }, [currentRoute]);

  useEffect(()=>{
    getPlainIframeURL();
  },[])

  useEffect(() => {
    let animation = new Animation();
    animation.animate(contentRef.current, { name: 'FadeIn', duration: 800 });
}, []);

  const getPlainIframeURL = async() => {
    setLoadingTableList(true);
    // console.log("getMetabaseToken() ", getMetabaseToken())
    if(!getMetabaseToken()){
      navigate('/access-denied', { state: { moduleName } });
      return;
    }
    if (!isValidMetabaseToken()) {
      await handleMetabaseTokenRefresh();
    }
    setIframeUrl(`https://www.beacon-analytics.io/auth/sso?jwt=${getMetabaseToken()}&return_to=/`)
    setLoadingTableList(false);
  }

  useEffect(() => {
    const calculateIframeDimensions = () => {
      const navbarHeight = getNavbarHeight();
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;

      if (contentRef.current) {
        const newIframeHeight = windowHeight - navbarHeight;
        setIframeDimensions({
          width: windowWidth,
          height: newIframeHeight,
        });
      }
    };
    const getNavbarHeight = () => {
      const navbarElement = document.querySelector('nav.navbar.navbar-pills');
      return navbarElement ? navbarElement.offsetHeight : 0;
    };
    calculateIframeDimensions();
    window.addEventListener('resize', calculateIframeDimensions);
    return () => {
      window.removeEventListener('resize', calculateIframeDimensions);
    };
  }, [iframeUrl]);

  const handlAuthorization = (error) => {
    console.log(error);
    if (error.code === "ERR_NETWORK") {
      showToast(t("No response from server."), 'error');
    }
    else if (error?.response?.status === 401) {
      showToast(t('Your session has expired. Please login again.'), 'error');
      logOutUser();
    }
    else if (error?.response?.status === 403) {
      navigate('/access-denied', { state: { moduleName } });
    }
  }

  const handleMetabaseTokenRefresh = async () => {
    // console.log("isValidMetabaseRefreshToken ", isValidMetabaseRefreshToken(), ", Actual metabse token: ", getMetabaseToken());
    if (isValidMetabaseRefreshToken()) {
        const metabaseUserInfo = getMetabaseUserInfo();
        // console.log("isValidMetabaseRefreshToken ", isValidMetabaseRefreshToken(), " metabaseUserInfo", metabaseUserInfo);
        try {
            const res = await agent.getRefreshMetebaseToken({ token: metabaseUserInfo.refreshToken });
            let userInfo = getMetabaseUserInfo();
            // console.log('userInfo', userInfo);
            setMetabaseUserInfo({ ...userInfo, ...res.data });
            setMetabaseToken(res.data.token);
        } catch (err) {
            console.log("err", err);
        }
    }
    else{
      showToast(t('Your session has expired. Please login again.'), 'error');
      logOutUser();
    }
}

  const getIframeData = async (data) => {
    let themeIn = (theme === "dark" ? "night" : "light")
    setLoadingTableList(true);
    // setTimeout(()=>{
    //   setLoadingTableList(false);
    //   const url = "https://cedric-millar.metabaseapp.com/embed/dashboard/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJyZXNvdXJjZSI6eyJkYXNoYm9hcmQiOjM0fSwicGFyYW1zIjp7fSwiZXhwIjoxNzAwNjc5NzIxfQ.mgxZD9sA1D-RH8YHw7owkZ2MusrSRccZQVXcTt-kUF0?tab=1-activity&invoice_date=2023-06-01~2023-06-30"
    //   const extraParams = `#font=Noto%20Sans&theme=${themeIn}&bordered=true&titled=true`;
    //   setIframeUrl(url+extraParams)
    // }, 2000)

    // console.log("isValidMetabaseToken() ", isValidMetabaseToken());
    if (!isValidMetabaseToken()) {
      await handleMetabaseTokenRefresh();
    }
    setIframeUrl(`https://www.beacon-analytics.io/auth/sso?jwt=${getMetabaseToken()}&return_to=/`)
    setLoadingTableList(false);
    
    // OLD Code to generate URL from API call
    const dashboardType = extractParamFromRoute(currentRoute);
    data.dashboard = `Dashboard-External-${data.customer.split('-')[0]}-${dashboardType}`; // data.dashboard = `Dashboard-Internal-${data.customer.split('-')[0]}-${dashboardType}`;
    if(data.customer && data.dashboard){
      agent.getMatabaseDashBoardID(data).then((response) => {
        const ID = response.data;
        setIframeUrl(`https://www.beacon-analytics.io/auth/sso?jwt=${getMetabaseToken()}&return_to=/embed/dashboard/${ID}`)
        setMetabaseDashBoardID(ID);
        setLoadingTableList(false);
      }).catch((error) => {
        setMetabaseDashBoardID(null);
        setLoadingTableList(false);
        handlAuthorization(error);
      });
    }
    else{
      setMetabaseDashBoardID(null);
      showToast(t("The dashboard is not available."), 'error');
      setLoadingTableList(false);
    }
    // OLD Code to generate URL from API call
  }

  const handlChangeCustomerDD = (e) => {
    let val = e.target.value;
    setCustomerDDText(val)
    setCustomerDDValue(customerDDList.find(item => item.text === val).value)
  }

  const handlChangeDashBoardDD = (e) => {
    let val = e.target.value;
    setDashboardDDText(val)
    setDashboardDDValue(dashboardDDList.find(item => item.text === val).value)
  }

  // useEffect(() => {
  //   if (loadingCustomerList) {
  //     let animation = new Animation();
  //     animation.animate(loaderRef.current, { name: 'FadeOut', duration: 800 });
  //   } else {
  //     let animation = new Animation();
  //     animation.animate(contentRef.current, { name: 'FadeIn', duration: 800 });
  //   }
  // }, [loadingCustomerList]);

  const { width, height } = iframeDimensions;

  return (
    <div>
      {/* {loadingCustomerList ? (
        <div ref={loaderRef} style={{ height: "100vh" }} className="my-custom-loader-container">
          <Loading />
        </div>
      ) : ( */}
        <div ref={contentRef} className="container-flex" >
          <div className='chart-container my-custom-text' style={{ paddingLeft: "1.5rem",
          // marginTop:"1.5rem"
        }}>
            <div style={{ padding: '0px 1rem 1rem 0px' }}>
              {/* <h1 className="mt-2 my-custom-text main-header-text flex-center">{t('Dashboard')}</h1> */}
              {/* <div>
                {customerDDList && customerDDList.length > 1 && (
                  <div className='flex-center' style={{ maxWidth: "100%", alignItems: 'end', flexWrap: 'wrap', gap: '1rem', padding: "1.5rem 0rem 0rem" }}>
                    <div className="my-custom-text mt-4">
                      <span className="form-field-label">
                        {t('Customer Name')}
                      </span>
                      <div className="">
                        <div className='daterangepicker-control-section'>
                          <DropDownListComponent
                            required={true}
                            onChange={handlChangeCustomerDD}
                            ref={customerDDRef}
                            value={customerDDText}
                            placeholder={t('Customer Name')}
                            floatLabelType="Never"
                            cssClass="e-medium"
                            className='e-field'
                            dataSource={customerDDList}
                            id="dropdown"
                            data-msg-containerid="errorForDropDown"
                          />
                        </div>
                      </div>
                    </div>
                    {(customerDDList && customerDDList.length > 1) &&
                    (<div>
                      <button onClick={() => {
                        if (customerDDValue && dashboardDDValue) { getIframeData({ customer: customerDDValue, dashboard : dashboardDDValue}) }
                      }}
                        disabled={loadingTableList}
                        className="my-custom-btn" type="submit" >{t('Search')}</button>
                    </div>)}
                  </div>
                  )}
              </div> */}

              <div className='chart-container mt-4 flex-center'>
              {/* {(!loadingTableList && metabaseDashBoardID) ? ( */}
                {!loadingTableList ? (
                  <div style={{paddingBottom:'1.5rem', width:'max-content',height:'max-content',background:'white'}}>
                    <iframe
                      src={iframeUrl
                        // + `#font=Noto%20Sans&bordered=false&titled=true`
                        // +`&theme=${(theme === "dark" ? "night" : "light")}`
                      }
                      title='FAP Dashboard'
                      width={width}
                      height={height}
                      // sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-downloads"
                    />
                  </div>
                ) : (
                  <div className='my-custom-loader-container' style={{ height: "100vh" }}><Loading /></div>
                )}
              </div>
            </div>
          </div>
        </div>
      {/* )} */}
    </div>
  );
};

export default DashBoard;
