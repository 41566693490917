import React, { useEffect, useState, useRef, useContext } from 'react';
import agent from '../../api/agent';
import { showToast } from '../Theme/Utils';
import { logOutUser } from '../TokenProvider/TokenProvider';
import { useTranslation } from 'react-i18next';
import AppContext from '../Theme/AppContext';
import { useNavigate } from 'react-router-dom';
import { CheckBoxSelection, DropDownListComponent, MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import Loading from '../Loading/Loading';
import { Animation } from '@syncfusion/ej2-base';
import { FormValidator } from '@syncfusion/ej2-inputs'; // Correct import
import { Inject, TabComponent, TabItemsDirective, TabItemDirective } from '@syncfusion/ej2-react-navigations';
import { ColumnDirective, ColumnsDirective, GridComponent, Page, Sort, Filter, Toolbar, DetailRow } from '@syncfusion/ej2-react-grids';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import './Document.css';

const Documents = () => {
  const { t, i18n } = useTranslation();
  const { theme, userInfo } = useContext(AppContext);
  const navigate = useNavigate();
  const moduleName = 'Documents';

  // State Variables
  const [searchText, setSearchText] = useState('');
  const [documentTypesSelected, setDocumentTypesSelected] = useState([]);
  const [loadingTableList, setLoadingTableList] = useState(false);
  const [customerDDList, setCustomerDDList] = useState([]);
  const [loadingCustomerList, setLoadingCustomerList] = useState(true);
  const [customerDDText, setCustomerDDText] = useState(null);
  const [customerDDValue, setCustomerDDValue] = useState(null);
  const [documentData, setDocumentData] = useState({});
  const [activeTab, setActiveTab] = useState(0);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [type, setType] = useState('');
  const [columnConfig, setColumnConfig] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentDocument, setCurrentDocument] = useState({
    downloadLink: '',
    documentName: '',
  });
  const [initialState, setInitialState] = useState({});

  // Refs
  const loaderRef = useRef(null);
  const contentRef = useRef(null);
  const formRef = useRef(null);
  const gridRef = useRef(null);

  // FormValidator Instance
  const formValidatorRef = useRef(null);

  // Initialize FormValidator on component mount and when form elements are rendered
  useEffect(() => {
    if (formRef.current) {
      formValidatorRef.current = new FormValidator('#documentForm', {
        rules: {
          customerName: {
            required: [true, t('Customer Name is required')],
          },
          documentType: {
            required: [true, t('Document Type is required')],
          },
          docRef: {
            required: [true, t('Order Number is required')],
          },
        },
        customPlacement: (inputElement, error) => {
          const msgContainerId = inputElement.getAttribute('data-msg-containerid');
          if (msgContainerId) {
            const msgContainer = document.getElementById(msgContainerId);
            if (msgContainer) {
              msgContainer.appendChild(error);
            }
          }
        },
      });
    }
  }, [customerDDList, documentTypes, formRef]); // Dependencies ensure the validator initializes after form fields are available

  // Handle Theme Change Animation
  useEffect(() => {
    setLoadingTableList(true);
    setTimeout(() => {
      setLoadingTableList(false);
    }, 50);
  }, [theme]);

  // Fetch Customer List on Component Mount
  useEffect(() => {
    getCustomerList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  // Fetch Documents Data when Customer Selection Changes
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!customerDDValue) {
          return;
        }
        const response = await agent.getCustomerDocuments(customerDDValue);

        if (!response.data) {
          setDocumentTypes([]);
          setInitialState({});
          setDocumentData({});
          setColumnConfig({});
          return;
        }

        const result = response.data.reduce((acc, item) => {
          acc[item] = { data: [], loading: false };
          return acc;
        }, {});

        const columnConfigPromises = response.data.map(async (type) => {
          const configResponse = await agent.getColumnFormat(type);
          return { [type]: configResponse.data };
        });

        // Wait for all column configuration requests to complete
        const columnConfigs = await Promise.all(columnConfigPromises);

        // Merge all column configurations into a single object
        const mergedColumnConfig = columnConfigs.reduce((acc, config) => ({ ...acc, ...config }), {});

        setDocumentTypes(response.data);
        setInitialState(result);
        setDocumentData(result);
        setColumnConfig(mergedColumnConfig);
      } catch (error) {
        console.error('Error fetching data:', error);
        handlAuthorization(error);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerDDValue, i18n.language]);

  // Set Default Type when Document Types Selected Change
  useEffect(() => {
    if (!type && documentTypesSelected?.length > 0) {
      setType(documentTypesSelected[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentTypesSelected, i18n.language]);

  // Update Type based on Active Tab
  useEffect(() => {
    const selectedType = documentTypesSelected[activeTab];
    setType(selectedType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, documentTypesSelected]);

  // Handle Animation for Loading Customer List
  useEffect(() => {
    if (loadingCustomerList) {
      let animation = new Animation();
      animation.animate(loaderRef.current, { name: 'FadeOut', duration: 800 });
    } else {
      let animation = new Animation();
      animation.animate(contentRef.current, { name: 'FadeIn', duration: 800 });
    }
  }, [loadingCustomerList]);

  // Fetch Customer List Function
  const getCustomerList = async () => {
    try {
      setLoadingCustomerList(true);
      let res = await agent.getCustomerListTrack();
      if (!(res.data.length > 0)) {
        navigate('/access-denied', { state: { moduleName } });
        return;
      }
      let list = res.data.map((item) => {
        const text = item.split('-')[0];
        return { text, value: item };
      });
      list = list.sort((a, b) => a.text.localeCompare(b.text));
      setCustomerDDList(list);
      setCustomerDDText(list[0].text);
      setCustomerDDValue(list[0].value);
      setLoadingCustomerList(false);
      return list[0].value;
    } catch (err) {
      setLoadingCustomerList(false);
      handlAuthorization(err);
    }
  };

  // Handle Change in Customer DropDown
  const handlChangeCustomerDD = (e) => {
    let val = e.value; // Use e.value instead of e.target.value for Syncfusion components
    setCustomerDDText(val);
    const selectedItem = customerDDList.find((item) => item.text === val);
    setCustomerDDValue(selectedItem ? selectedItem.value : null);
  };

  // Function to Fetch Data for a Specific Document Type
  const getTableDataByType = async (customer, type, searchText) => {
    try {
      if (searchText?.length === 0 || !type || !customer) {
        return;
      }

      const response = await agent.getDocuments(customer, JSON.stringify(type), searchText);

      response?.data.forEach((item) => {
        Object.keys(item).forEach((key) => {
          if (key.toLowerCase().includes('date') && item[key]) {
            item[key] = new Date(item[key]);
          }
        });
      });

      // Assuming the response structure contains 'items'
      const convertedData = convertData(response.data, type);

      setDocumentData(convertedData);
      setInitialState({});
    } catch (error) {
      handlAuthorization(error);
    }
  };

  // Function to Fetch All Data for All Document Types
  const getAllTableData = async (customer, documentTypesSelected, searchText) => {
    if (!documentTypesSelected || documentTypesSelected?.length === 0) {
      return;
    }

    // Fetch data for each selected document type
    await getTableDataByType(customer, documentTypesSelected, searchText);
  };

  // Convert Data Function
  const convertData = (data, type) => {
    const converted = { ...initialState };

    data.forEach((category) => {
      if (!converted[category.type]) {
        converted[category.type] = { data: [], loading: false };
      }

      if (category.length === 0) return;

      converted[category.type].data.push(category);
    });

    return converted;
  };

  // Get Columns by Document Type
  const getColumnsByType = (type) => {
    return columnConfig[type]; // Return specific columns based on type
  };

  // Handle Tab Selection
  const onTabSelected = (args) => {
    setActiveTab(args.selectedIndex);
  };

  // Open Modal Handler
  const openModal = async (downloadLink, documentName) => {
    try {
      const response = await agent.getDocument(customerDDValue, JSON.stringify(type), documentName);
      const link = response.data;

      setCurrentDocument({ downloadLink: link, documentName });
      setIsModalOpen(true);
    } catch (error) {
      handlAuthorization(error);
    }
  };

  // Close Modal Handler
  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentDocument({ downloadLink: '', documentName: '' });
  };

  // Handle Authorization Errors
  const handlAuthorization = (error) => {
    console.log(error);
    if (error.code === 'ERR_NETWORK') {
      showToast(t('No response from server.'), 'error');
    } else if (error?.response?.status === 401) {
      showToast(t('Your session has expired. Please login again.'), 'error');
      logOutUser();
    } else if (error?.response?.status === 403) {
      navigate('/access-denied', { state: { moduleName } });
    } else {
      showToast(t('An unexpected error occurred.'), 'error');
    }
  };

  // Download Template Component
  const DownloadTemplate = ({ downloadLink, documentName, openModal }) => {
    const handleClick = () => {
      openModal(downloadLink, documentName);
    };

    return (
      <span
        className="e-icons e-download"
        title={t('View Document')}
        style={{
          cursor: 'pointer',
          display: 'inline-block',
          fontSize: '18px',
        }}
        onClick={handleClick}
      ></span>
    );
  };

  // Download Template Function for Grid
  const downloadTemplate = (props) => {
    return <DownloadTemplate downloadLink={props.downloadLink} documentName={props.fileName} openModal={openModal} />;
  };

  // Grid Component
  const grid = (type) => {
    if (!documentData || documentTypesSelected.length === 0 || !type) {
      return <div> {t('Nothing to show')}</div>;
    }

    return (
      <GridComponent
        key={type}
        dataSource={documentData[type]?.data}
        allowPaging
        allowSorting
        toolbar={['Search']}
        rowHeight={50}
        ref={gridRef}
        childGrid={null}
      >
        <ColumnsDirective>
          <ColumnDirective key={'1'} template={downloadTemplate} headerText={t('Download')} width={70} textAlign={'Center'} />
          {getColumnsByType(type)?.map((col, idx) => (
            <ColumnDirective key={idx} field={col.field} headerText={t(col.headerText)} width={col.width} textAlign={col.textAlign} format={col.format} />
          ))}
        </ColumnsDirective>
        <Inject services={[Page, Sort, Filter, Toolbar, DetailRow]} />
      </GridComponent>
    );
  };

  // Validate and Search Function
  const validateAndSearch = () => {
    if (formValidatorRef.current.validate()) {
      setDocumentData(initialState);
      getAllTableData(customerDDValue, documentTypesSelected, searchText);
    }
  };

  function itemTemplate(data) {
    if (!data) {
      return;
    }
    const result = Object.values(data).join('');
    return (
      <span>
        <span className="name">{t(result)}</span>
      </span>
    );
  }

  function valueTemplate(data) {
    return <span>${data.FirstName}</span>;
  }

  return (
    <div>
      {loadingCustomerList ? (
        <div ref={loaderRef} style={{ height: '100vh' }} className="my-custom-loader-container">
          <Loading />
        </div>
      ) : (
        <div ref={contentRef} className="container-flex" style={{ padding: '1.5rem 0rem 4rem 1.5rem' }}>
          <h1 className="mt-4 mb-4 my-custom-text main-header-text flex-center" style={{ fontWeight: 400, padding: '2rem' }}>
            {t('Document Repository')}
          </h1>
          <form id="documentForm" ref={formRef}>
            <div
              className="flex-center"
              style={{
                maxWidth: '100%',
                gap: '1rem',
                flexWrap: 'wrap',
                alignItems: 'end',
              }}
            >
              {/* Customer Name */}
              <div className="form-field" style={{ display: 'flex', flexDirection: 'column' }}>
                <span className="form-field-label" style={{ color: theme === 'dark' ? 'white' : 'black' }}>
                  {t('Customer Name')} <span style={{ color: 'red' }}> *</span>
                </span>
                <DropDownListComponent
                  id="customerName"
                  data-msg-containerid="customerErrorContainer"
                  required={true}
                  onChange={handlChangeCustomerDD}
                  value={customerDDText}
                  placeholder={t('Customer Name')}
                  floatLabelType="Never"
                  dataSource={customerDDList}
                  cssClass="e-input-group"
                />
                <span id="customerErrorContainer" className="e-error-message"></span>
              </div>

              {/* Document Type */}
              <div className="form-field" style={{ display: 'flex', flexDirection: 'column', minWidth: '200px' }}>
                <span className="form-field-label" style={{ color: theme === 'dark' ? 'white' : 'black' }}>
                  {t('Document Type')} <span style={{ color: 'red' }}> *</span>
                </span>
                <MultiSelectComponent
                  id="documentType"
                  data-msg-containerid="documentTypeErrorContainer"
                  required={true}
                  onChange={(e) => setDocumentTypesSelected(e.value)}
                  valueTemplate={valueTemplate.bind(this)}
                  itemTemplate={itemTemplate.bind(this)}
                  dataSource={documentTypes}
                  placeholder={t('Select Type')}
                  floatLabelType="Never"
                  mode="CheckBox"
                  cssClass="e-input-group"
                  allowFiltering={false}
                >
                  <Inject services={[CheckBoxSelection]} />
                </MultiSelectComponent>
                <span id="documentTypeErrorContainer" className="e-error-message"></span>
              </div>

              {/* Search Text (docRef) */}
              <div className="form-field" style={{ display: 'flex', flexDirection: 'column' }}>
                <span className="form-field-label" style={{ color: theme === 'dark' ? 'white' : 'black' }}>
                  {t('Search')} <span style={{ color: 'red' }}> *</span>
                </span>
                <TextBoxComponent
                  id="docRef"
                  data-msg-containerid="searchErrorContainer"
                  required={true}
                  onChange={(e) => setSearchText(e.value)}
                  placeholder={t('Order Number')}
                  floatLabelType="Never"
                  cssClass="e-input-group"
                />
                <span id="searchErrorContainer" className="e-error-message"></span>
              </div>
            </div>

            {/* Search Button */}
            <div style={{ marginTop: '1rem', display: 'flex', justifyContent: 'center' }}>
              <button type="button" onClick={validateAndSearch} disabled={loadingTableList} className="my-custom-btn" style={{ textAlign: 'center' }}>
                {t('Search')}
              </button>
            </div>
          </form>

          {/* Modal for Document Viewer */}
          <DialogComponent
            visible={isModalOpen}
            width="80%"
            height="80%"
            header={currentDocument.documentName || t('Document Viewer')}
            showCloseIcon={true}
            close={closeModal}
            isModal={true}
            target={document.getElementById('root')}
            animationSettings={{ effect: 'Fade' }}
          >
            <div style={{ height: '100%', width: '100%' }}>
              {currentDocument.downloadLink ? (
                (() => {
                  const { downloadLink } = currentDocument;
                  const lowerCaseLink = downloadLink.toLowerCase();
                  if (lowerCaseLink.includes('.pdf')) {
                    return (
                      <iframe
                        src={downloadLink}
                        title={currentDocument.documentName}
                        style={{
                          width: '100%',
                          height: '100%',
                          border: 'none',
                        }}
                      ></iframe>
                    );
                  } else if (
                    lowerCaseLink.includes('.jpg') ||
                    lowerCaseLink.includes('.jpeg') ||
                    lowerCaseLink.includes('.png') ||
                    lowerCaseLink.includes('.gif')
                  ) {
                    return (
                      <img
                        src={downloadLink}
                        alt={currentDocument.documentName}
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'contain',
                        }}
                      />
                    );
                  } else {
                    return (
                      <div
                        style={{
                          textAlign: 'center',
                          paddingTop: '50px',
                        }}
                      >
                        <p> {t('Cannot display this file type.')} </p>
                        <a href={downloadLink} target="_blank" rel="noopener noreferrer">
                          <button type="button"> {t('Download File')}</button>
                        </a>
                      </div>
                    );
                  }
                })()
              ) : (
                <div
                  style={{
                    textAlign: 'center',
                    paddingTop: '50px',
                  }}
                >
                  <p> {t('Loading...')} </p>
                  <Loading />
                </div>
              )}
            </div>
          </DialogComponent>

          {/* Tabs for Each Document Type */}
          <div className="mt-4" style={{ marginRight: '1.5rem' }}>
            {documentData && documentTypesSelected.length > 0 ? (
              <TabComponent selected={onTabSelected} heightAdjustMode="None" allowDragAndDrop={false}>
                <TabItemsDirective>
                  {documentTypesSelected.map((docType, index) =>
                    docType ? <TabItemDirective key={index} header={{ text: t(docType) }} content={() => grid(docType)} /> : null
                  )}
                </TabItemsDirective>
              </TabComponent>
            ) : (
              <div style={{ textAlign: 'center' }}> {t('Select A Document Type')} </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Documents;
