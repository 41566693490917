// import { ScheduleComponent, Day, Week, WorkWeek, Month, Agenda, Inject } from '@syncfusion/ej2-react-schedule';
import React, { useState, useContext, useEffect } from 'react';
import { Inject } from '@syncfusion/ej2-react-calendars';
import {
    ColumnDirective, ColumnsDirective, CommandColumn, GridComponent, Page, Sort, Filter, Toolbar, ExcelExport, Edit} from '@syncfusion/ej2-react-grids';
import moment from 'moment';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import "./Trips.css";
import agent from '../../api/agent';
import { BiSolidFileExport } from 'react-icons/bi'
import TokenProvider, { logOutUser } from '../TokenProvider/TokenProvider';
import { useTranslation } from 'react-i18next';
import AppContext from '../Theme/AppContext';
import PDFViewer from './PDFViewer';
import { formatDate, showToast } from '../Theme/Utils';
import Loading from '../Loading/Loading';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import { TiAttachment } from 'react-icons/ti';
import { useNavigate } from 'react-router-dom';
import { GrRotateRight, GrRotateLeft } from 'react-icons/gr';
import { BiDownload } from 'react-icons/bi';
import StopDialogue from './StopDialogue';
import { IoOpenOutline } from "react-icons/io5";

const TripBackhaulStopTable = (props) => {
    const { t, i18n } = useTranslation();
    const { tripTableData, loadingTableList, backhaulTableData } = props;
    const navigate = useNavigate();
    let moduleName;

    if (props.activeTab === "Trips") {
        moduleName = "Trips"
    }
    else {
        moduleName = "Backhauls"
    }

    const { token, setToken } = TokenProvider();
    const { theme, userInfo, localeState } = useContext(AppContext);

    const [tripStopList, setTripStopList] = useState([]);
    const [stopDialogueStatus, setStopDialogueStatus] = useState(false);
    const [selectedTripNum, setSelectedTripNum] = useState(null);
    const [selectedShipToCode, setSelectedShipToCode] = useState(null);
    const [selectedStop, setSelectedStop] = useState(null);

    const [isDialogOpenPDF, setIsDialogOpenPDF] = useState(false);
    const [pdfContentJSON, setPdfContentJSON] = useState(null);

    const [isGridDataLoaded, setIsGridDataLoaded] = useState(false);
    const [rotationAngle, setRotationAngle] = useState(0);
    const [pdfKey, setKey] = useState(0);

    const [loadingTripStop, setLoadingTripStop] = useState(false);
    const [loadingTripStopImage, setLoadingTripStopImage] = useState(false);
    const tripStopGridRef = React.createRef();
    const tripsGridRef = React.createRef();
    const backhaulGridRef = React.createRef();
    let condition = false;
    const sortingOrderRef = React.createRef(null);

    const [sortingOrderTrip, setSortingOrderTrip] = useState([
        { field: 'tripNumber', direction: 'Descending' },
        { field: 'routeNumber', direction: 'Ascending' }
    ]);

    const handlAuthorization = (error) => {
        console.log(error);
        if (error.code === "ERR_NETWORK") {
            showToast(t("No response from server."), 'error');
        }
        else if (error?.response?.status === 401) {
            showToast(t('Your session has expired. Please login again.'), 'error');
            logOutUser();
        }
        else if (error?.response?.status === 403) {
            //showToast(t("You do not have access to ") + moduleName + t(", please contact your account manager to gain access."), 'error');
            navigate('/access-denied', { state: { moduleName } });
        }
    }

    // const openTripStopListDialogue = (data, modalFrom) => {
    //     try {
    //         const convertData = (dataA) => {
    //             return dataA.map(data => ({
    //                     ...data,
    //                     osrd_en: `D: ${data.damaged} S: ${data.short} O: ${data.overage} R: ${data.refusal}`,
    //                     status_fr: (data.status ? t(data.status, { lng: 'fr' }) : ""),
    //                     stopName_en: `${data.stopName}, ${data.city}, ${data.province}, Miles: ${data.distance}`,
    //                     origin_dates_en: `S: ${formatDate(data.schOriginDateTZ, 'MM-DD-YYYY HH:mm')}, A: ${formatDate(data.actualOriginDateTZ, 'MM-DD-YYYY HH:mm')}`,
    //                     schDate_en: `${formatDate(data.schDate, 'MM-DD-YYYY HH:mm')}${data?.eta ? ` ${data.eta}` : ''}`,
    //                     actualDate_en: `A: ${formatDate(data.actualArrivalTZ, 'MM-DD-YYYY HH:mm')} C: ${formatDate(data.actualCompleteTZ, 'MM-DD-YYYY HH:mm')}`,
    //                 }));
    //         }
    //         if (data && data.tripNumber) {
    //             setLoadingTripStop(true);
    //             setSelectedTripNum(data.tripNumber);
    //             const params = { customer: props.customer }

    //             if (modalFrom === "Trips") {
    //                 agent.getAllTripsStopsList({ ...params, tripNumber: data.tripNumber }).then((response) => {
    //                     let mappedData = convertData(response.data);
    //                     setTripStopList(mappedData);
    //                     setLoadingTripStop(false);
    //                     setStopDialogueStatus(true);
    //                     return mappedData;
    //                 }).catch((error) => {
    //                     setTripStopList([]);
    //                     setLoadingTripStop(false);
    //                     setStopDialogueStatus(true);
    //                     handlAuthorization(error);
    //                     return null;
    //                 });
    //             }
    //             else {
    //                 agent.getAllBackhaulStopsList({ ...params, tripNumber: data.tripNumber }).then((response) => {
    //                     let mappedData = convertData(response.data);
    //                     setTripStopList(mappedData);
    //                     setStopDialogueStatus(true);
    //                     setLoadingTripStop(false);
    //                     return mappedData;
    //                 }).catch((error) => {
    //                     setTripStopList([]);
    //                     setLoadingTripStop(false);
    //                     setStopDialogueStatus(true);
    //                     handlAuthorization(error);
    //                     return null;
    //                 });
    //             }
    //         }
    //     }
    //     catch (err) {
    //         console.log("err", err);
    //     }
    // };
    // const dialogClose = () => {
    //     setStopDialogueStatus(false);
    //     setIsGridDataLoaded(false);
    // };
    // const dialogOpen = () => {
    //     setStopDialogueStatus(true);
    // };
    // const closeTripStopImageModal = () => {
    //     setIsDialogOpenPDF(false)
    //     setPdfContentJSON(null);
    //     setSelectedShipToCode(null);
    //     setSelectedStop(null);
    //     setRotationAngle(0);
    //     setKey(0);
    // }

    const getPDFData = ({ shipToCode, seq }) => {
        setLoadingTripStopImage(true);
        setIsDialogOpenPDF(true);
        setSelectedShipToCode(shipToCode);
        setSelectedStop(seq);
        const params = {
            tripNumber: selectedTripNum,
            shipToCode,
            customer: props.customer,
        }
        agent.getCustomerTripStopImagePDF(params).then((response) => {
            setPdfContentJSON(response.data);
        }).catch((error) => {
            // handlAuthorization(error);
            showToast(t("Unable to Retrieve Image."), 'error');
        });
    }

    const downloadPdf = () => {
        if (pdfContentJSON?.imageContent) {
            const byteCharacters = atob(pdfContentJSON.imageContent);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `TripStop-${selectedTripNum}-${selectedShipToCode}.pdf`
            a.click();
            window.URL.revokeObjectURL(url);
        }
    };

    const handleToolbarClick = (args, gridName) => {
        if (args.item.id === 'trips-table_excelexport') {
            if(gridName === "Trips" && tripTableData.length > 0){
                props.exportToExcelTrips();
            }
        }
    }

    // const handleRotate = (direction) => {
    //     let newRotationAngle;
    //     if (direction === 'right') {
    //       newRotationAngle = (rotationAngle + 90) % 360;
    //     } else {
    //       newRotationAngle = (rotationAngle - 90 + 360) % 360;
    //     }
    //     setRotationAngle(newRotationAngle);
    //     setKey((prevKey) => prevKey + 1);
    //     // setLoadingTripStopImage(true)
    // };

    // const footerTemplate = () => {
    //     return (
    //       <div>
    //         <GrRotateLeft title={t('Rotate Left')} onClick={() => handleRotate('left')} style={{cursor:'pointer'}} size={25} color={theme === 'dark' ? 'white' : 'grey'} />
    //         <GrRotateRight title={t('Rotate Right')} onClick={() => handleRotate('right')} style={{ marginLeft: '1rem' ,cursor:'pointer'}} size={25} color={theme === 'dark' ? 'white' : 'grey'} />
    //         <BiDownload title={t('Download PDF')} onClick={downloadPdf} style={{ marginLeft: '1rem' ,cursor:'pointer'}} size={25} color={theme === 'dark' ? 'white' : 'grey'} />
    //       </div>
    //     );
    //   }

      const gridTemplate = (args) => {
        return (
            <StopDialogue
                args={args}
                modalFrom={props.activeTab}
                handlAuthorization={handlAuthorization}
                customer={props.customer}
            />
        )
      }

    const handleActionBegin = (args) => {
        if (args.requestType === 'beginEdit') {
            if (!condition) {
                args.cancel = true;
            }
        }
        // if (args.requestType === 'beginEdit' && args.columnName === 'tripNumber') {
        //   // Prevent the default edit action
        //   args.cancel = true;
        //   // Open your custom dialog
        //     openTripStopListDialogue(args.rowData, "Trips");
        //     tripsGridRef.current.startEdit(args.rowData);
        // }

        // if (args.requestType === 'recordClick' && args.column.field === 'tripNumber') {
        //     // sortingOrderRef.current = tripsGridRef.current.sortSettings.columns;
        //     const data = openTripStopListDialogue(args.rowData, "Trips");
        //     tripsGridRef.current.startEdit(data);
        //     // args.cancel = true;
        //   }
    };

    const createdTripTable = () => {
        try{
        if(props.activeTab === 'Trips'){
            if (tripsGridRef?.current) {
                tripsGridRef?.current.element.addEventListener('click', (e) => {
                    if (e.target.closest('.e-rowcell')) {
                        const rowInfo = tripsGridRef?.current.getRowInfo(e.target);
                        if (rowInfo.column.field === 'tripNumber' ||(rowInfo.column.field === 'imageFound' &&rowInfo.rowData.imageFound)) {
                            condition = true;
                            if (tripsGridRef?.current.isEdit) tripsGridRef?.current.endEdit();
                            let index = parseInt(e.target.closest('.e-row').getAttribute('data-rowindex'));
                            tripsGridRef?.current.selectRow(index);
                            tripsGridRef?.current.startEdit();
                        } else {
                            condition = false;
                        }
                    }
                });
            }
        }
        else{
            if (backhaulGridRef?.current) {
                backhaulGridRef?.current.element.addEventListener('click', (e) => {
                    if (e.target.closest('.e-rowcell')) {
                        const rowInfo = backhaulGridRef?.current.getRowInfo(e.target);
                        if (rowInfo.column.field === 'tripNumber') {
                            condition = true;
                            if (backhaulGridRef?.current.isEdit) backhaulGridRef?.current.endEdit();
                            let index = parseInt(e.target.closest('.e-row').getAttribute('data-rowindex'));
                            backhaulGridRef?.current.selectRow(index);
                            backhaulGridRef?.current.startEdit();
                        } else {
                            condition = false;
                        }
                    }
                });
            }
        }
        }
        catch(err){
            console.log("err",err);
        }
    };

    function exportToExcel(props) {
        return (
          <div className='my-custom-text' style={{ fontSize: '1rem' }}>
            <div className="flex-center" style={{ padding: '10px' }} onClick={() => { handleToolbarClick({ item: { id: "trips-table_excelexport"} }, "Trips"); }}>
              <span className="custom-export-icon"><BiSolidFileExport title="Export to Excel" /></span>
              <span style={{ marginLeft: '5px' }}>Export Data</span>
            </div>
          </div>
        )
    }

    const handleActionComplete = (args) => {
        // console.log("args ", args);
        // if (args.requestType === 'sorting') {
        //     setSortingOrderTrip(args.model.sortSettings.columns);
        // }

        // if (args.requestType === 'recordClick') {
        //     tripsGridRef.current.startEdit(args.rowData);
        //   }

        // if (args.requestType === 'recordClick') {
        //     const tripNumber = args.rowData.tripNumber;
        //     // Make an API call to get data for the edit dialogue
        //     openTripStopListDialogue(args.rowData, "Trips");
        // }

        if (args.requestType === 'beginEdit' ) { //&& args.columnName === 'tripNumber'
            const dialog = args.dialog;
            dialog.headerContent.lastChild.innerText = `${t("Stops Details")}: ${args.rowData.tripNumber}`;

            dialog.element.style.height = '600px';
            const bodyElement = dialog.element.querySelector('.e-dlg-content');
            if (bodyElement) {
                bodyElement.style.borderRadius = '10px';
            }
            const footerElement = args.dialog.element.querySelector('.e-footer-content');
            if (footerElement) {
              footerElement.style.display = 'none';
            }
            // dialog.showCloseIcon = false; // Hide the default close icon
            // dialog.closeOnEscape = false; // Disable closing the dialog by pressing Esc key
            // dialog.buttons = [closeButton]; // Set the custom close button

            // const dialogElement = args.dialog.element.querySelector('.e-dialog');
            // if (dialogElement) {
            //   dialogElement.style.width = '85%';
            //   dialogElement.style.height = '85%';
            //   dialogElement.id = 'TripStopDialgue';
            // }
        }
        // let isPDFView = true;
        // if ((args.requestType === 'save' || args.requestType === 'cancel') && isPDFView) {
        //     args.cancel = false;
        // }
    };

    const recordDoubleClick = (args) => {
        if(props.activeTab === 'Trips'){
            if (args.column.field === 'tripNumber' || (args.column.field === 'imageFound' && args.rowData['imageFound'])) {
                condition = true;
            } else {
                condition = false;
            }
        }
        else{
            if (args.column.field === 'tripNumber') {
                condition = true;
            } else {
                condition = false;
            }
        }
    }

    // const commands = [{ type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } }];
    // const commands2 = [{ type: 'Edit', buttonOption: { cssClass: 'e-flat', content: <IoOpenOutline color='white' size={25} /> } }];

    return (
        <div>
            <div style={{ padding: '0rem 4rem' }}>
                <ErrorBoundary compName="TRIP props.activeTab === 'Trips'">
                    {props.activeTab === 'Trips' && (
                        <div>
                            {(!loadingTableList) ?
                                <div>
                                    <GridComponent
                                        className='syncf-grid-comp mt-4 my-custom-toolbar-table'
                                        dataSource={tripTableData ? tripTableData : []}
                                        ref={tripsGridRef}
                                        height={tripTableData.length > 0 ? 600 : undefined}
                                        id="trips-table"
                                        dataBound={() => {
                                            if (tripsGridRef?.current) {
                                                // tripsGridRef.current?.refreshHeader();
                                                // setTimeout(()=>{
                                                //     setIsGridDataLoaded(true);
                                                // },200)
                                                // tripsGridRef.current?.autoFitColumns();
                                            }
                                        }}
                                        toolbarClick={(args)=>handleToolbarClick(args,"Trips")}
                                        allowPaging={true}
                                        locale={localeState === "fr" ? 'fr-FR' : 'en-US'}
                                        allowSorting={true}
                                        allowFiltering={(tripTableData && tripTableData.length > 0)}
                                        // allowExcelExport={true}
                                        // toolbar={['ExcelExport']}
                                        toolbar={[{ template: exportToExcel }]}
                                        pageSettings={{ pageSize: 15 }}
                                        textWrapSettings={{ wrapMode: 'Header' }}
                                        // editSettings={{ allowEditing: false, allowDeleting: false }}
                                        editSettings={{ allowEditing: true, allowAdding: false ,allowDeleting: false, mode: "Dialog", template: gridTemplate }} 
                                        actionBegin={handleActionBegin}
                                        actionComplete={handleActionComplete}
                                        // recordClick={handleRowCliCk}
                                        // load={handleLoad}
                                        // sortSettings={{columns: sortingOrderTrip}}
                                        sortSettings={{
                                            columns: [{ field: 'tripNumber', direction: 'Descending' }, { field: 'routeNumber', direction: 'Ascending' }],
                                        }}
                                        filterSettings={{
                                            type: 'Menu'
                                        }}
                                        created={createdTripTable}
                                        recordDoubleClick={recordDoubleClick}
                                    >
                                        <ColumnsDirective>
                                        {/* <ColumnDirective isPrimaryKey={true} commands={commands} headerText="" field="tripID" visible={true} width={50} 
                                            allowSorting={false}  allowFiltering={false} allowSearching={false} clipMode="Clip"
                                        /> */}
                                            <ColumnDirective field="tripID" headerTextAlign='Center' headerText="id" width="50" textAlign="Center" visible={false} />
                                            <ColumnDirective
                                                field="imageFound"
                                                headerText= {t("Doc")}
                                                headerTextAlign="Center"
                                                disableHtmlEncode={false}
                                                width="50"
                                                textAlign="Center"
                                                allowFiltering={false}
                                                template={(args) => {
                                                    return (
                                                        <div>
                                                            {args.imageFound ?
                                                                <div 
                                                                // onClick={() => { openTripStopListDialogue(args, "Trips") }} 
                                                                className='trip-num-col trip-stop-table'
                                                                >
                                                                    <TiAttachment 
                                                                    // style={{cursor:'pointer', color: (theme === "light" ? 'black' : 'white')}} 
                                                                    className='action-btn-table' />
                                                                </div>
                                                                :
                                                                <div></div>}
                                                        </div>
                                                    );
                                                }}
                                            />
                                            <ColumnDirective
                                                field="tripNumber"
                                                headerText= {t("Trip Number")}
                                                headerTextAlign='Center'
                                                disableHtmlEncode={false}
                                                width="80"
                                                textAlign="Center"
                                                isPrimaryKey={true}
                                                // commands={commands}
                                                headerTemplate={(args) => (<div className='whtspc-preln'>{args.headerText}</div>)}
                                                template={(data) => {
                                                    return (<div><div 
                                                        className='trip-table-col trip-num-col'
                                                            // onClick={() => { openTripStopListDialogue(data, "Trips") }}
                                                        >{data?.tripNumber}</div></div>
                                                        );
                                                }}
                                            />
                                            <ColumnDirective
                                                disableHtmlEncode={false}
                                                field="stopCount"
                                                headerText= {t("Stops")}
                                                headerTextAlign='Center'
                                                width="60"
                                                headerTemplate={(args) => (<div className='whtspc-preln'>{args.headerText}</div>)}
                                                textAlign="Center"
                                            />
                                            <ColumnDirective
                                                field="trailerNumber"
                                                disableHtmlEncode={false}
                                                headerText= {t("Trailer")}
                                                headerTextAlign='Center'
                                                width="80"
                                                headerTemplate={(args) => (<div className='whtspc-preln'>{args.headerText}</div>)}
                                                template={(data) => {
                                                    return (
                                                        <div>
                                                            {data.trailerNumber ? data.trailerNumber : ""}
                                                        </div>
                                                    );
                                                }}
                                                textAlign="Center"
                                            />
                                            <ColumnDirective
                                                field="routeNumber"
                                                disableHtmlEncode={false}
                                                headerText={t("Route/Ref")}
                                                headerTextAlign='Center'
                                                // headerTemplate={(args) => (<div className='whtspc-preln'>{args.headerText}</div>)}
                                                width="100"
                                                textAlign="Center"
                                            />
                                            <ColumnDirective
                                                field="origin_en"
                                                disableHtmlEncode={false}
                                                allowFiltering={false}
                                                allowSorting={false}
                                                headerText= {t("Origin")}
                                                headerTextAlign='Center'
                                                headerTemplate={(args) => (<div className='whtspc-preln'>{args.headerText}</div>)}
                                                template={(data) => {
                                                    const PickupDate = data.schOriginDateTZ ?  moment(data.schOriginDateTZ).format('YYYY-MM-DD') :"";
                                                    const FirstPickName = data.firstPickName;
                                                    const FirstPickCityState = data.firstPickCity + ", " + data.firstPickupState;
                                                    return (
                                                        <div>
                                                            <div>{PickupDate}</div>
                                                            <div style={{ whiteSpace: "pre-line" }}>{`${FirstPickName}  ${FirstPickCityState}`}</div>
                                                        </div>
                                                    );
                                                }}
                                                width="100"
                                                textAlign="Left"
                                            />
                                            <ColumnDirective
                                                field="destination_en"
                                                disableHtmlEncode={false}
                                                headerText= {t("Destination")}
                                                allowFiltering={false}
                                                allowSorting={false}
                                                headerTextAlign='Center'
                                                headerTemplate={(args) => (<div className='whtspc-preln'>{args.headerText}</div>)}
                                                template={(data) => {
                                                    const DelDate =data.lastDelSchDate ?  moment(data.lastDelSchDate).format('YYYY-MM-DD') :""
                                                    const LastDropName = data.lastDropName;
                                                    const LastDropCityState = data.lastDropCity + ", " + data.lastDropState;
                                                    return (
                                                        <div>
                                                            <div>{DelDate}</div>
                                                            <div style={{ whiteSpace: "pre-line" }}>{`${LastDropName}  ${LastDropCityState}`}</div>
                                                        </div>
                                                    );
                                                }}
                                                width="100"
                                                textAlign="Left"
                                            />
                                            <ColumnDirective
                                                field="carrierName"
                                                disableHtmlEncode={false}
                                                headerText= {t("Carrier")}
                                                headerTextAlign='Center'
                                                headerTemplate={(args) => (<div className='whtspc-preln'>{args.headerText}</div>)}
                                                template={({ carrierName }) => {
                                                    return (
                                                        <div style={{ whiteSpace: "pre-line" }}>
                                                            {carrierName}
                                                        </div>
                                                    )
                                                }}
                                                width="100"
                                                textAlign="Left"
                                            />
                                            <ColumnDirective
                                                field={localeState === "fr" ? "tripStatus_fr" : "tripStatus"}
                                                disableHtmlEncode={false}
                                                headerTemplate={(args) => (<div className='whtspc-preln'>{args.headerText}</div>)}
                                                headerText= {t("Status")}
                                                headerTextAlign='Center'
                                                width="100"
                                                textAlign="Center"
                                            />
                                        </ColumnsDirective>
                                        <Inject services={[Page, CommandColumn, Sort, Filter, Edit, Toolbar]} />
                                    </GridComponent> </div> : (
                                    <div className='my-custom-loader-container'><Loading /></div>
                                )}
                        </div>
                    )}
                </ErrorBoundary>

                <ErrorBoundary compName="TRIP props.activeTab === 'Backhaul'">
                    {props.activeTab !== 'Trips' && (
                        <div>
                            {(!loadingTableList) ? (
                                <div>
                                    <GridComponent
                                        className='syncf-grid-comp mt-4'
                                        dataSource={backhaulTableData ? backhaulTableData : []}
                                        ref={backhaulGridRef}
                                        height={backhaulTableData.length > 0 ? 600 : undefined}
                                        id="backhauls-table"
                                        // dataBound={() => {
                                        //     if (backhaulGridRef?.current) {
                                        //    //     backhaulGridRef.current?.refreshHeader();
                                        //         // backhaulGridRef.current?.autoFitColumns();
                                        //     }
                                        // }}
                                        allowPaging={true}
                                        allowSorting={true}
                                        allowFiltering={(backhaulTableData && backhaulTableData.length > 0)}
                                        locale={localeState === "fr" ? 'fr-FR' : 'en-US'}
                                        pageSettings={{ pageSize: 15 }}
                                        textWrapSettings={{ wrapMode: 'Header' }}
                                        // editSettings={{ allowEditing: false, allowDeleting: false }}
                                        sortSettings={{
                                            columns: [{ field: 'custRef', direction: 'Descending' }, { field: 'originDate_en', direction: 'Ascending' }],
                                        }}
                                        filterSettings={{
                                            type: 'Menu'
                                        }}
                                        resizeSettings={{
                                            allowResizing: true
                                        }}
                                        editSettings={{ allowEditing: true, allowAdding: false ,allowDeleting: false, mode: "Dialog", template: gridTemplate }} 
                                        actionBegin={handleActionBegin}
                                        actionComplete={handleActionComplete}
                                        created={createdTripTable}
                                        recordDoubleClick={recordDoubleClick}
                                    >
                                        <ColumnsDirective>
                                        <ColumnDirective isPrimaryKey={true} 
                                        // commands={commands} 
                                        headerText="" field="tmsOrder" visible={false} width={50} 
                                            // allowSorting={false}  allowFiltering={false} allowSearching={false} 
                                            clipMode="Clip"
                                        />
                                            <ColumnDirective
                                                disableHtmlEncode={false}
                                                field="custRef"
                                                headerText={t("Reference")}
                                                headerTextAlign='Center'
                                                width="80"
                                                textAlign="Center"
                                            />
                                            <ColumnDirective
                                                field="tripNumber"
                                                headerText= {t("Trip Number")}
                                                headerTemplate={(args) => (<div className='whtspc-preln'>{args.headerText}</div>)}
                                                headerTextAlign='Center'
                                                disableHtmlEncode={false}
                                                width="80"
                                                textAlign="Center"
                                                template={(data) => {
                                                    return (
                                                        <div 
                                                        className='trip-num-col backhaul-table' 
                                                        // onClick={() => openTripStopListDialogue(data, "Backhaul")}
                                                        >
                                                            {data.tripNumber}
                                                        </div>
                                                    );
                                                }}
                                            />
                                            <ColumnDirective
                                                disableHtmlEncode={false}
                                                field="trailerNumber"
                                                headerText= {t("Trailer")}
                                                headerTextAlign='Center'
                                                width="70"
                                                textAlign="Center"
                                            />
                                            <ColumnDirective field="origin_en" headerText= {t("Origin")} width="120"
                                                template={(data) => {
                                                    return (
                                                        <div style={{ whiteSpace: "pre-line" }}>
                                                            {data.originName}<br />{data.originAddress}
                                                        </div>
                                                    )
                                                }}
                                            />
                                            <ColumnDirective field="originDate_en" headerText= {t("Origin Date")} width="120"
                                                template={(data) => {
                                                    const originScheduled = formatDate(data.SchArrivalBeginDate ?? data.oSchDate, 'MM/DD/YYYY HH:mm');
                                                    const originActualDate = formatDate(data.oActualArrival, 'MM/DD/YYYY HH:mm');
                                                    return (
                                                        <div>
                                                            <p>{"S: "+originScheduled}</p>
                                                            <p>{"A: "+originActualDate}</p>
                                                            <p>{"C: "+originActualDate}</p>
                                                        </div>
                                                    );
                                                }} />
                                            <ColumnDirective field="destinationDate_en" headerText= {t("Dest Date")} width="120"
                                                template={(data) => {
                                                    return (
                                                        <div>
                                                            <p>{"S: "+formatDate(data.dSchDate, 'MM/DD/YYYY HH:mm')}</p>
                                                            <p>{"A: "+formatDate(data.dActualArrival, 'MM/DD/YYYY HH:mm')}</p>
                                                            <p>{"C: "+formatDate(data.dActualComplete, 'MM/DD/YYYY HH:mm')}</p>
                                                        </div>
                                                    );
                                                }} />
                                            <ColumnDirective 
                                                field={localeState === "fr" ? "orderStatus_fr" : "orderStatus"}
                                                headerText= {t("Status")} width="70" 
                                                disableHtmlEncode={false}
                                                textAlign="Center" 
                                            />
                                        </ColumnsDirective>
                                        <Inject services={[Page, CommandColumn, Sort, Filter, ExcelExport, Edit]} />
                                    </GridComponent>
                                </div>
                            ) : (
                                <div className='my-custom-loader-container'><Loading /></div>
                            )}
                        </div>
                    )}
                </ErrorBoundary>
            </div>
             {/* <ErrorBoundary compName="TRIP STOP: GridComponent">
                <DialogComponent
                    header={ t("Stops Details") +": " + selectedTripNum}
                    isModal width="85%" height="85%" style={{ maxWidth: '1200px' }}
                    showCloseIcon={true} visible={stopDialogueStatus} open={dialogOpen} close={dialogClose}
                >
                    <span style={{ height: '100%' }}>
                        {loadingTripStop && <div className='my-custom-loader-container'><Loading /></div>}
                        <div>
                            {!loadingTripStop &&
                                <div style={{ marginBottom: '2rem' }}>
                                    <div>
                                        {tripStopList && (
                                            <GridComponent
                                                ref={tripStopGridRef}
                                                dataBound={() => {
                                                    if (tripStopGridRef && tripStopGridRef.current) {
                                                        if (tripStopGridRef.current.dataSource.length > 0) {
                                                            tripStopGridRef.current.selectRows([0]);
                                                            setTimeout(() => {
                                                                setIsGridDataLoaded(true);
                                                            }, 100);
                                                        }
                                                            tripStopGridRef.current?.refreshHeader();
                                                        //     tripStopGridRef.current?.autoFitColumns();
                                                    }
                                                }}
                                                id="tripback-stop-table"
                                                locale={localeState === "fr" ? 'fr-FR' : 'en-US'}
                                                className='syncf-grid-comp mb-2'
                                                dataSource={tripStopList ? tripStopList : []}
                                                allowPaging={true}
                                                allowSorting={true}
                                                allowFiltering={(tripStopList && tripStopList.length > 0)}
                                                //allowExcelExport={true}
                                                //toolbar={(isGridDataLoaded ? ['ExcelExport'] : [])}
                                                // toolbar={[{ template: exportButtonTemplate }]}
                                                recordClick={(args)=>{
                                                    if (args.column.field === 'imageFound') {
                                                        getPDFData(args.rowData)
                                                    }
                                                }}
                                                pageSettings={{ pageSize: 7 }}
                                                textWrapSettings={{ wrapMode: 'Header' }}
                                                editSettings={{ allowEditing: false, allowDeleting: false }}
                                                sortSettings={{
                                                    columns: [{ field: 'seqNumber', direction: 'Ascending' }],
                                                }}
                                                filterSettings={{
                                                    type: 'Menu'
                                                }}
                                            >
                                                <ColumnsDirective>
                                                    <ColumnDirective field="tripShipTo" headerText="id" visible={false} />
                                                    <ColumnDirective
                                                        field="imageFound"
                                                        headerText= {t("Doc")}
                                                        headerTextAlign="Center"
                                                        disableHtmlEncode={false}
                                                        headerTemplate={(args) => (<div style={{ paddingLeft: '11px' }} className='whtspc-preln'>{" "}{args.headerText}</div>)}
                                                        width="60"
                                                        textAlign="Center"
                                                        template={(args) => {
                                                            return (
                                                                <div>
                                                                    {args.imageFound ?
                                                                        <div 
                                                                        // onClick={() => {getPDFData(args)}} 
                                                                        className='trip-num-col trip-stop-table'>
                                                                            <TiAttachment className='action-btn-table' />
                                                                        </div>
                                                                        :
                                                                        <div></div>}
                                                                </div>

                                                            );
                                                        }}
                                                    />
                                                    <ColumnDirective
                                                        field="osrd_en"
                                                        headerText= {t("OS and D")}
                                                        headerTemplate={(args) => (<div className='whtspc-preln'>{args.headerText}</div>)}
                                                        headerTextAlign='Center'
                                                        disableHtmlEncode={false}
                                                        allowFiltering={false}
                                                        allowSorting={false}
                                                        template={(data) => (
                                                            <div style={{ whiteSpace: "pre-line" }}>
                                                                {data.osrd_en}
                                                            </div>
                                                        )}
                                                        width="80"
                                                        textAlign="Left"
                                                    />
                                                    <ColumnDirective
                                                        disableHtmlEncode={false}
                                                        field="seq"
                                                        headerText= {t("Stop #")}
                                                        headerTemplate={(args) => (<div className='whtspc-preln'>{args.headerText}</div>)}
                                                        headerTextAlign='Center'
                                                        width="80"
                                                        textAlign="Left"
                                                    />
                                                    <ColumnDirective
                                                        field="externalSequence"
                                                        disableHtmlEncode={false}
                                                        headerText= {t("Seq")}
                                                        headerTextAlign='Center'
                                                        width="80"
                                                        textAlign="Center"
                                                    />
                                                    <ColumnDirective
                                                        field="shipToCode"
                                                        headerText= {t("Ship To")}
                                                        headerTemplate={(args) => (<div className='whtspc-preln'>{args.headerText}</div>)}
                                                        disableHtmlEncode={false}
                                                        headerTextAlign='Center'
                                                        width="80"
                                                        textAlign="Center"
                                                    />
                                                    <ColumnDirective
                                                        headerText= {t("Stop")}
                                                        field="stopName_en"
                                                        allowFiltering={false}
                                                        allowSorting={false}
                                                        headerTextAlign='Center'
                                                        template={(data) => {
                                                            const fullName = `${data.stopName}`;
                                                            const miles = t("Miles") +`: ${data.distance}`;
                                                            const location = `${data.city}, ${data.province}` ;
                                                            return (
                                                                <div style={{ whiteSpace: "pre-line" }}>
                                                                    <div>{fullName}</div>
                                                                    <div>{location}</div>
                                                                    <div>{miles}</div>
                                                                </div>
                                                            );
                                                        }}
                                                        width="140"
                                                        disableHtmlEncode={false}
                                                        textAlign="Left"
                                                    />
                                                    <ColumnDirective
                                                        headerText= {t("Origin Dates")}
                                                        headerTemplate={(args) => (<div className='whtspc-preln'>{args.headerText}</div>)}
                                                        field="origin_dates_en"
                                                        headerTextAlign='Center'
                                                        template={(data) => {
                                                            return (
                                                                <div className="whtspc-preln">
                                                                    <div>{`S: ${formatDate(data.schOriginDateTZ, 'MM-DD-YYYY HH:mm')}`}</div>
                                                                    <div>{`A: ${formatDate(data.actualOriginDateTZ, 'MM-DD-YYYY HH:mm')}`}</div>
                                                                </div>
                                                            );
                                                        }}
                                                        width="140"
                                                        disableHtmlEncode={false}
                                                        textAlign="Left"
                                                        // filter={{ type: 'date' }}
                                                    />
                                                    <ColumnDirective
                                                        headerText= {t("Sch Date")}
                                                        headerTemplate={(args) => (<div className='whtspc-preln'>{args.headerText}</div>)}
                                                        field="schDate_en"
                                                        template={(data) => {
                                                            return (
                                                                <div className="whtspc-preln">
                                                                    <div>{formatDate(data.schArrivalBeginDate, 'MM-DD-YYYY HH:mm')}</div>
                                                                    <div style={{ color: 'red' }}>{data?.eta}</div>
                                                                </div>
                                                            );
                                                        }}
                                                        disableHtmlEncode={false}
                                                        headerTextAlign='Center'
                                                        width="120"
                                                        textAlign="Left"
                                                    />
                                                    <ColumnDirective
                                                        headerText= {t("Actual Date")}
                                                        headerTemplate={(args) => (<div className='whtspc-preln'>{args.headerText}</div>)}
                                                        field="actualDate_en"
                                                        allowFiltering={false}
                                                        allowSorting={false}
                                                        headerTextAlign='Center'
                                                        template={(data) => {
                                                            return (
                                                                <div>
                                                                    <div>{`A: ${formatDate(data.actualArrivalTZ, 'MM-DD-YYYY HH:mm')}`}</div>
                                                                    <div>{`C: ${formatDate(data.actualCompleteTZ, 'MM-DD-YYYY HH:mm')}`}</div>
                                                                </div>
                                                            );
                                                        }}
                                                        width="140"
                                                        disableHtmlEncode={false}
                                                        textAlign="Left"
                                                    />
                                                    <ColumnDirective
                                                        field={localeState === "fr" ? "status_fr" : "status"}
                                                        headerText= {t("Status")}
                                                        disableHtmlEncode={false}
                                                        headerTextAlign='Center'
                                                        width="90"
                                                        textAlign="Left"
                                                    />
                                                </ColumnsDirective>
                                                <Inject services={[Page, CommandColumn, Sort, Filter, Toolbar]} />
                                            </GridComponent>
                                        )}
                                    </div>
                                </div>}
                        </div>
                    </span>
                </DialogComponent>
            </ErrorBoundary> */}

            {/* <ErrorBoundary compName="TRIP PDF Viewer">
                <DialogComponent
                    header={t("Stop #")+" " + selectedStop +" "+t("Images")} isModal width="40%" height="85%" style={{ minWidth: '300px' }} showCloseIcon={true}
                    visible={isDialogOpenPDF}
                    open={() => setIsDialogOpenPDF(true)} close={closeTripStopImageModal}
                    footerTemplate={footerTemplate}
                >
                    <div style={{ height: '100%', width: '100%' }}>
                        {pdfContentJSON && pdfContentJSON?.imageContent && <PDFViewer
                            pdfContent={pdfContentJSON?.imageContent}
                            loadingTripStopImage={loadingTripStopImage}
                            setLoadingTripStopImage={setLoadingTripStopImage}
                            rotationAngle={rotationAngle}
                            pdfKey={pdfKey}
                        />}
                        {loadingTripStopImage && (<div className='my-custom-loader-container'>
                            <Loading />
                        </div>)}
                    </div>
                </DialogComponent>
            </ErrorBoundary> */}
        </div>
    );
};

export default TripBackhaulStopTable;
