// import { ScheduleComponent, Day, Week, WorkWeek, Month, Agenda, Inject } from '@syncfusion/ej2-react-schedule';
import React, { useEffect, useState, useRef, useContext, useCallback } from 'react';
import { DatePickerComponent, Inject, MaskedDateTime } from '@syncfusion/ej2-react-calendars';
import { ColumnDirective, ColumnsDirective, CommandColumn, GridComponent, Page, Sort, Filter, ExcelExport } from '@syncfusion/ej2-react-grids';
import { TextBoxComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import "./Reports.css";
import agent from '../../api/agent';
import moment from 'moment';
import TokenProvider, { logOutUser } from '../TokenProvider/TokenProvider';
import { Card, Container, Row, Col } from 'react-bootstrap';
import { Tab, Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import AppContext from '../Theme/AppContext';
import { showToast } from '../Theme/Utils';
import { useNavigate } from 'react-router-dom';
import Loading from '../Loading/Loading';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { Animation } from '@syncfusion/ej2-base';

const getInitialDateRange = () => {
    const today = new Date();
    const startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 30);
    const endDate = today;
    return [startDate, endDate];
}

const ReportComp = () => {
    const { t, i18n } = useTranslation();
    const { theme, userInfo, localeState } = useContext(AppContext);

    const { token, setToken } = TokenProvider();
    const cbToken = token.token;
    const username = token.username;
    const ReportGridRef = useRef()
    const dateRangeRef = useRef()

    const [loadingTableList, setLoadingTableList] = useState(false);
    const [reportList, setReportList] = useState([]);
    const [selectedDateRange, setSelectedDateRange] = useState(getInitialDateRange());
    const navigate = useNavigate();
    const moduleName = "RateCall Reports"

    const [customerDDList, setCustomerDDList] = useState([]);
    const [loadingCustomerList, setLoadingCustomerList] = useState(true);
    const [customerDDText, setCustomerDDText] = useState(null);
    const [customerDDValue, setCustomerDDValue] = useState(null);
    const customerDDRef = useRef(null);
    const [placeKey, setPlaceKey] = useState(0);

    useEffect(() => {
        getCustomerList().then(data => {
            if(data){
                const [startDate, endDate] = getInitialDateRange()
                getList(startDate, endDate, data);
            }
        })
    }, []);

    useEffect(()=>{
        if(i18n.language){
            setPlaceKey(prev=>prev+1);
        }
    },[i18n.language])

    const loaderRef = useRef(null);
    const contentRef = useRef(null);
  
    useEffect(() => {
      if (loadingCustomerList) {
        let animation = new Animation();
        animation.animate(loaderRef.current, { name: 'FadeOut', duration: 800 });
      } else {
        let animation = new Animation();
        animation.animate(contentRef.current, { name: 'FadeIn', duration: 800 });
      }
    }, [loadingCustomerList]);

    const getCustomerList = async () => {
        try {
            setLoadingCustomerList(true);
            let res = await agent.getCustomerListRateCall();
            if (!(res.data.length > 0)) {
                navigate('/access-denied', { state: { moduleName } });
                return;
            }
            let list = res.data.map(item => {
                const text = item.split('-')[0];
                return { text, value: item };
            });
            list = list.sort((a, b) => a.text.localeCompare(b.text));
            setCustomerDDList(list);
            setCustomerDDText(list[0].text)
            setCustomerDDValue(list[0].value)
            setLoadingCustomerList(false);
            return list[0].value;
        }
        catch (err) {
            // setLoadingCustomerList(false);
            handlAuthorization(err);
        }
    }

    const handlChangeCustomerDD = (e) => {
        let val = e.target.value;
        setCustomerDDText(val)
        setCustomerDDValue(customerDDList.find(item => item.text === val).value)
    }

    const handlAuthorization = (error) => {
        console.log(error);
        if (error.code === "ERR_NETWORK") {
            showToast(t("No response from server."), 'error');
        }
        else if (error?.response?.status === 401) {
            showToast(t('Your session has expired. Please login again.'), 'error');
            logOutUser();
        }
        else if (error?.response?.status === 403) {
            //showToast(t("You do not have access to ")+ moduleName+ t(", please contact your account manager to gain access."), 'error');
            // navigate('/access-denied', { state: { moduleName } });
        }
    }

    const getList = (startDate, endDate, customer) => {
        const convertData = (dataA) => {
            return dataA.map((data) => ({
              ...data,
              errorMessage_fr: data.errorMessage ? t(data.errorMessage, { lng: 'fr' }) : "",
              origin_en: (data.originCity +(data.originState ? ", "+data.originState : "")+ " "+data.originPostal),
              destination_en: (data.destinationCity +(data.destinationState ? ", "+data.destinationState : "")+ " "+data.destinationPostal),
            }));
        };
        setLoadingTableList(true);
        let params = {
            startDate: moment(startDate).format('YYYY-MM-DD'),
            endDate: moment(endDate).format('YYYY-MM-DD'),
            customer,
        }
        agent.getAllHillmanRateCallRequestsList(params).then((response) => {
            for (let i = 0; i < response.data.length; i++) {
                response.data[i].requestDateTime = response.data[i].requestDateTime ? new Date(response.data[i].requestDateTime) : null;
                response.data[i].loadDate = response.data[i].loadDate ? new Date(response.data[i].loadDate) : null;
            }
            setReportList(convertData(response.data));
            setLoadingTableList(false);
        }).catch((error) => {
            handlAuthorization(error);
            setLoadingTableList(false);
        });
    }

    const handleSubmitForm = () => {
        getList(selectedDateRange[0], selectedDateRange[1], customerDDValue);
    }

    return (
        <div>
      {loadingCustomerList ? (
        <div ref={loaderRef} style={{ height: "100vh" }} className="my-custom-loader-container">
          <Loading />
        </div>
      ) : (
        <div ref={contentRef} className="container-flex" style={{ padding: "1.5rem 0rem 4rem 1.5rem" }}>
            <div className='chart-container mt-4' style={{ padding: '0rem 4rem' }}>
                <div>
                    <h1 className="mt-4 my-custom-text main-header-text flex-center">{t('Rate Call Reports')}</h1>
                    <div className='mt-2' id="dashboard-form">
                        <Container className='flex-center' style={{ maxWidth: "100%" }}>
                            <div className='flex-center my-custom-text' style={{ maxWidth: "100%", alignItems: 'end', flexWrap: 'wrap', gap: '1rem' }}>
                                {customerDDList && customerDDList.length > 1 &&
                                    <div>
                                        <span className="form-field-label">
                                            {t('Customer Name')}
                                        </span>
                                        <div className="">
                                            <div className='daterangepicker-control-section'>
                                                <DropDownListComponent
                                                    required={true}
                                                    onChange={handlChangeCustomerDD}
                                                    ref={customerDDRef}
                                                    value={customerDDText}
                                                    placeholder={t('Customer Name')}
                                                    floatLabelType="Never"
                                                    cssClass="e-medium"
                                                    className='e-field'
                                                    dataSource={customerDDList}
                                                    id="dropdown"
                                                    data-msg-containerid="errorForDropDown"
                                                />
                                            </div>
                                        </div>
                                    </div>}
                                <div>
                                    <span className="form-field-label">
                                        {t('Date Range')}
                                    </span>
                                    <div className="" >
                                        <div className='daterangepicker-control-section'>
                                            <DateRangePickerComponent
                                                width={200}
                                                className="form-control form-control-sm"
                                                placeholder={t('Select Date Range')}
                                                name='date_range'
                                                ref={dateRangeRef}
                                                key={"date_range"+placeKey}
                                                presets={[
                                                    { label: t('This Month'), start: new Date(new Date().getFullYear(), new Date().getMonth(), 1), end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0) },
                                                    { label: t('This Week'), start: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - new Date().getDay()), end: new Date() },
                                                    { label: t('This Quarter'), start: new Date(new Date().getFullYear(), Math.floor(new Date().getMonth() / 3) * 3, 1), end: new Date(new Date().getFullYear(), Math.floor(new Date().getMonth() / 3) * 3 + 3, 0) },
                                                    { label: t('This Year'), start: new Date(new Date().getFullYear(), 0, 1), end: new Date(new Date().getFullYear(), 11, 31) },
                                                    { label: t('Last Month'), start: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1), end: new Date(new Date().getFullYear(), new Date().getMonth(), 0) },
                                                    { label: t('Last Week'), start: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - new Date().getDay() - 7), end: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - new Date().getDay() - 1) },
                                                    { label: t('Last Quarter'), start: new Date(new Date().getFullYear(), Math.floor(new Date().getMonth() / 3) * 3 - 3, 1), end: new Date(new Date().getFullYear(), Math.floor(new Date().getMonth() / 3) * 3, 0) },
                                                    { label: t('Last Year'), start: new Date(new Date().getFullYear() - 1, 0, 1), end: new Date(new Date().getFullYear() - 1, 11, 31) },
                                                ]}
                                                value={selectedDateRange}
                                                onChange={e => {
                                                    setSelectedDateRange(e.value)
                                                }}
                                                open={()=>{
                                                    if(dateRangeRef && dateRangeRef.current){
                                                        dateRangeRef.current.popupWrapper.querySelector('.e-list-parent').lastChild.click();
                                                    }
                                                }}
                                                showClearButton={false}
                                                allowEdit={false}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <button disabled={loadingTableList} onClick={handleSubmitForm} className="my-custom-btn" style={{ marginTop: ".4rem" }} type="submit" >{t('Search')}</button>
                            </div>
                        </Container>
                    </div>
                    <div className='mt-4'>
                        {(!loadingTableList) ? (
                            <GridComponent
                                ref={ReportGridRef}
                                id="reports-ratecall-table"
                                // style={{ minHeight: '250px' }}
                                // className='syncf-grid-comp my-custom-toolbar-table'
                                className='syncf-grid-comp'
                                locale={localeState === "fr" ? 'fr-FR' : 'en-US'}
                                dataSource={reportList}
                                allowPaging={true}
                                // allowSorting={true}
                                allowFiltering={(reportList && reportList.length>0)}
                                pageSettings={{ pageSize: 15 }}
                                textWrapSettings={{ wrapMode: 'Header' }}
                                editSettings={{ allowEditing: false, allowDeleting: false }}
                                sortSettings={{
                                    columns: [{ field: 'netAmount', direction: 'Descending' }],
                                }}
                                filterSettings={{
                                    type: 'Menu'
                                }}
                                dataBound={() => {
                                    if (ReportGridRef?.current) {
                                        ReportGridRef.current?.refreshHeader();
                                        // ReportGridRef.current?.autoFitColumns();
                                    }
                                }}
                                resizeSettings={{
                                    allowResizing: true
                                }}
                            >
                                <ColumnsDirective>
                                    <ColumnDirective field="id" headerTextAlign='Center' headerText="id" width="0" textAlign="Center" visible={false} />
                                    <ColumnDirective
                                        field="requestDateTime"
                                        headerText={t("Request Dt")}
                                        headerTemplate={(props) => (
                                            <div style={{ textAlign: "center" }}>
                                                <div>{t("Request")}</div>
                                                <div>{t("Dt")}</div>
                                            </div>
                                        )}
                                        // template={(data) => {
                                        //     return (<div className="whtspc-preln text-center">
                                        //         {moment(data.requestDateTime).format('MM/DD/YYYY hh:mm:ss A')}
                                        //     </div>)
                                        // }}
                                        headerTextAlign='Center'
                                        disableHtmlEncode={false}
                                        width="100"
                                        textAlign="Left"
                                        format = {'MM/dd/yyyy hh:mm:ss a'}
                                    />
                                    <ColumnDirective
                                        disableHtmlEncode={false}
                                        field="origin_en"
                                        type="string"
                                        headerText={t("Origin")}
                                        headerTextAlign='Center'
                                        template={data => {
                                            return (<div>
                                                {/* {console.log("origin_en ", data.origin_en)} */}
                                                {/* <div style={{ whiteSpace: "pre-line" }}>{data.originCity +(data.originState ? ", "+data.originState : "")+ " "+data.originPostal}</div> */}
                                                <div style={{ whiteSpace: "pre-line" }}>{data.origin_en}</div>
                                            </div>)
                                        }}
                                        width="100"
                                        textAlign="Left"
                                    />
                                    <ColumnDirective
                                        field="destination_en"
                                        disableHtmlEncode={false}
                                        headerText={t("Destination")}
                                        headerTextAlign='Center'
                                        template={data => {
                                            return (<div>
                                                <div style={{ whiteSpace: "pre-line" }}>{data.destination_en}</div>
                                                {/* <div style={{ whiteSpace: "pre-line" }}>{data.destinationCity +(data.destinationState ? ", "+data.destinationState : "")+ " "+data.destinationPostal}</div> */}
                                            </div>)
                                        }}
                                        width="120"
                                        textAlign="Left"
                                    />
                                    <ColumnDirective
                                        field="requestedWeightInLbs"
                                        headerText={t("Requested Weight(Lbs)")}
                                        headerTextAlign='Center'
                                        headerTemplate={(props) => (
                                            <div className="whtspc-preln text-center">
                                                <div>{t("Requested")}</div>
                                                <div>{t("Weight")}</div>
                                                <div>({t("Lbs")})</div>
                                            </div>
                                        )}
                                        width="90"
                                        textAlign="Center"
                                    />
                                    <ColumnDirective
                                        field="loadDate"
                                        headerText={t("Loaded Date")}
                                        headerTemplate={(props) => (
                                            <div className="whtspc-preln text-center">
                                                <div>{t("Loaded")}</div>
                                                <div>{t("Date")}</div>
                                            </div>
                                        )}
                                        headerTextAlign='Center'
                                        width="70"
                                        textAlign="Center"
                                        format ={"MM/dd/yyyy"}
                                    />
                                    <ColumnDirective
                                        field={localeState === "fr" ? "errorMessage_fr" : "errorMessage"}
                                        headerText={t("Error Message")}
                                        headerTextAlign='Center'
                                        headerTemplate={(props) => (
                                            <div style={{ textAlign: "center" }}>
                                                <div>{t("Error")}</div>
                                                <div>{t("Message")}</div>
                                            </div>
                                        )}
                                        template={data => {
                                            return (<div>
                                                <div style={{ whiteSpace: "pre-line" }}>
                                                    {localeState === "fr" ? data.errorMessage_fr : data.errorMessage}
                                                </div>
                                            </div>)
                                        }}
                                        width="170"
                                        textAlign="Left"
                                    />
                                </ColumnsDirective>
                                <Inject services={[Page, CommandColumn, Sort, Filter, ExcelExport]} />
                            </GridComponent>
                        ) : (
                            <div className='my-custom-loader-container'><Loading /></div>
                        )}
                    </div>
                </div>
            </div>
        </div>
           )}
        </div>
    );
};

export default ReportComp;
