import './App.css';
import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import Footer from './components/Footer';
import Login from './components/Login/Login';
import AppContext from './components/Theme/AppContext';
import TokenProvider from './components/TokenProvider/TokenProvider';
import { getLocale, getTheme, setCurrentTheme } from './components/Theme/ThemeUtils';
import Home from './components/Home/Home';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import TripPage from './components/TrackAndTrace/TripsPage';
import BackhaulPage from './components/TrackAndTrace/BackhaulPage';
import CapacityManagement from './components/CapacityManagement/CapacityManagement';
import RateCall from './components/RateCall/RateCall';
import ReportComp from './components/RateCall/Reports';
import RateCallDefaults from './components/RateCall/RateCallDefaults';
import jwtDecode from 'jwt-decode';
import { showToast } from './components/Theme/Utils';
import { getJWTToken, getUserInfo, isValidRefreshToken, isValidToken, setJWTToken, setUserInfo } from './components/Auth/ProtectedRoute';
import { useTranslation } from 'react-i18next';
import NoPermissionPage from './components/Auth/NoPermissionPage';
import agent from './api/agent';
import Sidebar from './components/Sidebar/Sidebar';
import DashBoard from './components/DashBoard/DashBoard';
import moment from 'moment';
import Documents from './components/Documents/Documents';

function App() {
  const { token, setToken } = TokenProvider();
  const { t, i18n } = useTranslation();

  const lightThemeUrl = './bootstrap5.css';
  const darkThemeUrl = './bootstrap5-dark.css';
  const [theme, setTheme] = useState(getTheme());
  const [localeState, setLocaleState] = useState(getLocale());
  const [userInfo, setUserInfoState] = useState(getUserInfo());
  const [isLoadingAuth, setIsLoadingAuth] = useState(false);
  const [metabaseCurrentToken, setMetabaseCurrentToken] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      const jwtToken = getJWTToken();
      if (jwtToken) {
        const decodedToken = jwtDecode(jwtToken);
        const expTime = decodedToken.exp * 1000;
        const currentTime = new Date().getTime();
        const timeRemaining = expTime - currentTime;

        if (timeRemaining <= 600000) {
          handleRefreshToken();
        }

        if (decodedToken && decodedToken.absoluteExpiryUTC) {
          const currentTimeUTC = moment.utc(); // Get current date time in UTC using Moment.js
          const absoluteExpiryUTC = moment.utc(decodedToken.absoluteExpiryUTC, 'M/D/YYYY h:mm:ss A'); // Parse absoluteExpiryUTC using Moment.js
          const timeRemaining2 = absoluteExpiryUTC.diff(currentTimeUTC); // Calculate difference in milliseconds
          // console.log("timeRemaining2 ", timeRemaining2);
          if (timeRemaining2 <= 1800000) {
            // 30 mins : 1800000,  8 hours: 28800000
            showToast(t('Your token is about to expire in 30 minutes.'));
          }
        }
      } else {
        clearInterval(interval);
      }
    }, 10000); //60000   /// 30000
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    setCurrentTheme(getTheme());
    setTheme(getTheme());
  }, [token, setToken]);

  function toggleTheme() {
    setCurrentTheme(theme === 'light' ? 'dark' : 'light');
    setTheme(theme === 'light' ? 'dark' : 'light');
    //setToken({...token, theme: theme});
  }

  const handleRefreshToken = () => {
    if (!isValidToken() && isValidRefreshToken()) {
      const userInfo = getUserInfo();
      agent
        .getRefreshToken({ token: userInfo.refreshToken })
        .then((res) => {
          if (res.data && res.data.token) {
            let userInfo = getUserInfo();
            setUserInfo({ ...userInfo, ...res.data });
            setJWTToken(res.data.token);
          }
          // window.location.reload();
        })
        .catch((err) => console.log('err', err));
    }
  };

  const themeUrl = theme === 'light' ? lightThemeUrl : darkThemeUrl;

  if (!isValidToken() || isLoadingAuth) {
    return (
      <Login
        isLoadingAuth={isLoadingAuth}
        setIsLoadingAuth={setIsLoadingAuth}
        setToken={setToken}
        token={token}
        setMetabaseCurrentToken={setMetabaseCurrentToken}
      />
    );
  } else {
    return (
      <AppContext.Provider
        value={{ theme, toggleTheme, userInfo, setUserInfoState, setLocaleState, localeState, metabaseCurrentToken }}
        //style={{ backgroundColor: (theme === "dark" ? "#141a17" : "white"), height: "100%"}}
        // data-bs-theme="dark"
      >
        <link rel="stylesheet" href={themeUrl} />
        <div>
          <ErrorBoundary compName="Customer Portal Whole App">
            <div className={'App-header ' + (theme === 'dark' ? 'isDarkTheme' : ' ')} style={{ backgroundColor: theme === 'dark' ? '#141a17' : 'white' }}>
              <Sidebar />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/track-trips" element={<TripPage />} />
                <Route path="/track-backhaul" element={<BackhaulPage />} />
                <Route path="/capacitymanagement" element={<CapacityManagement />} />
                <Route path="/ratecall-details" element={<RateCall />} />
                <Route path="/ratecall-report" element={<ReportComp />} />
                <Route path="/ratecall-defaults" element={<RateCallDefaults />} />
                <Route path="/dashboard" element={<DashBoard />} />
                <Route path="/documents" element={<Documents />}></Route>
                <Route path="/access-denied" element={<NoPermissionPage />} />
              </Routes>
            </div>
          </ErrorBoundary>
          <Footer />
        </div>
      </AppContext.Provider>
    );
  }
}

export default App;
