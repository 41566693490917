import React, { useEffect, useState, useRef, useContext, useCallback } from 'react';
import { SwitchComponent } from '@syncfusion/ej2-react-buttons';
import { TextBoxComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DatePickerComponent, Inject, MaskedDateTime } from '@syncfusion/ej2-react-calendars';
import { MultiSelectComponent, CheckBoxSelection, DropDownTreeComponent, DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import agent from '../../api/agent';
import { L10n, setCulture } from '@syncfusion/ej2-base';
import { DataUtil } from '@syncfusion/ej2-data';
import { Card, Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import AppContext from '../Theme/AppContext';
import { useNavigate } from 'react-router-dom';
import {AiOutlineQuestionCircle} from "react-icons/ai"
import { startLoader, stopLoader } from '../Theme/Utils';

export const intialValues = {
    ref_number: "",
    customer_number: "",
    route_number: "",
    ship_date: null,
    customer_name: null,
    customer_value: null,
}

export const getInitialDateRange = () => {
    const today = new Date();
    const pastDate = new Date(today);
    pastDate.setDate(today.getDate() - 7);
    const futureDate = new Date(today);
    futureDate.setDate(today.getDate() + 1);
    return [pastDate, futureDate];
}

function FilterBar(props) {

    const { t, i18n } = useTranslation();
    const { theme, userInfo } = useContext(AppContext);
    const navigate = useNavigate();

    const { formValue, setFormValue, customerDDList, loadingTableList } = props;
    // const temp = [ { value: 1, text: 'dsdfsd'},{ value: 2, text: 'uyui'}, { value: 3, text: 'vbbnvb'}]
    const [placeKey, setPlaceKey] = useState(0);

    const RefNumberRef = useRef(null);
    const dConsigneeRef = useRef(null);
    const dModeRef = useRef(null);
    const customerDDRef = useRef(null);
    const shipDateDPRef = useRef(null);

    useEffect(() => {
        // const fetchDropdownValues = async () => {
        //     try {
        //         const tempformVals = await props.initDropdown();
        //         if (props.handleSubmitFilter) {
        //             props.handleSubmitFilter(tempformVals);
        //         }
        //     } catch (error) {
        //         console.log("error:", error);
        //     }
        // };
        // fetchDropdownValues();
    }, []);

    useEffect(()=>{
      if(i18n && i18n.language && placeKey > -1){
        setTimeout(()=>{
          setPlaceKey(prev=>prev+1);
        },50)
      }
  },[i18n.language])

    const resetHandle = (_e) => {
        let resets = intialValues
        resets.customer_value = formValue.customer_value;
        resets.customer_name = formValue.customer_name;
        setFormValue(resets);
    };


    const handleChangeInputBox = (inputName, inputVal) => {
        if (inputName === 'customer_name') {
            formValue.customer_value = customerDDList.find(item => item.text === inputVal).value;
        }
        setFormValue({ ...formValue, [inputName]: inputVal });
    }//

    const handleDocumentDownload = (props) => {
      startLoader()
      agent
        .GetTripManagementDoc()
        .then((response) => {
          const linkSource = `data:application/pdf;base64,${response.data}`;
          const downloadLink = document.createElement('a');
          const fileName = t(props) + '.pdf';
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        })
        .catch((x) => alert(t('Unable to download file')))
        .finally(()=>{ stopLoader() })
    };

    return (
      <div style={{ padding: '0rem 4rem' }}>
        <div id="dashboard-form" className="dashboard-form mt-4" style={{ backgroundColor: theme === 'light' ? 'white' : 'rgb(20, 26, 23)' }}>
          <Row>
            <Col md={11} sm={11} lg={11}>
              <div className="flex-center" style={{ maxWidth: '100%', alignItems: 'end', flexWrap: 'wrap', gap: '1rem' }}>
                {customerDDList && customerDDList.length > 1 && (
                  <div className="my-custom-text">
                    <span className="form-field-label">{t('Customer Name')}</span>
                    <div className="">
                      <div className="daterangepicker-control-section">
                        <DropDownListComponent
                          required={true}
                          onChange={(e) => handleChangeInputBox('customer_name', e.target.value)}
                          ref={customerDDRef}
                          value={formValue.customer_name}
                          placeholder={t('Customer Name')}
                          floatLabelType="Never"
                          cssClass="e-medium"
                          className="e-field"
                          dataSource={customerDDList}
                          id="dropdown"
                          data-msg-containerid="errorForDropDown"
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="my-custom-text">
                  <span className="form-field-label">{t('Ship Date')}</span>
                  <div className="">
                    <div className="daterangepicker-control-section">
                      <DateRangePickerComponent
                        className="form-control form-control-sm"
                        placeholder={t('Enter Ship Date')}
                        name="ship_date"
                        id="ship_date"
                        ref={shipDateDPRef}
                        key={"ship_date"+placeKey}
                        presets={[
                          {
                            label: t('This Month'),
                            start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
                            end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
                          },
                          {
                            label: t('This Week'),
                            start: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - new Date().getDay()),
                            end: new Date(),
                          },
                          {
                            label: t('This Quarter'),
                            start: new Date(new Date().getFullYear(), Math.floor(new Date().getMonth() / 3) * 3, 1),
                            end: new Date(new Date().getFullYear(), Math.floor(new Date().getMonth() / 3) * 3 + 3, 0),
                          },
                          { label: t('This Year'), start: new Date(new Date().getFullYear(), 0, 1), end: new Date(new Date().getFullYear(), 11, 31) },
                          {
                            label: t('Last Month'),
                            start: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
                            end: new Date(new Date().getFullYear(), new Date().getMonth(), 0),
                          },
                          {
                            label: t('Last Week'),
                            start: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - new Date().getDay() - 7),
                            end: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - new Date().getDay() - 1),
                          },
                          {
                            label: t('Last Quarter'),
                            start: new Date(new Date().getFullYear(), Math.floor(new Date().getMonth() / 3) * 3 - 3, 1),
                            end: new Date(new Date().getFullYear(), Math.floor(new Date().getMonth() / 3) * 3, 0),
                          },
                          { label: t('Last Year'), start: new Date(new Date().getFullYear() - 1, 0, 1), end: new Date(new Date().getFullYear() - 1, 11, 31) },
                        ]}
                        value={formValue.ship_date !== null ? formValue.ship_date : ''}
                        onChange={(e) => handleChangeInputBox('ship_date', e.value)}
                        cleared={() => handleChangeInputBox('ship_date', null)}
                        open={() => {
                          if (shipDateDPRef && shipDateDPRef.current) {
                            shipDateDPRef.current.popupWrapper.querySelector('.e-list-parent').lastChild.click();
                          }
                        }}
                        showClearButton={false}
                        allowEdit={false}
                      />
                    </div>
                  </div>
                </div>
                <div className=" my-custom-text">
                  <span className="form-field-label">{t('Ref Number')}</span>
                  <div className="">
                    <TextBoxComponent
                      type="text"
                      id="ref_number"
                      name="ref_number"
                      className="form-control form-control-sm"
                      placeholder={t('Ref Number')}
                      key={"ref_number"+placeKey}
                      onChange={(e) => handleChangeInputBox('ref_number', e.target.value)}
                      value={formValue.ref_number}
                      ref={RefNumberRef}
                      htmlAttributes={{ maxlength: '100' }}
                    />
                  </div>
                </div>
                <div className=" my-custom-text">
                  <span className="form-field-label">{t('Customer Number')}</span>
                  <div className="">
                    <TextBoxComponent
                      type="text"
                      className="form-control form-control-sm"
                      placeholder={t('Customer Number')}
                      onChange={(e) => handleChangeInputBox('customer_number', e.target.value)}
                      value={formValue.customer_number}
                      id="customer_number"
                      name="customer_number"
                      key={"customer_number"+placeKey}
                      ref={dConsigneeRef}
                      htmlAttributes={{ maxlength: '100' }}
                    />
                  </div>
                </div>
                <div className=" my-custom-text">
                  <span className="form-field-label">{t('Route Number')}</span>
                  <div className="">
                    <TextBoxComponent
                      type="text"
                      className="form-control form-control-sm"
                      placeholder={t('Route Number')}
                      onChange={(e) => handleChangeInputBox('route_number', e.target.value)}
                      value={formValue.route_number}
                      id="route_number"
                      name="route_number"
                      key={"route_number"+placeKey}
                      ref={dModeRef}
                      htmlAttributes={{ maxlength: '100' }}
                    />
                  </div>
                </div>
                <div>
                  <button
                    onClick={() => {
                      if (props?.handleSubmitFilter) {
                        props?.handleSubmitFilter(formValue);
                      }
                    }}
                    disabled={loadingTableList}
                    className="my-custom-btn"
                    type="submit"
                  >
                    {t('Search')}
                  </button>
                </div>
                <div>
                  <div
                    className="btn btn-outline-secondary active"
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      resetHandle();
                    }}
                  >
                    {t('Reset')}
                  </div>
                </div>
              </div>
            </Col>
            <Col md={1} sm={1} lg={1}>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: "100%" }}>
                <AiOutlineQuestionCircle
                  onClick={() => {
                    handleDocumentDownload('TripManagement');
                  }}
                  style={{ height: '30px', marginTop:"1rem", cursor: 'pointer' }}
                  size={30}
                  color={theme === 'dark' ? 'white' : 'grey'}
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
}

export default FilterBar;

      // //console.log(response.data);
      // const linkSource = `data:application/pdf;base64,${response.data}`;
      // //const blob = new Blob([response.data], { type: 'application/pdf' });

      // // Create a URL for the Blob

      // //const url = window.URL.createObjectURL(linkSource);


      // const downloadLink = document.createElement("a");
      //  const fileName = t("sd") + ".pdf"; 
      //  downloadLink.href = linkSource;
      //  downloadLink.download = fileName;
      //  downloadLink.click();
    //  const url = window.URL.createObjectURL(blob);

      // Create a download link
      // const downloadLink = document.createElement('a');
      // downloadLink.href = url;
      // const fileName = 'your_file_name.pdf';
      // downloadLink.download = fileName;

      // Trigger the download
      //downloadLink.click();

      // Release the object URL to free up resources
      //window.URL.revokeObjectURL(url);

    //   const linkSource = `${response.data}`;
    //   const downloadLink = document.createElement("a");
    //   const fileName = t(props) + ".pdf"; 
    //   downloadLink.href = linkSource;
    //   downloadLink.download = fileName;
    //   downloadLink.click();

    //  // var data = window.atob(response.data);
    //   var bytes = new Uint8Array(response.data.length);
    //   for (var i = 0; i < response.data.length; i++) {
    //       bytes[i] = response.data.charCodeAt(i);
    //   }
    //   var blob = new Blob([bytes.buffer], { type: "application/pdf" });
    //   var url = URL.createObjectURL(blob);
    //   var link = document.createElement('a');
    //   link.download = "filename.pdf";
    //   link.href = url;
    //   document.body.appendChild(link); // Needed for Firefox
    //   link.click();
    //   document.body.removeChild(link);
    // const blob = new Blob([response.data]);
    // const url = URL.createObjectURL(blob);

    // // Create an invisible anchor element to trigger the download
    // const a = document.createElement('a');
    // a.href = url;
    // a.download = "name.pdf"
    // a.style.display = 'none';
    // document.body.appendChild(a);

    // a.click();

    // // Clean up
    // URL.revokeObjectURL(url);
    // document.body.removeChild(a);