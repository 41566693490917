// import { ScheduleComponent, Day, Week, WorkWeek, Month, Agenda, Inject } from '@syncfusion/ej2-react-schedule';
import React, { useEffect, useState, useContext, useRef } from "react";
import "./Trips.css";
import agent from "../../api/agent";
import { showToast, startLoader, stopLoader } from "../Theme/Utils";
import TokenProvider, { logOutUser } from "../TokenProvider/TokenProvider";
import { useTranslation } from "react-i18next";
import AppContext from "../Theme/AppContext";
import FilterBar, { getInitialDateRange, intialValues } from "./FilterBar";
import TripBackhaulStopTable from "./TripBackhaulStopTable";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Loading from "../Loading/Loading";
import { Animation } from '@syncfusion/ej2-base';
import * as XLSX from 'xlsx';
// import Localbinding from "./temp";
//import XLSX from "xlsx-js-style";

const TripsPage = (props) => {
  const { t, i18n } = useTranslation();
  const moduleName = "Trips";
  const { theme, userInfo } = useContext(AppContext);

  const { token, setToken } = TokenProvider();
  // const cbToken = token.token;
  // const username = token.username;
  const [formValue, setFormValue] = useState(intialValues);

  const [tripTableData, setTripTableData] = useState([]);
  const [loadingTableList, setLoadingTableList] = useState(false);
  const [loadingCustomerList, setLoadingCustomerList] = useState(true);
  const [customerDDList, setCustomerDDList] = useState([]);

  const navigate = useNavigate();

  // const [sortOrder, setSortOrder] = useState('Ascending'); // or 'Descending' based on your default
  // const [sortColumn, setSortColumn] = useState('TripNumber'); // Default sorting column
  // const [totalRecords, setTotalRecords] = useState(0);
  // const tripsGridRef = React.createRef();
  // const [pageSettings, setPageSettings] = useState({
  //     currentPage: 1,
  //     pageSize: 10,
  //     pageCount: 5,
  //     totalRecordsCount: 0
  // });

  const initDropdown = async () => {
    try {
      setLoadingCustomerList(true);
      let list = await agent.getCustomerListTrack();
      if (!(list.data.length > 0)) {
        navigate("/access-denied", { state: { moduleName: "Trips" } });
        return;
      }
      list = list.data.map((item) => {
        const text = item.split("-")[0];
        return { text, value: item };
      });
      list = list.sort((a, b) => a.text.localeCompare(b.text));
      setCustomerDDList(list);
      const tempForm = intialValues;
      tempForm.customer_name = list[0].text;
      tempForm.customer_value = list[0].value;
      tempForm.ship_date = getInitialDateRange();
      setFormValue(tempForm);
      setLoadingCustomerList(false);
      return tempForm;
    } catch (err) {
      console.log("error in getFilterValues", err);
      // setLoadingCustomerList(false);
      handlAuthorization(err);
      return intialValues;
    }
  };

  useEffect(() => {
    const fetchDropdownValues = async () => {
      try {
        const tempformVals = await initDropdown();
        if(tempformVals.customer_value){
          handleSubmitFilter(tempformVals);
        }
      } catch (error) {
        console.log("error:", error);
      }
    };
    fetchDropdownValues();
  }, []);

  const handlAuthorization = (error) => {
    console.log(error);
    setLoadingTableList(false);
    if (error.code === "ERR_NETWORK") {
      showToast(t("No response from server."), "error");
    } else if (error?.response?.status === 401) {
      showToast(t("Your session has expired. Please login again."), "error");
      logOutUser();
    } else if (error?.response?.status === 403) {
      //showToast(t("You do not have access to ")+ moduleName+ t(", please contact your account manager to gain access."), 'error');
      navigate("/access-denied", { state: { moduleName } });
    }
  };

  const getAllTableData = (filters) => {
    const convertData = (dataA) => {
      return dataA.map((data) => ({
        ...data,
        trailerNumber: data.trailerNumber ? data.trailerNumber : "",
        tripStatus_fr: data.tripStatus ? t(data.tripStatus, { lng: 'fr' }) : "",
        origin_en: `${moment(data.pickupDate).format("YYYY-MM-DD")}  ${
          data.firstPickName
        } ${data.firstPickCity}, ${data.firstPickupState}`,
        destination_en: `${moment(data.delDate).format("YYYY-MM-DD")}  ${
          data.lastDropName
        } ${data.lastDropCity}, ${data.lastDropState}`,
      }));
    };
    setLoadingTableList(true);
    let ShipDate;
    if (filters.ship_date) {
      ShipDate = {
        shipStartDate: moment(filters.ship_date[0]).format("YYYY-MM-DD"),
        shipEndDate: moment(filters.ship_date[1]).format("YYYY-MM-DD"),
      };
    }
    let customer = filters.customer_value ? filters.customer_value : null;
    const newParams = {
      custRefNumber: filters.ref_number ? filters.ref_number : null,
      ...ShipDate,
      customer,
      CustomerNumber: filters.customer_number ? filters.customer_number : null,
      RouteNum: filters.route_number ? filters.route_number : null,
    };
    agent
      .getCustomerTripsList(newParams)
      .then((response) => {
        setTripTableData(convertData(response.data.items));
        setLoadingTableList(false);
        // setTotalRecords(response.data.totalRecords)
        // setPageSettings({
        //     ...pageSettings,
        //     currentPage: response.data.currentPage,
        //     totalPages: response.data.totalPages,
        // })
      })
      .catch((error) => {
        handlAuthorization(error);
      });
  };

  function downloadExcel(excelData) {
    const dataArray = excelData.map((item) => {
      const schStopDate = item.schStopDate
        ? new Date(item.schStopDate).toLocaleString("en-US", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hourCycle: "h23",
          })
        : "";
      const actualArrivalDate = item.actualArrivalDate
        ? new Date(item.actualArrivalDate).toLocaleString("en-US", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hourCycle: "h23",
          })
        : "";
      const actualDepartureDate = item.actualDepartureDate
        ? new Date(item.actualDepartureDate).toLocaleString("en-US", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hourCycle: "h23",
          })
        : "";

      return [
        item.tripNumber,
        item.trackingNumber,
        item.trailerNumber,
        item.carrierName,
        item.tripStatus,
        item.stopSeqNumber,
        item.stopStatus,
        item.stopName,
        item.stopAddress,
        item.stopCity,
        item.stopState,
        schStopDate,
        actualArrivalDate,
        actualDepartureDate,
        item.stopTimeZone,
      ];
    });

    const header = [
      t("Trip Number"),
      t("Tracking Number"),
      t("Trailer Number"),
      t("Carrier Name"),
      t("Trip Status"),
      t("Stop Seq Number"),
      t("Stop Status"),
      t("Stop Name"),
      t("Stop Address"),
      t("Stop City"),
      t("Stop State"),
      t("Scheduled Stop Date"),
      t("Actual Arrival Date"),
      t("Actual Departure Date"),
      t("Stop TimeZone"),
    ];
    const wsData = [header, ...dataArray];
    const ws = XLSX.utils.aoa_to_sheet(wsData);
    const colWidths = wsData[1].map((col) => ({
      wch: (col ? col.toString().length : 18) + 2,
    }));
    ws["!cols"] = colWidths;
    ws["!rows"] = [{ hpt: 12, hpx: 24, outlineLevel: 0, collapsed: false }];
    ws["!cols"] = colWidths;
    var boldFont = { bold: true };
    for (var i = 0; i < header.length; i++) {
      var cell = ws[XLSX.utils.encode_cell({ r: 0, c: i })];
      if (!cell.s) {
        cell.s = {};
      }
      cell.s.font = boldFont;
    }
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, ws, "Trips");
    var wbout = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "binary",
      cellStyles: true,
    });
    function s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    }
    const filename = `Trips_${new Date().toISOString().slice(0, 10)}.xlsx`;
    const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
  }

  const exportToExcel = (args, gridName) => {
    startLoader();
    let ShipDate;
    if (formValue.ship_date) {
      ShipDate = {
        shipStartDate: moment(formValue.ship_date[0]).format("YYYY-MM-DD"),
        shipEndDate: moment(formValue.ship_date[1]).format("YYYY-MM-DD"),
      };
    }
    let customer = formValue.customer_value ? formValue.customer_value : null;
    const newParams = {
      custRefNumber: formValue.ref_number ? formValue.ref_number : null,
      ...ShipDate,
      customer,
      CustomerNumber: formValue.customer_number
        ? formValue.customer_number
        : null,
      RouteNum: formValue.route_number ? formValue.route_number : null,
    };
    agent
      .getExportedExcelData("Trips", newParams)
      .then((response) => {
        const excelData = response.data;
        downloadExcel(excelData);
      })
      .catch((error) => {
        handlAuthorization(error);
      })
      .finally(()=>{
        stopLoader()
      })
  };

  const handleSubmitFilter = () => {
    getAllTableData(formValue);
  };

  const loaderRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    if (loadingCustomerList) {
      let animation = new Animation();
      animation.animate(loaderRef.current, { name: 'FadeOut', duration: 800 });
    } else {
      let animation = new Animation();
      animation.animate(contentRef.current, { name: 'FadeIn', duration: 800 });
    }
  }, [loadingCustomerList]);

  useEffect(()=>{
    setLoadingTableList(true);
    setTimeout(() => {
    setLoadingTableList(false);
    }, 50);
    // throw new Error('Simulated error from ChildComponentWithError'); 
  },[theme])

  return (
    <div>
      {loadingCustomerList ? (
        <div ref={loaderRef} style={{ height: "100vh" }} className="my-custom-loader-container">
          <Loading />
        </div>
      ) : (
        <div
          className="container-flex"
          style={{ padding: "1.5rem 0rem 4rem 1.5rem" }}
          ref={contentRef}
        >
          {/* <Localbinding /> */}
          <div className="custom-label">
            <h1 className="mt-4 my-custom-text main-header-text flex-center">
              {t("Trips")}
            </h1>
          </div>
          <div className="">
            <FilterBar
              formValue={formValue}
              setFormValue={setFormValue}
              handleSubmitFilter={handleSubmitFilter}
              initDropdown={initDropdown}
              customerDDList={customerDDList}
              loadingTableList={loadingTableList}
            />
          </div>
          <div className="chart-container mt-4">
            <TripBackhaulStopTable
              formValue={formValue}
              activeTab="Trips"
              tripTableData={tripTableData}
              loadingTableList={loadingTableList}
              customer={formValue.customer_value}
              exportToExcelTrips={exportToExcel}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default TripsPage;
// const handleActionBegin = (args) => {
//     if (args.requestType === 'paging' || args.requestType === 'sorting') {
//         //   setGridState(prevState => ({
//         //     ...prevState,
//         //     currentPage: args.currentPage,
//         //     pageSize: args.pageSize,
//         //     sortSettings: args.sorted && args.sorted.length > 0 ? args.sorted : prevState.sortSettings
//         //   }));
//         console.log("args ", args);
//         console.log("args.sorted && args.sorted.length > 0 ? args.sorted : prevState.sortSettings ", args.sorted && args.sorted.length > 0 ? args.sorted : null);
//         //   setPageSettings({
//         //     pageSize: args.pageSize,
//         //     currentPage: args.currentPage,
//         //     totalPages:
//         //   })
//     }
// };

// const dataManager = new DataManager({
//     url: 'https://localhost:7150/api/v1/CustomerPortalTrips/GetAllCustomerTrips',
//     adaptor: new UrlAdaptor(),
//     crossDomain: true
// });
// const query = new Query().from('Orders').page(pageSettings.currentPage, pageSettings.pageSize);
// const getData = () => {
//     dataManager.executeQuery(query).then((e) => {
//         setData(e.result);
//         setPageSettings({
//             ...pageSettings,
//             currentPage: e.currentPage,
//             totalRecordsCount: e.count
//         });
//     });
// }
// useEffect(() => {
//     getData();
// }, []); // Empty dependency array means this effect runs once on component mount
// const handlePageChange = (args) => {
//     query.page(args.currentPage, args.pageSize);
//     getData();
// }

// const data = new DataManager({
//     adaptor: new WebApiAdaptor(),
//     url: 'https://localhost:7150/api/v1/CustomerPortalTrips/GetAllCustomerTrips',
//     headers: {
//         Authorization: `Bearer ${getJWTToken()}`
//     },
//     crossDomain: true, // Add this if making a cross-domain request
//     // adaptor: {
//     //     beforeSend: (args) => {
//     //         console.log("args", );
//     //         const query = args.request.properties;
//     //         if (query instanceof Query) {
//     //             query.queries.forEach(param => {
//     //                 if (param.fn === 'onPage') {
//     //                     if (param.e.key === 'top') {
//     //                         param.e.key = 'rowPerPage';
//     //                     }
//     //                     if (param.e.key === 'skip') {
//     //                         param.e.key = 'currentPage';
//     //                     }
//     //                 }
//     //                 if (param.fn === 'onSortBy') {
//     //                     if (param.e.name === 'direction') {
//     //                         param.e.name = 'sortOrder';
//     //                     }
//     //                     if (param.e.name === 'field') {
//     //                         param.e.name = 'sortColumn';
//     //                     }
//     //                 }
//     //                 // Add additional conditions for custom parameters as needed
//     //             });
//     //         }
//     //     }
//     // }
// });
// console.log("totalRecords ", totalRecords, " ,,pageSettings ", pageSettings);
