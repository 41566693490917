import React from 'react';
import agent from "../../api/agent";
import TokenProvider, {getTokenUser} from "../TokenProvider/TokenProvider";
import { withTranslation } from 'react-i18next';
import { getUserInfo } from '../Auth/ProtectedRoute';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
      compName: (props.compName ? props.compName : ""),
      token: getTokenUser(),
      tenantName: (props.tenantName ? props.tenantName : " "),
    };
    // window.onerror = function (message, source, lineno, colno, error) {
    //   console.log("error: ",error);
    //   console.log("message: ",message);
    //   console.log("lineno: ",lineno);
    //   console.log("colno: ",colno);
    //   console.log("source: ",source);
    //   return true; // Prevent default browser behavior
    // };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      ...this.state,
      hasError: true,
      error: error,
      errorInfo: errorInfo
    });
    console.error("in Component :", this.state.compName, " The error : ",error, errorInfo);
    this.postEmailError(error, errorInfo);
  }

  postEmailError = (error, errorInfo) => {
    console.log("error : ", error);
    console.log("errorInfo : ", errorInfo);
    console.log("getUserInfo()", getUserInfo());
    agent.sendErrorEmail({
      token: this.state.token?.token,
      errorTypes: 17,
      UserName: getUserInfo().username,
      TenantName: this.state.tenantName,
      APIServiceName:" ",
      ControllerName:" ",
      MethodName: "Error in Customer Portal Frontend",
      Parameters:`[[Environment : ${process.env.REACT_APP_ENV}]]`,
      ErrorMessage: `Error: ${error && error.toString()}`,
      InnerException:" ",
      CallStack:`[[Environment : ${process.env.REACT_APP_ENV}]]: ${errorInfo && errorInfo.componentStack}`,
      ReferenceNumber:" ",
      ReferenceType:" ",
      sendEmail:true,
    })
    .then((response) => {
      })
    .catch((error) => {
      console.error('Error while reporting the error:', error);
    }).finally(()=>{
      window.location.reload();
    })
  };

  render() {
    const { t } = this.props;

    if (this.state.hasError) {
      return (
        <div style={{textAlign: 'center'}} className='my-custom-text flex-center'>
          <h2>{t('Something went wrong')}!</h2>
          {/* <p>{this.state.error && this.state.error.toString()}</p>
          <p>{this.state.errorInfo && this.state.errorInfo.componentStack}</p> */}
        </div>
      );
    }
    return this.props.children;
  }
}

export default withTranslation()(ErrorBoundary);
