import toast from 'react-hot-toast';
import { getTheme } from './ThemeUtils';
import moment from 'moment';

export const constants = {
    invalid_date: '0001-01-01T00:00:00'
}
export const formatDate = (date, format) => {
    if (date === constants.invalid_date || date === null || !date) {
        return "";
    } else {
        try {
            if (moment(date).isValid()) {
                return moment(date).format(format);
            } else {
                console.error("Invalid date:", date);
                return "";
            }
        } catch (error) {
            console.error("Error formatting date:", error);
            return "";
        }
    }
}

export const showToast = (message, type, inTheme, other) => {
    const theme = (inTheme || getTheme());
    const duration = 5000;
    const baseStyle = {
        borderRadius: '10px',
        zIndex: '9999',
    };
    if (theme === "dark") {
        const style = {
            ...baseStyle,
            background: "#282c34",
            color: '#fff',
        };
        if (type === "success") {
            toast.success(message, { style, duration })
        }
        else if (type === "error") {
            toast.error(message, { style, duration })
        }
        else {
            toast(message, { style, duration })
        }
    }
    else {
        const style = {
            ...baseStyle,
        };
        if (type === "success") {
            toast.success(message, { style, duration })
        }
        else if (type === "error") {
            toast.error(message, { style, duration })
        }
        else {
            toast(message, { style, duration })
        }
    }
}

export const startLoader = () => {
    const rootElement = document.querySelector("#root");
    if (rootElement) {
        rootElement.style.cursor = "wait";
    }
}

export const stopLoader = () => {
    const rootElement = document.querySelector("#root");
    if (rootElement) {
        rootElement.style.cursor = "auto";
    }
}
