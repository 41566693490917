import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { L10n } from '@syncfusion/ej2-base';
import { loadCldr } from '@syncfusion/ej2-base';
import * as gregorian from './ca-gregorian.json';
import * as currencies from './currencies.json';
import * as numbers from './numbers.json';
import * as timeZoneNames from './timeZones.json';
import fr from './fr.json';
// https://support.syncfusion.com/support/tickets/515836#update-5611088

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        //English translations here
      },
    },
    fr: {
      translation: {
        //French
        'Bill of Lading Number': 'Numéro de connaissement',
        'Document Type is required': 'Le type de document est obligatoire',
        'Order Number is required': 'Le numéro de commande est obligatoire',
        'Customer Name is required': 'Le nom du client est obligatoire',
        'Delivery Method': 'Mode de livraison',
        'Delivery Date': 'Date de livraison',
        'This field is required.': 'Ce champ est requis.',
        'Select A Document Type': 'Sélectionner un type de document',
        'Document Repository': 'Dépôt de Documents',
        'Bill of Ladiing': 'Connaissement',
        'Order Number': 'Numéro de commande',
        'Select Type': 'Sélectionner le type',
        'Document Type': 'Type de Document',
        Search: 'Rechercher',
        'Delivery Note': 'Bon de Livraison',
        'Order Confirmation': 'Confirmation de Commande',
        'This field is required': 'Ce champ est requis',
        Download: 'Télécharger',
        'File Name': 'Nom du Fichier',
        'Customer PO Number': 'Numéro de Commande Client',
        'Delivery Number': 'Numéro de Livraison',
        ShipDate: "Date d'Expédition",
        'Print Date': "Date d'Impression",
        'Print Time': "Heure d'Impression",
        Subject: 'Sujet',
        Logout: 'Se déconnecter',
        Home: 'Accueil',
        Privacy: 'Confidentialité',
        Help: 'Aide',
        'Cedric Millar Integrated Solutions': ' Cedric Millar Solutions Intégrées',
        'Add Event': 'Ajouter un évènement',
        'Need Help?': "Besoin d'aide?",
        Login: 'Connexion',
        Dark: 'Sombre',
        Light: 'Clair',
        "FAQ's": 'Questions fréquemment posées (FAQ)',
        'Need help': "Besoin d'aide",
        'Contact Us': 'Contactez-nous',
        Email: 'Courriel',
        'Incorrect username and/or password.': "Nom d'utilisateur et/ou mot de passe incorrect(s).",
        Enroute: 'En route',
        Delivered: 'Livré',
        Assigned: 'Assigné',
        Completed: 'Complété',
        Open: 'Ouvrir',
        Scheduled: 'Planifié',
        'Track and Trace': 'Suivi et Traçabilité',
        Trips: 'Trajets',
        Backhaul: 'Retour à vide',
        'Capacity Management': 'Gestion de la Capacité',
        'Rate Call': 'Appel de Tarif',
        Reports: 'Rapports',
        Controls: 'Contrôles',
        'Rate Look up': 'Recherche de tarif',
        'Access Denied': 'Accès Refusé',
        'You do not have access to': "Vous n'avez pas accès à",
        'please contact your account manager to gain access.': "veuillez contacter votre gestionnaire de compte pour obtenir l'accès.",
        this: 'ce',
        'Ship Date': "Date d'expédition",
        'Enter Ship Date': "Entrer la date d'expédition",
        'Customer Name': 'Nom du client',
        'Ref Number': 'Numéro de référence',
        'Customer Number': 'Numéro de client',
        'Route Number': 'Numéro de route',
        Backhauls: 'Retours de chargement',
        'Enter Equipment Available Date': "Saisir la date de disponibilité de l'équipement",
        'Confirmation Sent': 'Confirmation envoyée',
        Refreshing: 'Actualisation en cours',
        Commments: 'Commentaires',
        Used: 'Utilisé',
        Confirmation: 'Confirmation',
        Sent: 'Envoyé',
        'No response from server.': 'Pas de réponse du serveur.',
        'Equipment Used should be less than Quantity.': "La quantité d'équipement utilisée doit être inférieure à la quantité disponible.",

        'The dashboard is not available.': "Le tableau de bord n'est pas disponible",
        'Error has occurred while trying to access your data. The systems administrator has been notified. Please try again in few mins':
          "Une erreur s'est produite lors de la tentative d'accès à vos données. L'administrateur système a été notifié. Veuillez réessayer dans quelques minutes.",
        'Assigned Trips': 'Voyages attribués',
        'Last Refreshed': 'Dernière actualisation',
        'Your token is about to expire in 30 minutes.': 'Votre jeton est sur le point d’expirer dans 30 minutes.',
        Refresh: 'Actualiser',
        'No records to display': 'Aucun enregistrement à afficher',
        'Past 30 Day Capacity Locations': 'Emplacements de capacité des 30 derniers jours',
        From: 'De',
        'Pickup City/Area': 'Ville/Zone de ramassage',
        Qty: 'Quantité',
        To: 'À',
        'Destination City/Area': 'Ville/Zone de destination',
        'Equipment Available Date': "Date de disponibilité de l'équipement",
        Comments: 'Commentaires',
        Comment: 'Commentaire',
        Reset: 'Réinitialiser',
        Submit: 'Soumettre',
        'Update In/Out': "Mettre à jour l'entrée/sortie",
        'Add Document': 'Ajouter un document',
        'Replace Document': 'Remplacer le document',
        'Select Option': 'Sélectionner une option',
        'Add or Replace Document': 'Ajouter ou remplacer un document',
        'Date & Time In': "Date et heure d'entrée",
        'Date & Time Out': 'Date et heure de sortie',
        'Proof Of Delivery PDF': 'Preuve de livraison PDF',
        Browse: 'Parcourir',
        'Or drop files here': 'Ou déposer les fichiers ici',
        ETA: 'ETA',
        'ETA Update': 'ETA Update',
        Arrived: 'Arrivé',
        Pickup: 'Ramassage',
        Delivery: 'Livraison',
        'Cross-Dock Arrival': 'Arrivée en cross-docking',
        'Unable to get the Trip Confirmation.': "Impossible d'obtenir la confirmation du voyage.",
        // for main look of page
        'No Image found': 'Aucune image trouvée',
        // trips
        TripID: 'Identifiant du voyage',
        'Trip Number': 'Numéro du voyage',
        'Tracking Number': 'Numéro de suivi',
        'Order Reference': 'Référence de la commande',
        'Trailer Number': 'Numéro de la remorque',
        'Carrier Name': 'Nom du transporteur',
        'Trip Status': 'Statut du voyage',
        'Stop Seq Number': "Numéro de séquence d'arrêt",
        'Stop Status': "Statut de l'arrêt",
        'Stop Name': "Nom de l'arrêt",
        'Stop Address': "Adresse de l'arrêt",
        'Stop City': "Ville de l'arrêt",
        'Stop State': "État de l'arrêt",
        'Scheduled Stop Date': "Date planifiée de l'arrêt",
        'Actual Arrival Date': "Date d'arrivée réelle",
        'Actual Departure Date': 'Date de départ réelle',
        'Stop TimeZone': "Fuseau horaire de l'arrêt",

        'City/Province postal code not found: ': 'Code postal de la ville/province non trouvé: ',
        'Maximize the power of your supply chain with Cedric Millar’s CUSTOMER PORTAL!':
          "Optimisez la puissance de votre chaîne d'approvisionnement avec le PORTAIL CLIENT de Cedric Millar !",
        'Our Customer Portal will help you manage your capacity, track the status of your trips, look up trip rates in accordance to your final destination, download reports and much more – all helping your business create flow, uncover hidden savings, and enhance efficiencies in your supply chain operation.':
          "Notre Portail Client vous aidera à gérer votre capacité, à suivre l'état de vos voyages, à consulter les tarifs de voyage en fonction de votre destination finale, à télécharger des rapports et bien plus encore - tout cela contribuant à créer un flux dans votre entreprise, à découvrir des économies cachées et à améliorer l'efficacité de votre chaîne d'approvisionnement.",
        Click: 'Cliquez',
        'in the top left corner to navigate the menu items.': 'dans le coin supérieur gauche pour naviguer dans les éléments du menu.',
        'This Month': 'Ce mois-ci',
        'This Week': 'Cette semaine',
        'This Quarter': 'Ce trimestre',
        'This Year': 'Cette année',
        'Last Month': 'Le mois dernier',
        'Last Week': 'La semaine dernière',
        'Last Quarter': 'Le trimestre dernier',
        'Last Year': "L'année dernière",
        'Ref Number': 'Entrez le numéro de référence',
        'Customer Number': 'Numéro de client',
        'Route Number': 'Numéro de route',

        // for capacity board
        'Please enter in a date': 'Veuillez entrer une date',
        'Please select a truck': 'Veuillez sélectionner un camion',
        'Please enter a qty': 'Veuillez entrer une quantité',
        'Your session has expired. Please login again.': 'Votre session a expiré. Veuillez vous reconnecter.',
        'Please select the equipment type again': "Veuillez sélectionner à nouveau le type d'équipement",
        'Unable to post capacity. Please refresh the page and check your internet connection.':
          'Impossible de publier la capacité. Veuillez rafraîchir la page et vérifier votre connexion Internet.',
        'Unable to delete. Please check your internet connection. If the error continues, please contact the Help Desk':
          "Impossible de supprimer. Veuillez vérifier votre connexion Internet. Si l'erreur persiste, veuillez contacter le service d'assistance",

        'Unable to update event status. Please check your internet connection':
          "Impossible de mettre à jour le statut de l'événement. Veuillez vérifier votre connexion Internet.",
        'Unable to add event status, please login again': "Impossible d'ajouter le statut de l'événement, veuillez vous reconnecter.",
        'Unable to add event status.': "Impossible d'ajouter le statut de l'événement.",
        'Unable to add event status. Error:': "Impossible d'ajouter le statut de l'événement. Erreur :",
        'In date & time cannot be after out date & time': "La date et l'heure d'entrée ne peuvent pas être postérieures à la date et l'heure de sortie",
        Error: 'Erreur',
        'Please enter in a date and time': 'Veuillez entrer une date et une heure',
        'Driver release from consignee (trip will be shown as delivered automatically on the last stop)':
          'Autorisation du destinataire par le conducteur (le trajet sera automatiquement marqué comme livré sur le dernier arrêt)',
        'ETA will send notificaiton to customer and will not be reflected here':
          "L'heure estimée d'arrivée enverra une notification au client et ne sera pas affichée ici",
        'Time of arrival at consignee': "Heure d'arrivée chez le destinataire",
        'Date & Time': 'Date et heure',
        'Delay Reason': 'Raison du retard',

        Issue: 'Problème',
        Answer: 'Réponse',
        'Truck Type': 'Type de camion',
        'State/Province': 'État/Province',
        Quantity: 'Quantité',
        Copy: 'Copier',
        Delete: 'Supprimer',
        Equipment: 'Équipement',
        Type: 'Type',
        Available: 'Disponible',
        Date: 'Date',
        'Enter date': 'Entrez la date',

        // rate call
        '*Postal OR Provice/City Required': '*Code postal OU Province/Ville requis',
        '*Invalid Canadian Postal Code': '*Code postal canadien invalide',
        'Total Weight is required': 'Le poids total est requis',
        'City/Province postal code not found:': 'Code postal de la ville/province non trouvé :',
        RateCall: 'Appel de tarif',
        'Item Not Found': 'Article non trouvé',
        'Cedric Millar Rate Call': 'Appel de tarif Cedric Millar',
        'Postal Code': 'Code postal',
        OR: 'OU',
        'Select a Province': 'Sélectionner une province',
        'Select a City': 'Sélectionner une ville',
        Total: 'Total',
        'Weight(LB)': 'Poids (lb)',
        'Enter Total Weight(LB)': 'Entrer le poids total (lb)',
        '**SKU Total weight will override the total weight entered here, if available.':
          "**Le poids total du SKU remplacera le poids total saisi ici, s'il est disponible.",
        'Top 3 Oracle Item #': "Top 3 Numéro d'article Oracle",
        'LookUp Rate': 'Rechercher un tarif',
        Oracle: 'Oracle',
        'Item #': "Numéro d'article",
        'Oracle<br>Item #': "Numéro d'article<br>Oracle",
        Length: 'Longueur',
        Width: 'Largeur',
        Height: 'Hauteur',
        'Unit Weight(LB)': 'Poids unitaire (lb)',
        Unit: 'Unité',
        'Total Weight(LB)': 'Poids total (lb)',
        'Row #': 'Ligne #',
        'Carrier Name': 'Nom du transporteur',
        Carrier: 'Transporteur',
        Name: 'Nom',
        Mode: 'Mode',
        Transit: 'Transit',
        Days: 'Jours',
        'Total Pay': 'Paiement total',
        'Transit Days - The pickup day is assumed to be day 0.': "Jours de transit - Le jour d'enlèvement est supposé être le jour 0.",
        'Carrier charges include fuel but no taxes.': 'Les frais du transporteur incluent le carburant mais pas les taxes.',

        // reports
        Results: 'Résultats',
        'Rate Call Reports': "Rapports d'appel de tarif",
        'Date Range': 'Plage de dates',
        'Select Date Range': 'Sélectionner la plage de dates',
        'Request Dt': 'Date de demande',
        Request: 'Demande',
        Dt: 'Date',
        'Requested Weight(Lbs)': 'Poids demandé (lb)',
        Requested: 'Demandé',
        Weight: 'Poids',
        Lbs: 'lb',
        'Loaded Date': 'Date de chargement',
        Loaded: 'Chargé',
        Message: 'Message',
        'Error Message': "Message d'erreur",
        Dashboard: 'Tableau de bord',
        'Unable to Retrieve Image.': "Impossible de récupérer l'image.",

        // rate call defaults
        '*Value must be a positive number.': '*La valeur doit être un nombre positif.',
        '*You have unsaved changes.': '*Vous avez des modifications non enregistrées.',
        'Enter Value': 'Entrer la valeur',
        'RateCall Controls': "Contrôles d'appel de tarif",
        'Courier Average Weight per Box:': 'Poids moyen du courrier par boîte :',
        'Freight adder for courier:': 'Majoration de fret pour le courrier :',
        'Freight added for LTL:': 'Fret ajouté pour le LTL :',
        'Average weight per pallet:': 'Poids moyen par palette :',
        'Cube to weight ratio:': 'Rapport cube/poids :',
        'Lbs.': 'lb',
        '%(on the total value)': '% (sur la valeur totale)',
        'Lbs per Cubic Ft': 'lb par pied cube',

        // for trip management
        'Terms of Service': "Conditions d'utilisation",
        Departed: 'Départ',
        'Trip#': 'Numéro de trajet',
        'Ref#': 'Réf.',
        'P/U Date': 'Date de ramassage',
        'First P/U': 'Premier ramassage',
        'Del Date': 'Date de livraison',
        'Last Drop': 'Dernière livraison',
        Stops: 'Arrêts',
        Mode: 'Mode',
        Weight: 'Poids',
        Miles: 'Miles',
        Download: 'Télécharger',
        Seqeunce: 'Séquence',
        Trip: 'Trajet',
        'Stop #': 'Arrêt n°',
        'Shipment Status': "Statut de l'envoi",
        Address: 'Adresse',
        'ZIP Code': 'Code postal',
        Instructions: 'Instructions',
        'Appt Req': 'Demande de rendez-vous',
        'Something went wrong': 'Quelque chose s’est mal passé',

        /// FAQ's
        'I entered a load in the Capacity Board, and it does not show up on the Trip Management page.':
          "J'ai saisi un chargement dans le tableau des capacités et il n'apparaît pas sur la page de gestion des voyages.",
        'The customer needs to accept the load and update their systems. Only then a trip will be generated.':
          "Le client doit accepter le chargement et mettre à jour son système. Ce n'est qu'à ce moment-là qu'un voyage sera généré.",
        'I updated the ETA status for a trip. Nothing happens.':
          "J'ai mis à jour le statut (Heure probable d'arrivée / ETA) d'un déplacement. Rien ne semble se passer.",
        'A notification with the update is sent to the customer and will not be reflected here.':
          'Une notification avec la mise à jour est envoyée au client et ne sera pas affichée ici.',
        "I received a cancellation email. Why can't I see the trip?": "J'ai reçu un courriel d'annulation : Pourquoi ne puis-je pas voir le voyage?",
        'Cancelled trips are not shown. The trip was cancelled by the customer or replaced by another trip created in our system.':
          'Les voyages annulés ne sont pas affichés. Le voyage a été annulé par le client ou remplacé par un autre voyage créé dans notre système.',
        'The same loading slip is on multiple trips.': 'Le même bon de chargement figure sur plusieurs voyages.',
        'Please contact us at helpdesk@cedricmillar.com with the trip numbers.':
          "Veuillez nous contacter à l'adresse helpdesk@cedricmillar.com en indiquant les numéros de voyage.",
        'The weights are not correct for my trip.': 'Les poids sont incorrects pour mon voyage.',
        'Please contact us at helpdesk@cedricmillar.com with the trip numbers and correct weights.':
          "Veuillez nous contacter à l'adresse helpdesk@cedricmillar.com avec les numéros de voyage et les poids adéquats.",
        'I need to cancel my trip.': 'Je dois annuler mon voyage.',
        'Please contact the customer directly to request cancellation. Once updated in their systems, the trip will be cancelled in TMS.':
          "Veuillez contacter le client directement pour demander l'annulation. Une fois mis à jour dans leurs systèmes, le voyage sera annulé dans notre système de gestion des transports (TMS).",
        'I am not sure what trip to invoice as I have received cancellations from active trips.':
          "Je ne sais pas quel voyage facturer, car j'ai reçu des annulations de voyages actifs.",
        'Please check Customer Portal for loading slip number and match it to trip number for invoicing.':
          'Veuillez consulter le portail du transporteur pour obtenir le numéro de bordereau de chargement et le faire correspondre au numéro de voyage pour la facturation.',

        //// for the report error messgage:
        'No matching rate found.': 'Aucun tarif correspondant trouvé.',
        'Unknown Destination City State': 'État de la ville de destination inconnu',
        'No rates found for matching cities': 'Aucun tarif trouvé pour les villes correspondantes',
        'No rates found for matching dimensions': 'Aucun tarif trouvé pour les dimensions correspondantes',
        'Unknown Origin City State': "État de la ville d'origine inconnu",
        'You have unsaved changes. Are you sure you want to switch customer?':
          'Vous avez des modifications non enregistrées. Êtes-vous sûr de vouloir changer de client ?',
        ////
        'Welcome to Customer Portal': 'Bienvenue sur le Portail des Transporteurs',
        'Help Guides': "Guides d'aide",
        'Contact us': 'Nous contacter',
        Schedule: 'Calendrier',
        'Excel Export': 'Exportation Excel',
        //06/06
        'New Event': 'Nouvel évènement',
        'Choose event type': "Choisissez le type d'événementR",
        Door: 'Porte',
        'Select a door': 'Sélectionnez une porte',
        Time: 'Temps',
        Start: 'Début',
        End: 'Fin',
        'Override Freight Profile': 'Remplacer le profil de cargaison',
        'Total Event Minutes': "nombre total de minutes d'événement",
        'All Day Event': 'Événements toute la journée',
        'Block Other Events': "Bloquer d'autres événements",
        'Expiring Session...': 'Session expirante...',
        'Your session is about to expire. Please logout.': "Votre session est sur le point d'expirer. Veuillez vous déconnecter.",
        'Your login sessions has expired. Please Logout.': 'Votre session de connexion a expiré. Veuillez vous déconnecter.',
        Password: 'Le mot de passe',
        'Incorrect credentials. Please try again.': 'Identifiants incorrects. Veuillez réessayer.',
        'Email Us': 'Envoyez-nous un email',
        Username: "Nom d'utilisateur",
        'Unable to reach the server. Please check your internet connection.':
          "Impossible d'atteindre le serveur. S'il vous plait, vérifiez votre connexion internet. FR",
        CANCELLED: 'ANNULER',
        Cancel: 'Annuler',
        'Choose cancel reason': "Choisissez la raison de l'annulation",
        Status: 'Statut',
        'Stops Details': 'Détails des arrêts',
        Doc: 'Document',
        'OS and D': 'Origine et destination',
        Seq: 'Séquence',
        'Ship To': 'Expédier à',
        Stop: 'Arrêt',
        'Origin Dates': "Dates d'origine",
        'Sch Date': 'Date prévue',
        'Actual Date': 'Date réelle',
        Images: 'Images',
        'Download PDF': 'Télécharger le PDF',
        'Close PDF': 'Fermer le PDF',
        'Rotate Left': 'Rotation à gauche',
        'Rotate Right': 'Rotation à droite',
        'Rotate PDF': 'Faire pivoter le PDF',
        'Loading PDF...': 'Chargement du PDF...',
        'Trip Number': 'Numéro de voyage',
        Trailer: 'Remorque',
        Route: 'Itinéraire',
        'Route/Ref': 'Réf. de <br> l’itinéraire',
        Origin: 'Origine',
        Destination: 'Destination',
        Carrier: 'Transporteur',
        Reference: 'Référence',
        'Origin Date': "Date d'origine",
        'Dest Date': 'Date de destination',

        'Choose a status': 'Choisissez un statut',
        'Enter event details': "Entrez les détails de l'événement",
        '*Possible conflict. Please choose another door or time': '*Possibilité de conflit. Veuillez choisir une autre porte ou heure',
        Carrier: 'Transporteur',
        'Choose a carrier': 'Choisissez un transporteur',
        'Freight Profile': 'Profil de la cargaison',
        Notes: 'Nota',
        'Add notes': 'Ajouter des remarques',
        Add: 'Ajouter',
        Edit: 'Éditer',
        Update: 'Mise à jour',
        'Actual Time': 'Temps réel',
        'End Actual': 'Réel de fin',
        'Ref #': 'Réf #',
        'Carrier Reference #': 'Référence du transporteur',
        'Acpt Date': "Date d'acceptation",
        'Carrier Accepted Date': "Date d'acceptation du transporteur",
        'Cst. Ref #': 'Clt. Réf',
        'Customer Reference #': 'Numéro de référence client',
        'Carrier Instr': 'Consignes du transporteur',
        'Carrier Instructions': 'Instructions du transporteur',
        'Seal #': 'Scellé#',
        'Trailer #': 'Caravane # FR',
        'Insp Status': "Statut d'inspec",
        'Inspection Staus': "Statut d'inspection",
        Temp: 'Temp',
        degree: 'Degré',
        Actual: 'Réelle', //temperature
        'Please select a carrier': 'Veuillez sélectionner un transporteur',
        Save: 'Sauver',
        Conflicts: 'Conflits',
        'Actual Quantity': 'Quantité réelle',
        'Total Units': 'Unités totales',
        Name: 'Nom',
        'CMIS Udpate': 'CMIS Mettre au courant',
        'Your event has been updated.': 'Votre événement a été mis à jour.',
        'Event Created': 'Événement créé',
        'Event Updated': 'Événement mis à jour',

        'Please enter date in mm/dd/yyy format': 'Veuillez saisir la date au format mm/jj/aaaa',
        History: 'Histoire',
        'Calculated Minutes': 'Minutes calculées',
        'There was an error processing the request': "Une erreur s'est produite lors du traitement de la demande",
        'Password/Password FR': 'Password/Mot de passe ',
        'Username/Username FR': "Username/Nom d'utilisateur",
        'Incorrect username and/or password.': "Nom d'utilisateur et/ou mot de passe incorrect.",
        'Add Vendor': 'Ajouter un fournisseur',
        Vendor: 'Vendeur',
        'Confirmation Number': 'Numéro de confirmationr',
        ' for ': ' pour',
        'Internal Notes': 'Remarques internes',
        'Expected Temperature': 'Température attendue',
        'Actual Temperatre': 'Température réelle',
        ' Reference:': 'Référence:',
        'Customer REF:': 'Client REF:',
        Cancelled: 'Annulé',
        Delivered: 'Livré',
        Scheduled: 'Calendrier',
        'No Show': "N'est pas arrivé",
        'Customer Reference': 'Référence client',
        'Customer Confirmation Number': 'Numéro de confirmation client',
        'Site Administration': 'Administration du site',
        Profile: 'Profil',
        Reporting: 'Rapport',
        'There are unsaved changes. Please save or cancel.': 'Il y a des modifications non enregistrées. Veuillez enregistrer ou annuler.',
        Search: 'Rechercher',
        Admin: 'Administrateur',
        'Edit Event': "Modifier l'événement",
        'First Name': 'Prénom',
        'Last Name': 'nom de famille',
        Locale: 'Lieu',
        View: 'Voir',
        Remove: 'Retirer',
        'Reset Password': 'Réinitialiser le mot de passe',
        'Choose Existing User': 'Choisissez un utilisateur existant',
        'Add Existing User': 'Ajouter un utilisateur existant',
        'User Permissions': 'Autorisation utilisateur',
        'Select Close Time': "Sélectionnez l'heure de fermeture",
        Site: 'Site',
        'Site Description': 'Description du site',
        'Site Name': 'Site name',
        'Booking Interval (m)': 'Intervalle de réservation (m)',
        'Street Address': 'Adresse de rue',
        City: 'Ville',
        State: 'État (ou province)',
        'Select Open Time': "Sélectionner l'heure d'ouverture",
        'Site email': 'E-mail du site',
        'Show Temperature': 'Afficher la température',
        Inactive: 'Inactif',
        Timezone: 'Fuseau Horaire',
        Country: 'Pays',
        Color: 'Couleur',
        Filter: 'Filtre',
        'Reference #': 'Référence #',
        'Customer Ref #': 'Réf client #',
        'Accepted On': 'Accepté le',
        'Search Page By Field': 'Page de recherche',
        'Re- currence Editor': 'Éditeur de récurrence',
        ADD: 'Ajouter',
        'To create a freight profile, please go to Site Managment': 'Pour créer un profil de fret, veuillez vous rendre sur la gestion du site',
        ' Vendor ': 'Vendeur',
        '- Customer Reference ': '- Référence client',
        'Door Name': 'Nom de la porte',
        Label: 'Étiquette',
        Close: 'Fermer',
        'Add Existing Carrier': 'Ajouter un opérateur existant',
        'Choose Existing Carrier': 'Choisissez un opérateur existant',
        Cell: 'Téléphone ',
        Department: 'Département',
        Description: 'Description',
        'Maximum Minutes': 'Minutes maximales',
        'This field is required': 'Ce champ est requis',
        'Add Existing Freight Profile': 'Ajouter un profil de cargaison existant',

        'Inspection Status': "Statut d'inspection",
        Hold: 'Tenir',
        Pass: 'Passer',
        'Include Recurrence': 'Inclure la récurrence',
        Clear: 'Réinitialiser',
        'Vendor Name': 'Nom du Vendeur',
        'Schedule End': "Fin de l'horaire",
        'Schedule Start': "Début de l'horaire",
        'Results:': 'Résultats:',
        'No Results': 'aucun résultat',
        'No doors available. Add a door.': 'Aucune porte disponible. Ajouter une porte.',
        'No carriers available. Add a carrier.': 'Aucun support disponible. Ajoutez un transporteur.',
        'Run Report': "Rapport d'exécution",
        'Report Name': 'Nom du rapport',
        New: 'Nouveau',
        'Unsaved changes...': 'Modifications non enregistrées...',
        'Event Status': "Statut de l'événement",
        'End Date': 'Date de fin',
        'Start Date': 'Date de début',
        'Event Type': "Type d'événement",
        'Please update your password': 'Veuillez mettre à jour votre mot de passe',
        'Recurrence Editor': 'Éditeur de récurrence',
        'Add Door': 'Ajouter une porte',
        'Choose Existing Freight Profile': 'Choisissez le profil de cargaison existant',
        'Please add a vendor through Site Management': 'Veuillez ajouter un fournisseur via la gestion du site',
        'A TRUSTED APPROACH TO SUPPLY CHAIN SOLUTIONS': "UNE APPROCHE DE CONFIANCE POUR LES SOLUTIONS DE CHAÎNE D'APPROVISIONNEMENT",
        'By logging in, you agree to our': 'En vous connectant, vous acceptez nos',
        'Privacy Policy': 'politique de confidentialité',
        and: 'et',
        'Terms of Use': "nos conditions d'utilisation",
        'LOG IN': 'CONNEXION',
        'Need Assistance?': "Besoin d'assistance?",
        'Contact us here': 'Contactez-nous ici',
        'Moved By Intelligence': 'Mû par intelligence',
        'New Password': 'Nouveau mot de passe',
        'Confirm Password': 'Confirmez le mot de passe',
      },
    },
  },
  lng: 'en',
  fallbackLng: 'en',
});

// L10n.load({
//   'fr-FR': {
//     datepicker: {
//       today: "Aujourd'hui"
//     },
//     dialog: {
//       close: 'Fermer'
//     },
//     daterangepicker: {
//       applyText: 'Appliquer',
//       cancelText: 'Annuler',
//       "customRange": "Plage personnalisée",
//       "days": "Jours"
//     },
//     numerictextbox:{
//       incrementTitle:"Augmenter la valeur",
//       decrementTitle:"Diminuer la valeur"
//     },
//     'grid': {
//       Empty: 'No hay registros que mostrar',
//       EmptyRecord: 'Aucun enregistrement à afficher',
//       InvalidFilterMessage: 'Datos de filtro inválidos',
//       GroupDropArea:
//         'Arrastra un encabezado de columna aquí para agrupar su columna',
//       UnGroup: 'Haga clic aquí para desagrupar',
//       GroupDisable: 'La agrupación está deshabilitada para esta columna',
//       FilterbarTitle: 'celda de barra de filtro',
//       EmptyDataSourceError:
//         'DataSource no debe estar vacío en la carga inicial ya que las columnas se generan desde dataSource en la Cuadrícula de columnas de AutoGenerate',
//       Print: 'Impresión',
//       PdfExport: 'Exportar a PDF',
//       WordExport: 'Exportar a Word',
//       CsvExport: 'Exportar a CSV',
//       Search: 'Buscar',
//       Columnchooser: 'Columnas',
//       Save: 'Grabar',
//       Item: 'registro',
//       Items: 'registros',
//       EditOperationAlert:
//         'No hay registros seleccionados para la operación de edición',
//       DeleteOperationAlert:
//         'No hay registros seleccionados para la operación de eliminación',
//       SaveButton: 'Grabar',
//       OKButton: 'Confirmar',
//       CancelButton: 'Cancelar',
//       EditFormTitle: 'Detalles de',
//       AddFormTitle: 'Añadir nuevo registro',
//       BatchSaveConfirm: '¿Seguro que quieres guardar los cambios?',
//       BatchSaveLostChanges:
//         'Los cambios no guardados se perderán. Estás seguro de que quieres continuar?',
//       ConfirmDelete: '¿Seguro que quieres borrar el registro?',
//       CancelEdit: '¿Seguro que quieres cancelar los cambios?',
//       ChooseColumns: 'Elija Columna',
//       SearchColumns: 'columnas de búsqueda',
//       Matchs: 'No se encontraron coincidencias',
//       FilterButton: 'Filtrar',
//       ClearButton: 'Cancelar',
//       StartsWith: 'Comienza con',
//       EndsWith: 'Termina con',
//       Contains: 'Contiene',
//       Equal: 'Igual',
//       NotEqual: 'No es igual',
//       IsNull: "Est nul",
//       NotNull: "Est non nulle",
//       IsEmpty: "Vide",
//       Like: "comme",
//       IsNotEmpty: "N'est pas vide",
//       NotEmpty: "Pas vide",
//       NotContains: "Ne contient pas",
//       NotEndsWith: "Ne se termine pas par",
//       NotStartsWith: "Ne commence pas par",
//       LessThan: 'Menos que',
//       LessThanOrEqual: 'Menor o igual',
//       ChooseDate: 'Elija una fecha',
//       EnterValue: 'Introduzca el valor',
//       Copy: 'Copiar',
//       Group: 'Agrupar por esta columna',
//       Ungroup: 'Desagrupar por esta columna',
//       autoFitAll: 'Ajustar automáticamente todas las columnas',
//       autoFit: 'Ajustar automáticamente esta columna',
//       Export: 'Exportar',
//       FirstPage: 'Primera página',
//       LastPage: 'Última página',
//       PreviousPage: 'Pagina anterior',
//       NextPage: 'Siguiente página',
//       SortAscending: 'Orden ascendente',
//       SortDescending: 'Orden descendiente',
//       EditRecord: 'Editar registro',
//       DeleteRecord: 'Eliminar el registro',
//       FilterMenu: 'Filtro',
//       SelectAll: 'Seleccionar todo',
//       Blanks: 'Espacios en blanco',
//       FilterTrue: 'Verdadero',
//       FilterFalse: 'Falso',
//       NoResult: 'No se encontraron coincidencias',
//       ClearFilter: 'Borrar filtro',
//       NumberFilter: 'Filtros numéricos',
//       TextFilter: 'Filtros de texto',
//       DateFilter: 'Filtros de fecha',
//       DateTimeFilter: 'Filtros de fecha',
//       MatchCase: 'Coincidencia',
//       Between: 'Entre',
//       CustomFilter: 'Filtro personalizado',
//       CustomFilterPlaceHolder: 'Introduzca el valor',
//       CustomFilterDatePlaceHolder: 'Elija una fecha',
//       AND: 'Y',
//       OR: 'O',
//       ShowRowsWhere: 'Mostrar filas donde:',
//       'Add': 'Ajouter',
//       'Edit': 'Modifier',
//       'Delete': 'Supprimer',
//       'Update': 'Mettre à jour',
//       'Cancel': 'Annuler',
//       'Excelexport': "Exporter vers Excel",
//       'filterMenuOperatorContains': "Contient",
//       'filterMenuOperatorStartsWith': "Commence par",
//       'filterMenuOperatorEndsWith': "Finit par",
//       'currentPageInfo': '{0} sur {1} pages',
//       'totalItemsInfo': '( {0} éléments )',
//       'pagerDropDown': 'Éléments par page',
//       'True': 'Vrai',
//       'False': 'Faux',
//       'GreaterThan': 'Supérieur à',
//       'GreaterThanOrEqual': 'Supérieur ou égal à',
//     },
//     pager: {
//       currentPageInfo: '{0} de {1} pages',
//       totalItemsInfo: '({0} enregistrements)',
//       firstPageTooltip: 'Aller à la première page',
//       lastPageTooltip: 'Aller à la dernière page',
//       nextPageTooltip: 'Aller à la page suivante',
//       previousPageTooltip: 'Revenir à la page précédente',
//       nextPagerTooltip: 'Au groupe de pages suivant',
//       previousPagerTooltip: 'Au groupe de pages précédent',
//       pagerDropDown: 'Enregistrements par page',
//       pagerAllDropDown: 'Enregistrements',
//       All: 'Tout',
//     },
//   }
// });

L10n.load(fr);
loadCldr(gregorian, currencies, numbers, timeZoneNames);
export default i18n;
