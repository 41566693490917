// import { ScheduleComponent, Day, Week, WorkWeek, Month, Agenda, Inject } from '@syncfusion/ej2-react-schedule';
import React, { useEffect, useState, useRef, useContext, useCallback } from 'react';
import { DatePickerComponent, Inject, MaskedDateTime } from '@syncfusion/ej2-react-calendars';
import { ColumnDirective, ColumnsDirective, CommandColumn, GridComponent, Page, Sort, Filter, ExcelExport, Toolbar, Edit } from '@syncfusion/ej2-react-grids';
//import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { TextBoxComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import './RateCall.css';
import agent from '../../api/agent';
//import { IoMdRefresh } from 'react-icons/io';
import TokenProvider, { logOutUser } from '../TokenProvider/TokenProvider';
import { Card, Container, Row, Col } from 'react-bootstrap';
//import { Tab, Nav } from 'react-bootstrap';
//import { TabComponent, TabItemDirective, TabItemsDirective } from '@syncfusion/ej2-react-navigations';
import { useTranslation } from 'react-i18next';
import AppContext from '../Theme/AppContext';
import GroupeLightLogo from '../../images/Groupe-light-logo.png';
import GroupeDarkLogo from '../../images/Groupe-dark-logo.png';
import { showToast } from '../Theme/Utils';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import ButtonLoading from '../Loading/ButtonLoading';
import Loading from '../Loading/Loading';
import { Animation } from '@syncfusion/ej2-base';

const RateCallComp = () => {
  const { t, i18n } = useTranslation();
  const moduleName = 'RateCall';
  const { theme, userInfo, localeState } = useContext(AppContext);

  const { token, setToken } = TokenProvider();
  const cbToken = token.token;
  const username = token.username;
  const navigate = useNavigate();

  const [resultRateCallList, setResultRateCallList] = useState([]);

  const [stateDDList, setStateDDList] = useState([]);
  const [oriAreaDDList, setOriAreaDDList] = useState([]);
  const [destAreaDDList, setDestAreaDDList] = useState([]);

  const [rateCallInputList, setRateCallInputList] = useState([]);
  const [loadingTableList, setLoadingTableList] = useState(false);

  const [customerDDList, setCustomerDDList] = useState([]);
  const [loadingCustomerList, setLoadingCustomerList] = useState(true);
  const [customerDDText, setCustomerDDText] = useState(null);
  const [customerDDValue, setCustomerDDValue] = useState(null);
  const [resultErrorMsg, setResultErrorMsg] = useState("");
  const [maxHeight, setMaxHeight] = useState(0);
  const [isNTDCust, setIsNTDCust] = useState(false);
  const [placeKey, setPlaceKey] = useState(0);

  const customerDDRef = useRef(null);
  const resultEmptyGridRef = useRef(null);

  const formRef = useRef(null);
  const skuGridRef = useRef(null);
  const containerRef = useRef(null);

  const validateForm = (values) => {
    const canadianPostalRegex = /^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$|^[A-Za-z]\d[A-Za-z]\d[A-Za-z]\d$/;
    const errors = {};
    if (!values.originPostal && (!values.originState || !values.originCity)) {
      errors.originPostal = '*Postal OR Provice/City Required';
    }
    if (!values.destinationpostal && (!values.destState || !values.destCity)) {
      errors.destinationpostal = '*Postal OR Provice/City Required';
    }
    if (values.originPostal && !canadianPostalRegex.test(values.originPostal.trim())) {
      errors.originPostal = '*Invalid Canadian Postal Code';
    }
    if (values.destinationpostal && !canadianPostalRegex.test(values.destinationpostal.trim())) {
      errors.destinationpostal = '*Invalid Canadian Postal Code';
    }
    if (!values.totalWeightInput || values.totalWeightInput <= 0) {
      //&& rateCallInputList.length === 0
      errors.totalWeightInput = '*Total Weight is required';
      // errors.totalWeightInput = '*Total Weight is required or At least one item in the SKU Table.';
    }
    return errors;
  };

  const { values, handleChange, handleBlur, handleSubmit, handleReset, errors, touched, setValues, isValid } = useFormik({
    initialValues: {
      originPostal: '',
      originState: null,
      originCity: null,
      destinationpostal: '',
      destState: null,
      destCity: null,
      totalWeightInput: 0,
    },
    validate: validateForm,
    onSubmit: (values) => {
      setLoadingTableList(true);
      let params = {};
      let gridSKUData = rateCallInputList.map((item) => {
        return {
          skuNumber: String(item.id),
          itemNumber: item['oracleItem#'],
          unitLength: item.Length,
          unitWidth:  (item.Width),
          unitHeight: (item.Height),
          skU_Qty: item['Quantity'],
          skU_WeightInLBS: item.skU_WeightInLBS,
          totalWeight: item.TotalWeight,
        };
      });
      params = { gridSKUData, ...values };
      params.totalWeight = params.totalWeightInput;
      params.totalWeightInput = undefined;
      getLowestRateCallListAPI(params);
    },
  });

  const setErrorMessageResult = (emptyErrorMessage) => {
      setTimeout(()=>{
        var emptyRow = document.querySelector('#ratecallresult-grid-nopagination .e-emptyrow');
        if (emptyRow) {
            emptyRow.cells[0].innerText = emptyErrorMessage || t("No records to display");
        }
      },500)
  }

  const getLowestRateCallListAPI = (params) => {
    try{
      setResultRateCallList([]);
      setErrorMessageResult()
      agent
        .getAllLowestRateCallList(params, { customer: customerDDValue })
        .then((response) => {
          let emptyErrorMessage;
          if(response.data.carrierRates && response.data.carrierRates.length > 0){
            setResultRateCallList(response.data.carrierRates);
          }
          else{
            if(response.data?.errorMessage){
              emptyErrorMessage = t(response.data.errorMessage);
              showToast(t(response?.data.errorMessage), "error");
            }
            else if ( typeof(response?.data) === "string"  &&response?.data?.includes('City/Province postal code not found')) {
              let msg = "City/Province postal code not found: ";
              const result = response.data.substring(msg.length).trim();
              showToast(t(msg) + result, "error");
              emptyErrorMessage = t(msg) + result;
            }
            setErrorMessageResult(emptyErrorMessage);
          }
          setLoadingTableList(false);
        })
        .catch((error) => {
          setLoadingTableList(false);
          handlAuthorization(error);
        });
    }
    catch(e){
      console.log("e ", e);
    }
  };

  const handleChangeInput = (name, value) => {
    handleChange({ target: { name, value } });
    if (name === 'originState' || name === 'destState') {
      getAreaDropdownList(value, name);
    }
  };

  const handlAuthorization = (error) => {
    console.log(error);
    if (error.code === "ERR_NETWORK") {
      showToast(t("No response from server."), 'error');
    }
    else if (error?.response?.status === 401) {
      showToast(t('Your session has expired. Please login again.'), 'error');
      logOutUser();
    } else if (error?.response?.status === 403) {
      //showToast(t("You do not have access to ") + moduleName + t(", please contact your account manager to gain access."), 'error');
      navigate('/access-denied', { state: { moduleName } });
    }
  };

  useEffect(() => {
    getCustomerList().then((data) => {
      if(data){
        getStateDropdownList(data);
      }
    });
  }, []);

  useEffect(()=>{
    if(i18n.language){
        setPlaceKey(prev=>prev+1);
    }
},[i18n.language])

  const getCustomerList = async () => {
    try {
      setLoadingCustomerList(true);
      let res = await agent.getCustomerListRateCall();
      if (!(res.data.length > 0)) {
        navigate('/access-denied', { state: { moduleName } });
        return;
      }
      let list = res.data.map((item) => {
        const text = item.split('-')[0];
        return { text, value: item };
      });
      const hasNTD = list.some(item => item.value.toLowerCase().includes('ntd'));
      if (!hasNTD) {
        navigate('/access-denied', { state: { moduleName } });
        return;
      }
      list.sort((a, b) => a.text.localeCompare(b.text));
      setCustomerDDList(list);
      setCustomerDDText(list[0].text);
      setCustomerDDValue(list[0].value);
      if(list[0].value.toLowerCase().includes('ntd')){
        setIsNTDCust(true);
      }
      setLoadingCustomerList(false);
      return res.data[0];
    } catch (err) {
      // setLoadingCustomerList(false);
      handlAuthorization(err);
    }
  };

  const handlChangeCustomerDD = (e) => {
    let val = e.target.value;
    setCustomerDDText(val);
    setCustomerDDValue(customerDDList.find((item) => item.text === val).value);
    if(val.toLowerCase().includes('ntd')){
      setIsNTDCust(true);
    }
    else{
      setIsNTDCust(false);
    }
  };

  const getStateDropdownList = (customer) => {
    const defaultParams = { countryCode: 'CA', customer };
    agent
      .getStateByCountryCodeList(defaultParams)
      .then((response) => {
        let states = Object.keys(response.data).map((key) => ({
          text: response.data[key],
          value: key,
        }));
        states.sort((a, b) => a.text.localeCompare(b.text));
        setStateDDList(states);
      })
      .catch((error) => {
        handlAuthorization(error);
      });
  };

  const getAreaDropdownList = (stateCode, name) => {
    const tenantName = 'NTD';
    agent
      .getAreaByStateCodeList({ stateCode, tenantName })
      .then((response) => {
        const area = response.data.map((x) => ({
          text: x.areaName,
          value: x.areaName,
          // value: x.areaID,
        }));
        if (name === 'originState') {
          setOriAreaDDList(area);
        } else {
          setDestAreaDDList(area);
        }
      })
      .catch((error) => {
        handlAuthorization(error);
      });
  };

  const calculateTotalWeight = (updatedList) => {
    let totalWeight = 0;
    updatedList.forEach((item) => {
      const weight = Number(item.TotalWeight);
      if (!isNaN(weight)) {
        totalWeight += weight;
      }
    });
    setValues({ ...values, totalWeightInput: parseInt(totalWeight) });
  };

  const handleActionComplete = async (args) => {
    // if (args.requestType === 'save') {
    //     const editedRecord = args.data;
    //     const index = rateCallInputList.findIndex(item => item.id === editedRecord.id);
    //     if (index !== -1) {
    //         const updatedList = [...rateCallInputList];
    //         updatedList[index] = editedRecord;
    //         setRateCallInputList(updatedList);
    //     }
    // }
  };

  const handleActionBegin = async (args) => {
    if (args.requestType === 'save') {
      const updatedList = [...rateCallInputList];

      skuGridRef.current.showSpinner();
      if (args.action === 'add') {
        const highestId = updatedList.reduce((maxId, item) => Math.max(maxId, item.id), 0);
        const newRecord = { ...args.data, id: highestId + 1 };
        updatedList.push(newRecord);

        try {
          const response = await agent.getOracleItem({
            customer: customerDDValue,
            itemNumber: newRecord['oracleItem#'],
          }).catch((e) => {
            args.cancel = true;
            showToast(t('Item Not Found'), "error");
            handlAuthorization(e)
            return;
          });
          const updatedRecord = {
            ...newRecord,
            Length: response.data.length,
            Width: response.data.width,
            Height: response.data.height,
            skU_WeightInLBS: parseInt(response.data.weight),
            TotalWeight: parseInt(Number(response.data.weight) * Number(newRecord.Quantity)),
          };

          updatedList[updatedList.length - 1] = updatedRecord;
          setRateCallInputList(updatedList);
          calculateTotalWeight(updatedList);
          skuGridRef.current.hideSpinner();
        } catch (error) {
          console.error('Error fetching Oracle Item:', error);
          showToast(t('Item Not Found'), "error");
          args.cancel = true;
          skuGridRef.current.deleteRecord();
          skuGridRef.current.hideSpinner();
          handlAuthorization(error)
          return;
        }
      } else {
        const existingItem = updatedList.find((item) => item.id === args.data.id);
        if (existingItem) {
          Object.assign(existingItem, args.data);
        }

        try {
          const response = await agent
            .getOracleItem({
              customer: customerDDValue,
              itemNumber: existingItem['oracleItem#'],
            })
            .catch((e) => {
              args.cancel = true;
              showToast(t('Item Not Found'), "error");
              handlAuthorization(e)
              return;
            });
          const updatedRecord = {
            ...existingItem,
            Length: response.data.length,
            Width: response.data.width,
            Height: response.data.height,
            UnitWeight: response.data.weight,
            TotalWeight: parseInt(Number(response.data.weight) * Number(existingItem.Quantity)),
          };

          const index = updatedList.findIndex((item) => item.id === args.data.id);
          updatedList[index] = updatedRecord;
          setRateCallInputList(updatedList);
          calculateTotalWeight(updatedList);
          skuGridRef.current.hideSpinner();
        } catch (error) {
          console.error('Error fetching Oracle Item:', error);
          showToast(t('Item Not Found'), "error");
          args.rowData = null;
          args.cancel = true;
          skuGridRef.current.hideSpinner();
          handlAuthorization(error)
          return;
        }
      }
    } else if (args.requestType === 'delete') {
      const updatedList = rateCallInputList.filter((item) => item.id !== args.data[0].id);
      setRateCallInputList(updatedList);
      calculateTotalWeight(updatedList);
    }
  };

  const handleSubmitLookup = () => {
    handleSubmit();
  };

  const loaderRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    if (loadingCustomerList) {
      let animation = new Animation();
      animation.animate(loaderRef.current, { name: 'FadeOut', duration: 800 });
    } else {
      let animation = new Animation();
      animation.animate(contentRef.current, { name: 'FadeIn', duration: 800 });
    }
  }, [loadingCustomerList]);

  // function gridLoaded(args) {
  //     dataGrid.current.refreshHeader();
  //     // dataGrid.current.autoFitColumns();
  // }

  return (
    <div>
    {loadingCustomerList ? (
      <div ref={loaderRef} style={{ height: "100vh" }} className="my-custom-loader-container">
        <Loading />
      </div>
    ) : (
    <div  ref={contentRef} className="container-flex" style={{ padding: '1.5rem 0rem 4rem 1.5rem' }}>
      <div className="chart-container mt-4" style={{ padding: '0rem 4rem' }}>
        <div className="mt-4 flex-center" style={{ flexDirection: 'column' }}>
            {isNTDCust &&(
            <img
              src={theme === 'dark' ? GroupeDarkLogo : GroupeLightLogo}
              alt="logo"
              style={{ maxHeight: '200px', maxWidth: '300px', alignContent: 'center', overflow: 'hidden' }}
            />
          )}
          <h1 className="my-custom-text main-header-text">{t('Cedric Millar Rate Call')}</h1>
          {customerDDList && customerDDList.length > 1 &&
            <div className='flex-center' style={{ maxWidth: "100%", alignItems: 'end', flexWrap: 'wrap', gap: '1rem' }} id='dashboard-form'>
              <div className="my-custom-text">
                <span className="form-field-label">
                  {t('Customer Name')}
                </span>
                <div className="">
                  <div className='daterangepicker-control-section'>
                    <DropDownListComponent
                      required={true}
                      onChange={handlChangeCustomerDD}
                      ref={customerDDRef}
                      value={customerDDText}
                      placeholder={t('Customer Name')}
                      floatLabelType="Never"
                      cssClass="e-medium"
                      className='e-field'
                      dataSource={customerDDList}
                      id="dropdown"
                      data-msg-containerid="errorForDropDown"
                    />
                  </div>
                </div>
              </div>
              <div>
                <button
                  //    onClick={() => { if (customerDDValue) { getAllTableData(customerDDValue)}}}
                  disabled={loadingTableList}
                  className="my-custom-btn" type="submit" >{t('Search')}</button>
              </div>
            </div>}
        </div>

        <Container style={{ maxWidth: '100%' }} ref={containerRef}>
          <Row style={{ gap: '0rem' }}>
            <Col xs={12} lg={(isNTDCust ? 6 : 12)} className="pd-0 matching-heights">
                <div ref={formRef} style={{ width: '100%' }} className="rate-call-form mt-4">
                  <form onSubmit={handleSubmit}>
                    <Row style={{ marginBottom: '1.5rem' }}>
                      <Col xs={12} lg={2}>
                        <div className="flex-center my-custom-text required" style={{ justifyContent: 'start', height: '100%' }}>
                          {t('Origin')}
                        </div>
                      </Col>
                      <Col xs={12} lg={3} className="pd-0">
                        <TextBoxComponent
                          type="text"
                          className="form-control form-control-sm"
                          placeholder={t('Postal Code')}
                          name="originPostal"
                          onChange={(e) => {
                            handleChangeInput('originPostal', e.target.value);
                          }}
                          key={"originPostal"+placeKey}
                          onBlur={handleBlur}
                          value={values.originPostal}
                        />
                        {touched.originPostal && errors.originPostal && <div className="e-error">{t(errors.originPostal)}</div>}
                      </Col>
                      <Col xs={12} lg={1} className="pd-0">
                        <div className="flex-center my-custom-text" style={{ height: '100%' }}>
                          {t('OR')}
                        </div>
                      </Col>
                      <Col xs={12} lg={3}>
                        <DropDownListComponent
                          allowFiltering={true}
                          cssClass="e-small"
                          filterType={"Contains"}
                          className="form-control form-control-sm"
                          style={{height:"30px"}}
                          dataSource={stateDDList}
                          fields={{ text: 'text', value: 'value' }}
                          placeholder={t('Province')}
                          name="originState"
                          key={"originState"+placeKey}
                          onBlur={() => {
                            handleBlur({ target: { name: 'originState' } });
                          }}
                          onChange={(e) => {
                            handleChangeInput('originState', e.target.value);
                            if (values.originCity != null) {
                              handleChangeInput('originCity', '');
                            }
                          }}
                          value={values.originState}
                        />
                        {touched.originState && errors.originState && <div className="e-error">{t(errors.originState)}</div>}
                      </Col>
                      <Col xs={12} lg={3}>
                        <DropDownListComponent
                          allowFiltering={true}
                          cssClass="e-small"
                          filterType={"Contains"}
                          className="form-control form-control-sm"
                          style={{height:"30px"}}
                          dataSource={oriAreaDDList}
                          fields={{ text: 'text', value: 'value' }}
                          placeholder={t('City')}
                          key={"originCity"+placeKey}
                          enabled={values.originState === null || values.originState === '' ? false : true}
                          name="originCity"
                          onBlur={() => {
                            handleBlur({ target: { name: 'originCity' } });
                          }}
                          onChange={(e) => handleChangeInput('originCity', e.target.value)}
                          value={values.originCity}
                        />
                        {touched.originCity && errors.originCity && <div className="e-error">{t(errors.originCity)}</div>}
                      </Col>
                    </Row>

                    <Row style={{ marginBottom: '1.5rem' }}>
                      <Col xs={12} lg={2}>
                        <div className="flex-center my-custom-text required" style={{ justifyContent: 'start', height: '100%' }}>
                          {t('Destination')}
                        </div>
                      </Col>
                      <Col xs={12} lg={3} className="pd-0">
                        <TextBoxComponent
                          type="text"
                          className="form-control form-control-sm"
                          placeholder={t('Postal Code')}
                          name="destinationpostal"
                          key={"destinationpostal"+placeKey}
                          onChange={(e) => {
                            handleChangeInput('destinationpostal', e.target.value);
                          }}
                          onBlur={handleBlur}
                          value={values.destinationpostal}
                        />
                        {touched.destinationpostal && errors.destinationpostal && <div className="e-error">{t(errors.destinationpostal)}</div>}
                      </Col>
                      <Col xs={12} lg={1} className="pd-0">
                        <div className="flex-center my-custom-text" style={{ height: '100%' }}>
                          {t("OR")}
                        </div>
                      </Col>
                      <Col xs={12} lg={3}>
                        <DropDownListComponent
                          allowFiltering={true}
                          cssClass="e-small"
                          filterType={"Contains"}
                          className="form-control form-control-sm"
                          style={{height:"30px"}}
                          dataSource={stateDDList}
                          fields={{ text: 'text', value: 'value' }}
                          placeholder={t('Province')}
                          key={"destState"+placeKey}
                          name="destState"
                          onBlur={() => {
                            handleBlur({ target: { name: 'destState' } });
                          }}
                          onChange={(e) => {
                            handleChangeInput('destState', e.target.value);
                            if (values.destCity != null) {
                              handleChangeInput('destCity', '');
                            }
                          }}
                          value={values.destState}
                        />
                        {touched.destState && errors.destState && <div className="e-error">{t(errors.destState)}</div>}
                      </Col>

                      <Col xs={12} lg={3}>
                        <DropDownListComponent
                          allowFiltering={true}
                          cssClass="e-small"
                          filterType={"Contains"}
                          style={{height:"30px"}}
                          className="form-control form-control-sm"
                          dataSource={destAreaDDList}
                          fields={{ text: 'text', value: 'value' }}
                          placeholder={t('City')}
                          key={"destCity"+placeKey}
                          enabled={values.destState === null || values.destState === '' ? false : true}
                          name="destCity"
                          onBlur={() => {
                            handleBlur({ target: { name: 'destCity' } });
                          }}
                          onChange={(e) => handleChangeInput('destCity', e.target.value)}
                          value={values.destCity}
                        />
                        {touched.destCity && errors.destCity && <div className="e-error">{t(errors.destCity)}</div>}
                      </Col>
                    </Row>
                    {/* old end  */}
                  </form>

                  <Row>
                    <Col xs={12} lg={2}>
                      <div className="flex-center my-custom-text" style={{ flexWrap: 'wrap', justifyContent: 'start', height: '100%' }}>
                        <div>{t('Total')}</div>&nbsp;<div><div style={{ display: 'flex' }} className='required'>{t('Weight(LB)')}</div></div>
                      </div>
                    </Col>
                    <Col xs={12} lg={3} className="pd-0">
                      <div>
                        <div>
                          <NumericTextBoxComponent
                            min={0}
                            max={10000}
                            decimals={0}
                            format="N"
                            className="e-field form-control form-control-sm"
                            placeholder={t('Enter Total Weight(LB)')}
                            name="totalWeightInput"
                            key={"totalWeightInput"+placeKey}
                            value={values.totalWeightInput}
                            onChange={(e) => {
                              handleChangeInput('totalWeightInput', e.value);
                            }}
                            onBlur={handleBlur}
                          />
                          {touched.totalWeightInput && errors.totalWeightInput && <span className="e-error">{t(errors.totalWeightInput)}</span>}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: '1.5rem' }}>
                    <Col xs={12} lg={12}>
                      <div className="mt-1 light-info-text"> {t('**SKU Total weight will override the total weight entered here, if available.')}</div>
                    </Col>
                  </Row>
                  {isNTDCust &&(
                  <Row style={{ marginBottom: '1.5rem' }}>
                    <Col xs={12} lg={12}>
                      <div className="mt-1 ">
                        <div className="my-custom-text">
                          <div className="" style={{ display: 'flex', flexWrap: 'wrap' }}>
                            <div>
                              <b> {t('Top 3 Oracle Item #')+" :"}</b>
                            </div>
                            <div style={{ margin: '0 2px' }}>{t('Passenger Tires - 98294 |')} </div>
                            <div style={{ margin: '0 2px' }}> {t('Industrial Tires - 94684.1 |')}</div>
                            <div style={{ margin: '0 2px' }}> {t('Medium Truck Tires - 89297.1')}</div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  )}
                  <Row>
                    <Col xs={12} lg={6}>
                      <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                        <button style={{ display: 'flex' }} onClick={handleSubmitLookup} disabled={!isValid} className="my-custom-btn">
                          {loadingTableList && <ButtonLoading style={{ marginRight: '6px' }} />}
                          {t('LookUp Rate')}
                        </button>
                        <div
                          onClick={() => {
                            handleReset();
                            setRateCallInputList([]);
                            setLoadingTableList(true);
                            setTimeout(()=>{
                              setResultRateCallList([]);
                              setLoadingTableList(false);
                            },100);
                          }}
                          style={{ width: 'max-content', marginLeft: '1rem' }}
                          className="btn btn-outline-secondary active flex-center"
                          type="button"
                        >
                          {t('Reset')}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
            </Col>

            {isNTDCust && (
            <Col xs={12} lg={6} className="pd-0 matching-heights" >
             <div style={{height: "94%",}}> 
                <GridComponent
                  height={"100%"}
                  style={{ marginLeft: '1rem'}}
                  ref={skuGridRef}
                  id="rate-call-input-table"
                  className="syncf-grid-comp mt-4 my-custom-toolbar-table no-pagination-table"
                  dataSource={rateCallInputList} //[]
                  allowPaging={false}
                  allowSorting={true}
                  allowFiltering={false}
                  // pageSettings={{ pageSize: 15 }}
                  textWrapSettings={{ wrapMode: 'Header' }}
                  editSettings={{ allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Normal' }}
                  toolbar={['Add', 'Edit', 'Delete', 'Update', 'Cancel']}
                  actionComplete={handleActionComplete}
                  actionBegin={handleActionBegin}
                  loadingIndicator={{ cssClass: 'custom-loader', position: 'Center' }} // remove the indicatorType property
                >
                  <ColumnsDirective>
                    <ColumnDirective field="id" headerText="id" textAlign="Center" visible={false} />
                    <ColumnDirective
                      field="oracleItem#"
                      headerText={t("Oracle<br>Item #")}
                      isPrimaryKey={true}
                      headerTemplate={(props) => (
                        <div style={{ textAlign: 'center' }}>
                          <div> {t('Oracle')}</div>
                          <div> {t('Item #')}</div>
                        </div>
                      )}
                      headerTextAlign="Center"
                      textAlign="Center"
                      disableHtmlEncode={false}
                      width="100"
                      validationRules={{ required: true, minLength: 3 }}
                    />
                    <ColumnDirective
                      field="Quantity"
                      headerText={t("Quantity")}
                      editType="numericedit"
                      format="N"
                      headerTextAlign="Center"
                      width="100"
                      textAlign="Center"
                      validationRules={{ required: true, min: 1, max: 10000 }}
                    />
                    <ColumnDirective
                      field="Length"
                      disableHtmlEncode={false}
                      headerText="L"
                      // headerText={localeState === "fr" ? 'L' : 'L'}
                      headerTextAlign="Left"
                      width="40"
                      textAlign="Left"
                      allowEditing={false}
                    />
                    <ColumnDirective
                      field="Width"
                      disableHtmlEncode={false}
                      headerText={localeState === "fr" ? 'I' : 'W'}
                      headerTextAlign="Left"
                      width="40"
                      textAlign="Left"
                      allowEditing={false}
                    />
                    <ColumnDirective
                      field="Height"
                      disableHtmlEncode={false}
                      headerText="H"
                      // headerText={localeState === "fr" ? 'H' : 'H'}
                      headerTextAlign="Left"
                      width="40"
                      textAlign="Left"
                      allowEditing={false}
                    />
                    <ColumnDirective
                      field="skU_WeightInLBS"
                      headerText={t("Unit Weight(LB)")}
                      format={'dd'}
                      headerTemplate={(props) => (
                        <div style={{ textAlign: 'center' }}>
                          <div> {t('Unit')}</div>
                          <div> {t('Weight(LB)')}</div>
                        </div>
                      )}
                      headerTextAlign="Center"
                      disableHtmlEncode={false}
                      width="100"
                      textAlign="Center"
                      allowEditing={false}
                    />
                    <ColumnDirective
                      field="TotalWeight"
                      headerText={t("Total Weight(LB)")}
                      headerTemplate={(props) => (
                        <div style={{ textAlign: 'center' }}>
                          <div>{t('Total')}</div>
                          <div>{t('Weight(LB)')}</div>
                        </div>
                      )}
                      headerTextAlign="Center"
                      disableHtmlEncode={false}
                      width="100"
                      textAlign="Center"
                      allowEditing={false}
                    />
                  </ColumnsDirective>
                  <Inject services={[Page, Edit, CommandColumn, Sort, Filter, ExcelExport, Toolbar]} />
                </GridComponent>
            </div>
            </Col>
            )}
          </Row>
          </Container>
          </div>

      <div className="chart-container mt-4" style={{ padding: '0rem 4rem' }}>
        <div>
          <h1 className="mt-4 my-custom-text main-header-text flex-center">{t('Results')}</h1>
          <div>
            {!loadingTableList ? (
              <div>
                {resultRateCallList.length > 0 ? 
              <GridComponent
                className="syncf-grid-comp mt-4"
                id="ratecallresult-grid-pagination"
                height={200}
                dataSource={resultRateCallList}
                allowPaging={true}
                allowSorting={true}
                allowFiltering={true}
                pageSettings={{ pageSize: 15 }}
                textWrapSettings={{ wrapMode: 'Header' }}
                editSettings={{ allowEditing: false, allowDeleting: false }}
                sortSettings={{
                  columns: [{ field: 'rank', direction: 'Ascending' }],
                }}
                filterSettings={{
                  type: 'Menu',
                }}
              >
                <ColumnsDirective>
                  <ColumnDirective field="id" headerTextAlign="Center" headerText="id" width="0" visible={false} />
                  <ColumnDirective
                    field="rank" //"rowId"
                    headerText={t('Row #')}
                    headerTextAlign="Center"
                    disableHtmlEncode={false}
                    width="100"
                    textAlign="Center"
                  />
                  <ColumnDirective
                    disableHtmlEncode={false}
                    field="carrierSCAC" //"CarrierName"
                    headerText={t('Carrier Name')}
                    headerTemplate={(props) => (
                      <div style={{ textAlign: 'center' }}>
                        <div> {t('Carrier')}</div>
                        <div> {t('Name')}</div>
                      </div>
                    )}
                    headerTextAlign="Center"
                    width="160"
                    textAlign="Center"
                  />
                  <ColumnDirective field="mode" disableHtmlEncode={false} headerText={t('Mode')} headerTextAlign="Center" width="80" textAlign="Center" />
                  <ColumnDirective
                    field="transitTime" //"transitDays"
                    // headerText={t('Transit Days(1)')}
                    disableHtmlEncode={false}
                    headerTemplate={(props) => (
                      <div style={{ textAlign: 'center' }}>
                        <div> {t('Transit')}</div>
                        <div> {t('Days')}(1)</div>
                      </div>
                    )}
                    width="80"
                    textAlign="Center"
                  />
                  <ColumnDirective
                    field="quoteTotal" //"totalPay"
                    //  headerText={t('Total Pay(2)')}
                    disableHtmlEncode={false}
                    headerTemplate={(props) => (
                      <div style={{ textAlign: 'center' }}>
                        <div> {t('Total')}</div>
                        <div> {t('Pay')}(2)</div>
                      </div>
                    )}
                    width="80"
                    textAlign="Center"
                  />
                </ColumnsDirective>
                <Inject services={[Page, CommandColumn, Sort, Filter, ExcelExport]} />
              </GridComponent>
              : 
              <GridComponent
              className="syncf-grid-comp mt-4 no-pagination-table"
              id="ratecallresult-grid-nopagination"
              ref={resultEmptyGridRef}
              height={200}
              dataSource={[]}
              allowPaging={false}
              allowSorting={true}
              allowFiltering={false}
              textWrapSettings={{ wrapMode: 'Header' }}
              editSettings={{ allowEditing: false, allowDeleting: false }}
              sortSettings={{
                columns: [{ field: 'rank', direction: 'Ascending' }],
              }}
            >
              <ColumnsDirective>
                <ColumnDirective field="id" headerTextAlign="Center" headerText="id" width="0" visible={false} />
                <ColumnDirective
                  field="rank" //"rowId"
                  headerText={t('Row #')}
                  headerTextAlign="Center"
                  disableHtmlEncode={false}
                  width="100"
                  textAlign="Center"
                />
                <ColumnDirective
                  disableHtmlEncode={false}
                  field="carrierSCAC" //"CarrierName"
                  headerText={t('Carrier Name')}
                  headerTemplate={(props) => (
                    <div style={{ textAlign: 'center' }}>
                      <div> {t('Carrier')}</div>
                      <div> {t('Name')}</div>
                    </div>
                  )}
                  headerTextAlign="Center"
                  width="160"
                  textAlign="Center"
                />
                <ColumnDirective field="mode" disableHtmlEncode={false} headerText={t('Mode')} headerTextAlign="Center" width="80" textAlign="Center" />
                <ColumnDirective
                  field="transitTime" //"transitDays"
                  // headerText={t('Transit Days(1)')}
                  disableHtmlEncode={false}
                  headerTemplate={(props) => (
                    <div style={{ textAlign: 'center' }}>
                      <div> {t('Transit')}</div>
                      <div> {t('Days')}(1)</div>
                    </div>
                  )}
                  width="80"
                  textAlign="Center"
                />
                <ColumnDirective
                  field="quoteTotal" //"totalPay"
                  //  headerText={t('Total Pay(2)')}
                  disableHtmlEncode={false}
                  headerTemplate={(props) => (
                    <div style={{ textAlign: 'center' }}>
                      <div> {t('Total')}</div>
                      <div> {t('Pay')}(2)</div>
                    </div>
                  )}
                  width="80"
                  textAlign="Center"
                />
              </ColumnsDirective>
              <Inject services={[Page, CommandColumn, Sort, Filter, ExcelExport]} />
            </GridComponent>}
              </div>
            ) : (
              <div style={{height: 200}} className="my-custom-loader-container">
                <Loading />
              </div>
            )}
          </div>
          <div className="mt-4 my-custom-text">
            <div>(1) {t('Transit Days - The pickup day is assumed to be day 0.')}</div>
            <div>(2) {t('Carrier charges include fuel but no taxes.')}</div>
          </div>
        </div>
      </div>
    </div>
      )}
      </div>
  );
};

export default RateCallComp;
