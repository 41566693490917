import jwtDecode from 'jwt-decode';

export const getJWTToken = () => {
  return localStorage.getItem('jwtToken');
};

export const setJWTToken = (token) => {
  localStorage.setItem('jwtToken', token);
};

export const getMetabaseToken = () => {
  return localStorage.getItem('metabaseToken');
};

export const setMetabaseToken = (token) => {
  localStorage.setItem('metabaseToken', token);
};

export const setMetabaseUserInfo = (userInfo) => {
  return localStorage.setItem("userInfoMetabase" , JSON.stringify(userInfo))
}

export const getMetabaseUserInfo = (key) => {
  return JSON.parse(localStorage.getItem("userInfoMetabase"));
}

export const setUserInfo = (userInfo) => {
  return localStorage.setItem("userInfo" , JSON.stringify(userInfo))
}

export const getUserInfo = (key) => {
  return JSON.parse(localStorage.getItem("userInfo"));
}

export const logOutUser = () => {
	localStorage.removeItem("token");
  localStorage.removeItem('jwtToken');
  localStorage.removeItem('userInfo');
  localStorage.removeItem('metabaseToken');
  localStorage.removeItem('userInfoMetabase');
	window.location.assign("/");
	setTimeout(() => {
		window.location.reload();
	}, 500);
}

export const removeJWTToken = () => {
  localStorage.removeItem('jwtToken');
};

export const isValidToken = () => {
  try {
    const decodedToken = jwtDecode(localStorage.getItem('jwtToken'));
    const currentTimestamp = Math.floor(Date.now() / 1000);
    if (decodedToken.exp > currentTimestamp) {
      return true;
    } else {
      return false;
    }
  }
  catch (err) {
    return false;
  }
}

export const isValidRefreshToken = () => {
  try {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"))
    if(userInfo && userInfo.refreshToken ){
      const decodedToken = jwtDecode(userInfo.refreshToken);
      const currentTimestamp = Math.floor(Date.now() / 1000);
      if (decodedToken.exp > currentTimestamp) {
        return true;
      } else {
        return false;
      }
    }
    else {
      return false;
    }
  }
  catch (err) {
    return false;
  }
}

export const isValidMetabaseToken = () => {
  try {
    const decodedToken = jwtDecode(localStorage.getItem('metabaseToken'));
    const currentTimestamp = Math.floor(Date.now() / 1000);
    if (decodedToken.exp > currentTimestamp) {
      return true;
    } else {
      return false;
    }
  }
  catch (err) {
    return false;
  }
}
export const isValidMetabaseRefreshToken = () => {
  try {
    const userInfo = JSON.parse(localStorage.getItem("userInfoMetabase"))
    if(userInfo && userInfo.refreshToken ){
      const decodedToken = jwtDecode(userInfo.refreshToken);
      const currentTimestamp = Math.floor(Date.now() / 1000);
      if (decodedToken.exp > currentTimestamp) {
        return true;
      } else {
        return false;
      }
    }
    else {
      return false;
    }
  }
  catch (err) {
    return false;
  }
}