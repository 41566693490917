const Loading = () => {
	return (
		<div>
			<div className='spinner-border' role='status' style={{ color: "#06DF94" }}>
				<span className='sr-only' style={{ color: "#06DF94" }}></span>
			</div>
		</div>
	);
};

export default Loading;
